import React, { useReducer } from "react";
import legalvacationdaysreducer from "./legalvacationdaysReducer";
import legalvacationdaysContext from "./legalvacationdaysContext";
import {
  SET_LOADING,
  GET_DATA_BALANCE_DAYS,
  ADD_LEGALVACATION_REQUEST,
  RESET_MESSAGE,
  ERROR_GET_DATA,
  GET_DATA_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_PENDING_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_LEGALVACATION_REQUESTS_BY_CODBOSS,
  GET_DATA_LEGALVACATION_REQUESTS_BY_RRHH,
  GET_REQUEST_LEGALVACATION,
  UPDATE_LEGALVACATION_REQUEST_RRHH,
  GET_DATA_EMPLOYEE_INFO,
  UPDATE_LEGALVACATION_REQUEST,
  DELETE_LEGALVACATION_DAYS_REQUEST,
  GET_DATA_LEGALVACATION_MAIN_COUNT
} from "../../types";
import axiosClient from "../../config/axios";
import moment from "moment";

const LegalVacationDaysDataState = (props) => {
  const initialState = {
    balanceDays: [],
    loading: true,
    message: null,
    legalvacationRequests: [],
    legalvacationRequestBoss: [],
    legalvacationRequestPending: [],
    legalvacationRequestsRRHH: [],
    infoEmployee: null,
    legalVacationRequestAprovedCount: null
  };

  const [state, dispatch] = useReducer(legalvacationdaysreducer, initialState);

  const user = JSON.parse(localStorage.getItem("user"));

  const getBalanceDays = async (rut) => {
    try {
      const response = await axiosClient.get(
        `/api/employee/${rut}/balancedays`
      );

      dispatch({
        type: GET_DATA_BALANCE_DAYS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const getLegalVacationRequestsByCodEmployee = async (codEmployee) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.get(
        `/api/legalvacationrequest/${codEmployee}`
      );

      dispatch({
        type: GET_DATA_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  // const getInfoEmployee = async (rut) => {
  //   try {
  //     const response = await axiosClient.get(
  //       `/api/employee/${rut}/info`
  //     );

  //     dispatch({
  //       type: GET_DATA_EMPLOYEE_INFO,
  //       payload: response.data,
  //     });
  //   } catch (error) {
  //     dispatch({
  //       type: ERROR_GET_DATA,
  //       payload: error,
  //     });
  //   }
  // };

  const addLegalVacationRequest = async (dataForm) => {
    dispatch({
      type: SET_LOADING,
    });

    try {
      const names = state.balanceDays.Nombres;

      const firstName = names.split(" ");

      const fullName = `${firstName} ${state.balanceDays.Apaterno}`;

      const data = {
        codigo: state.balanceDays.Codigo,
        rut: user.username,
        nombre: fullName,
        mail: user.email,
        fechaDesde:
          dataForm.days === 1
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[0], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          fechaHasta:
          dataForm.days === 1
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[1], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
        diasHabiles: dataForm.days,
        comentario: dataForm.comment,
        codigoJefe: state.balanceDays.codigoJefe,
        nombreJefe: state.balanceDays.NombreJefe,
        mailJefe: state.balanceDays.CorreoJefe,
      };

      const response = await axiosClient.post(`api/legalvacationrequest`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };

      dispatch({
        type: ADD_LEGALVACATION_REQUEST,
        payload: alert,
      });

      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
        window.location.reload();
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const addLegalVacationRequestRRHH = async (legalvacationRequest) => {
    dispatch({
      type: SET_LOADING,
    });

    try {
      const {Codigo, Nombres, Apaterno, Mail, Rut, codigoJefe, NombreJefe, CorreoJefe} = state.balanceDays

      const firstName = Nombres.split(" ");

      const fullName = `${firstName} ${Apaterno}`;

      const data = {
        codigo: Codigo,
        rut: Rut,
        nombre: fullName,
        mail: Mail,
        fechaDesde:
        legalvacationRequest.days === 1
              ? moment(legalvacationRequest.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(legalvacationRequest.dateString[0], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          fechaHasta:
          legalvacationRequest.days === 1
              ? moment(legalvacationRequest.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(legalvacationRequest.dateString[1], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
        diasHabiles: legalvacationRequest.days,
        comentario: legalvacationRequest.comment,
        codigoJefe: codigoJefe,
        nombreJefe: NombreJefe,
        mailJefe: CorreoJefe,
      };

      const response = await axiosClient.post(`/api/rrhh/legalvacationrequest`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };

      dispatch({
        type: ADD_LEGALVACATION_REQUEST,
        payload: alert,
      });

      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
        window.location.reload();
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getLegalVacationRRHH = async (query) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.get(`/api/rrhh/legalvacationrequest/?query=${query ?? ''}`
      );

      dispatch({
        type: GET_REQUEST_LEGALVACATION,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  

  // const getPendingLegalVacationRequestsByCodEmployee = async (codEmployee) => {
  //   try {
  //     const response = await axiosClient.get(
  //       `/api/legalvacationrequest/pending/${codEmployee}`
  //     );

  //     dispatch({
  //       type: GET_DATA_PENDING_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE,
  //       payload: response.data.data,
  //     });
  //   } catch (error) {
  //     const alert = {
  //       msg: error.response.data.message,
  //       category: "error",
  //     };
  //     dispatch({
  //       type: ERROR_GET_DATA,
  //       payload: alert,
  //     });
  //   }
  // };

  const getLegalVacationRequestsByCodBoss = async (codBoss) => {
    dispatch({
      type: SET_LOADING,
    });

    const codBossFormat = codBoss.split("-");

    try {
      const response = await axiosClient.get(
        `/api/legalvacationrequest/boss/${codBossFormat[0]}`
      );

      dispatch({
        type: GET_DATA_LEGALVACATION_REQUESTS_BY_CODBOSS,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateLegalVacationRequest = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.put(`api/legalvacationrequest/${id}`, data);
      console.log(response.data.message)
      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPDATE_LEGALVACATION_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateLegalVacationRequestRevision = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.put(`api/legalvacationrequestrevision/${id}`, data);
      console.log(response.data.message)
      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPDATE_LEGALVACATION_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  // const getLegalVacationRequestsByRRHH = async (query = '') => {
  //   dispatch({
  //     type: SET_LOADING,
  //   });
  //   try {
  //     const url = query.length === 0 ? '/api/rrhh/legalvacationrequest' : `/api/rrhh/legalvacationrequest?query=${query}`
  //     const response = await axiosClient.get(url);
  //     dispatch({
  //       type: GET_REQUEST_LEGALVACATION,
  //       payload: response.data.data,
  //     });
  //   } catch (error) {
  //     const alert = {
  //       msg: error.response.data.message,
  //       category: "error",
  //     };
  //     dispatch({
  //       type: ERROR_GET_DATA,
  //       payload: alert,
  //     });
  //   }
  // };

  const deleteLegalVacationRequest = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.delete(
        `/api/rrhh/legalvacationRequest/${id}`
      );

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: DELETE_LEGALVACATION_DAYS_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateLegalVacationRequestRRHH = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const params = {
        fechaDesde:
        data.days === 1
              ? moment(data.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(data.dateString[0], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          fechaHasta:
          data.days === 1
              ? moment(data.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(data.dateString[1], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
        diasHabiles: data.days,
        comentario: data.comment,
        tipo: data.tipo,
      };
      const response = await axiosClient.put(
        `api/rrhh/legalvacationrequest/${id}`,
        params
      );

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPDATE_LEGALVACATION_REQUEST_RRHH,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getLegalVacationRequestAprovedCount = async () => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.get(`/api/rrhh/legalvacationrequestapprovedcount`);
      dispatch({
        type: GET_DATA_LEGALVACATION_MAIN_COUNT,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  return (
    <legalvacationdaysContext.Provider
      value={{
        balanceDays: state.balanceDays,
        loading: state.loading,
        message: state.message,
        getBalanceDays,
        legalvacationRequests: state.legalvacationRequests,
        legalvacationRequestBoss: state.legalvacationRequestBoss,
        legalvacationRequestPending: state.legalvacationRequestPending,
        // infoEmployee: state.infoEmployee,
        legalvacationRequestsRRHH: state.legalvacationRequestsRRHH,
        legalVacationRequestAprovedCount: state.legalVacationRequestAprovedCount,
        
        addLegalVacationRequest,
        addLegalVacationRequestRRHH,
        getLegalVacationRRHH,
        getLegalVacationRequestsByCodEmployee,
        // getPendingLegalVacationRequestsByCodEmployee,
        getLegalVacationRequestsByCodBoss,
        // getLegalVacationRequestsByRRHH,
        updateLegalVacationRequest,
        updateLegalVacationRequestRevision,
        deleteLegalVacationRequest,
        updateLegalVacationRequestRRHH,
        // getInfoEmployee,
        getLegalVacationRequestAprovedCount
      }}
    >
      {props.children}
    </legalvacationdaysContext.Provider>
  );
};

export default LegalVacationDaysDataState;
