import {
  GET_DATA_BALANCE_DAYS,
  ADD_ATTENDANCE_REQUEST,
  RESET_MESSAGE,
  ERROR_GET_DATA,
  GET_DATA_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_PENDING_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_ATTENDANCE_REQUESTS_BY_CODBOSS,
  UPDATE_VACATION_REQUEST,
  SET_LOADING,
  GET_REQUEST_ATTENDANCE,
  UPDATE_ATTENDANCE_REQUEST_RRHH,
  GET_DATA_EMPLOYEE_INFO,
  UPDATE_ATTENDANCE_REQUEST,
  DELETE_ATTENDANCE_DAYS_REQUEST,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case GET_DATA_BALANCE_DAYS:
      return {
        ...state,
        balanceDays: action.payload[0],
        loading: false,
      };

    case GET_DATA_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE:
      return {
        ...state,
        attendanceRequests: action.payload,
        loading: false,
      };
    case GET_DATA_PENDING_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE:
      return {
        ...state,
        attendanceRequestPending: action.payload,
        loading: false,
      };
    case GET_DATA_ATTENDANCE_REQUESTS_BY_CODBOSS:
      return {
        ...state,
        vacationRequestBoss: action.payload,
        loading: false,
      };

    case GET_REQUEST_ATTENDANCE:
      return {
        ...state,
        message: "",
        attendanceRequestsRRHH: action.payload,
        loading: false,
      };

    case GET_DATA_EMPLOYEE_INFO:
    return {
      ...state,
      message: action.payload,
      loading: false,
    };

    

    case ADD_ATTENDANCE_REQUEST:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    
    case UPDATE_ATTENDANCE_REQUEST:
    return {
      ...state,
      message: action.payload,
      loading: false,
    };

    case DELETE_ATTENDANCE_DAYS_REQUEST:
    case UPDATE_ATTENDANCE_REQUEST_RRHH:
    case UPDATE_VACATION_REQUEST:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };

    case UPDATE_ATTENDANCE_REQUEST:
    return {
      ...state,
      message: action.payload,
      loading: false,
    };

    case ERROR_GET_DATA:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: null,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_EMPLOYEE_INFO:
      return {
        ...state,
        infoEmployee: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
