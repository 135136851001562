import React, { useEffect, useContext } from "react";
import { Alert } from "antd";
import FormAdditionalDay from "./FormAditionalDay";
import AdditionalDaysContext from "../../context/additionaldays/additionaldaysContext";
import AlertContext from "../../context/alert/alertContext";

const AdditionalDays = () => {
  const additionalDaysContext = useContext(AdditionalDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    balanceDays,
    vacationRequestPending,
    loading,
    message,
    getBalanceDays,
    addVacationRequest,
    getPendingVacationRequestsByCodEmployee,
  } = additionalDaysContext;

  useEffect(() => {
    async function fetchPendingVacationRequest() {
      const user = JSON.parse(localStorage.getItem("user"));
      getPendingVacationRequestsByCodEmployee(user.username);
    }
    fetchPendingVacationRequest();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getBalanceDays(user.username);
    }
    fetchData();
  }, []);

  return (
    <>     
    { vacationRequestPending.length === 0 ? (
        <FormAdditionalDay
          balanceDays={balanceDays}
          loading={loading}
          message={message}
          addVacationRequest={addVacationRequest}
          alert={alert}
          showAlert={showAlert}
        />    
        ) : (
          <Alert
            message="Solicitud pendiente"
            description="Usted tiene una solicitud pendiente, favor contactar a supervisor."
            type="info"
            showIcon
            style={{ width: "50%" }}
          />
      )} 
    </>
  );
};

export default AdditionalDays;
