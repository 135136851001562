import React from "react";
import { Route } from "react-router-dom";

import Login from "./Login";
import ChangePasswordForm from "./ChangePasswordForm";

/* const NoMatch = () => <p>Error 404. La pagina no ha sido encontrada.</p>; */

const AuthLayout = () => {
  return (
    <div>
      <Route path="/auth/login" component={Login} />
      <Route
        path="/auth/recuperacion-contrasena"
        component={ChangePasswordForm}
      />
    </div>
  );
};

export default AuthLayout;
