import React from "react";
import { Route, Switch } from "react-router-dom";

import PrivateRoute from "../routes/PrivateRoute";
import Layout from "../Layout/LayoutPrincipal";
import AdditionalDays from "../additionaldays/AdditionalDays";
import AttendanceDays from "../attendancedays/AttendanceDays";
import LegalVacationDays from "../legalvacationdays/LegalVacationDays";

import MyRequests from "../additionaldays/MyRequests";
import MyRequestsAttendance from "../attendancedays/MyRequests";
import MyRequestsLegalVacation from "../legalvacationdays/MyRequests";


import RequestAdminFromMyEmployees from "../administrativedays/RequestsAdminFromMyEmployees";
import RequestsFromMyEmployees from "../additionaldays/RequestsFromMyEmployees";
import RequestAttendanceFromMyEmployees from "../attendancedays/RequestsFromMyEmployees";
import RequestLegalVacationFromMyEmployees from "../legalvacationdays/RequestsFromMyEmployees";


import ExampleScreen from "../example/ExampleScreen";
import AdministrativeDays from "../administrativedays/AdministrativeDays";
import MyRequestsAdmin from "../administrativedays/MyRequestAdmin";
import FormDetails from "../file/DetailsUpload/FormDetails";
import FormManualUpload from "../file/ManualUpload/FormManualUpload";
import FormRequest from "../request/formRequest";
import FormRRHHAdministrativeDays from "../request/formRRHHAdministrativeDays";
import AdditionalRRHHRequest from "../request/additionalRRHHRequest";
import AttendanceRRHHRequest from "../request/attendanceRRHHRequest";
import Reports from "../reports/reports";
import fileOutput from "../filesoutput/filesoutput";
import FormRRHHAdditionalDays from "../request/formRRHHAdditionalDays";
import FormRRHHAttendanceDays from "../request/formRRHHAttendanceDays";
import PermitionDays from "../permitiondays/PermitionDays";
import MyRequestsPermit from "../permitiondays/MyRequestsPermit";
import RequestPermitFromMyEmployees from "../permitiondays/RequestsFromMyEmployees";
import formRRHHLegalVacationsDays from "../request/formRRHHLegalVacationDays";
import LegalVacationsRRHHRequest from "../request/legalVacationsRRHHRequest";
import LegalVacationsGeneralRequest from "../request/legalVacationsGeneralRequest";
import FormRRHHPermitionDays from "../request/formRRHHPermitionDays";
import PermitionRRHHRequest from "../request/permitionRRHHRequest";

const NoMatch = () => <p>Error 404. La pagina no ha sido encontrada.</p>;

const AppLayout = () => {
  return (
    <div>
      <Layout>
        <Switch>
          <PrivateRoute
            path="/app/dias-adicionales"
            component={AdditionalDays}
          />
          <PrivateRoute
            path="/app/dias-administrativos"
            component={AdministrativeDays}
          />
          <PrivateRoute
            path="/app/mis-solicitudes-administrativas"
            component={MyRequestsAdmin}
          />
          <PrivateRoute path="/app/mis-solicitudes" component={MyRequests} />
          <PrivateRoute
            path="/app/solicitudes-mis-empleados"
            component={RequestsFromMyEmployees}
          />
          <PrivateRoute
            path="/app/solicitudes-mis-empleados-administrativos"
            component={RequestAdminFromMyEmployees}
          />
          <PrivateRoute path="/app/example-screen" component={ExampleScreen} />
          <PrivateRoute path="/app/archivos" component={FormManualUpload} />
          <PrivateRoute
            path="/app/archivos-detalle/:id"
            component={FormDetails}
          />

          <PrivateRoute
            path="/app/dias-administrativos-rrhh"
            component={FormRRHHAdministrativeDays}
          />
          <PrivateRoute
            path="/app/dias-administrativos-solicitudes"
            component={FormRequest}
          />

          <PrivateRoute
            path="/app/dias-adicionales-solicitudes"
            component={AdditionalRRHHRequest}
          />
          <PrivateRoute
            path="/app/dias-adicionales-rrhh"
            component={FormRRHHAdditionalDays}
          />

          <PrivateRoute
            path="/app/dias-asistencia"
            component={AttendanceDays}
          />
          <PrivateRoute
            path="/app/mis-solicitudes-asistencia"
            component={MyRequestsAttendance}
          />
          <PrivateRoute
            path="/app/solicitudes-mis-empleados-asistencia"
            component={RequestAttendanceFromMyEmployees}/>

          <PrivateRoute
            path="/app/dias-asistencia-solicitudes"
            component={FormRRHHAttendanceDays}
          />

          <PrivateRoute
            path="/app/asistencias-solicitudes"
            component={AttendanceRRHHRequest}
          />

          <PrivateRoute
            path="/app/dias-permiso"
            component={PermitionDays}
          />
          <PrivateRoute
            path="/app/mis-solicitudes-permiso"
            component={MyRequestsPermit}
          />
          <PrivateRoute
            path="/app/solicitudes-mis-empleados-permisos"
            component={RequestPermitFromMyEmployees}/>

          <PrivateRoute
            path="/app/dias-permiso-solicitudes"
            component={FormRRHHPermitionDays}
          />

          <PrivateRoute
            path="/app/permisos-solicitudes"
            component={PermitionRRHHRequest}
          />

          <PrivateRoute path="/app/reportes" component={Reports} />
          <PrivateRoute path="/app/archivos-de-salida" component={fileOutput} />


          <PrivateRoute
            path="/app/vacaciones-atrasadas"
            component={LegalVacationDays}
          />
          <PrivateRoute
            path="/app/mis-vacaciones-atrasadas"
            component={MyRequestsLegalVacation}
          />
          <PrivateRoute
            path="/app/solicitudes-mis-empleados-vacaciones-atrasadas"
            component={RequestLegalVacationFromMyEmployees}/>

          <PrivateRoute
            path="/app/vacaciones-atrasadas-rrhh"
            component={formRRHHLegalVacationsDays}
          />

          <PrivateRoute
            path="/app/vacaciones-atrasadas-solicitudes"
            component={LegalVacationsGeneralRequest}
          />

          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Layout>
    </div>
  );
};

export default AppLayout;
