import React, { useReducer } from "react";
import administrativedaysReducer from "./administrativedaysReducer";
import administrativedaysContext from "./administrativedaysContext";
import axiosClient from "../../config/axios";
import moment from "moment";

import * as types from "../../types";

const AdministrativeDaysDataState = (props) => {
    const initialState = {
      loading: true,
      message: null,
      types: [],
      holidays: [],
      vacationHistory: [],
      additional:{},
      adminRequests: [],
      adminRequestsBoss: [],
      adminRequestsPending: [],
      adminRequestsRRHH:[],
      adminRequestsModify:[],
      adminRequestsModifyBoss:[],
      adminCondition:{},
    };
  
    const [state, dispatch] = useReducer(administrativedaysReducer, initialState);
    const user = JSON.parse(localStorage.getItem("user"));

    const getTypes = async () => {
        try {
          dispatch({
            type: types.SET_LOADING,
          });

          const response = await axiosClient.get(
            `/api/admistrative/days/types`
          );
    
          dispatch({
            type: types.GET_TYPES_ADMINISTRATIVE_DAYS_SUCCESS,
            payload: response.data.types,
          });
        } catch (error) {
          dispatch({
            type: types.ERROR_GET_DATA,
            payload: error,
          });
        }
    };


    const addAdministrativeDaysRequest = async (dataForm) => {
      dispatch({
        type: types.SET_LOADING,
      });
  
      try {
        const names = dataForm.balanceDays.Nombres;
  
        const firstName = names.split(" ");
  
        const fullName = `${firstName} ${dataForm.balanceDays.Apaterno}`;
  
        const data = {
          codigo: dataForm.balanceDays.Codigo,
          rut: user.username,
          nombre: fullName,
          mail: user.email,
          fechaDesde:
          dataForm.days === 1 || dataForm.days === 0.5
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[0], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          fechaHasta:
          dataForm.days === 1 || dataForm.days === 0.5
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[1], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          diasHabiles: dataForm.days,
          tipo: dataForm.type,
          comentario: dataForm.comment,
          codigoJefe: dataForm.balanceDays.codigoJefe,
          nombreJefe: dataForm.balanceDays.NombreJefe,
          mailJefe: dataForm.balanceDays.CorreoJefe,
        };
        const response = await axiosClient.post(`/api/administrative/days`, data);
        const alert = { msg: response.data.message, category: "success" };
  
        dispatch({
          type: types.ADD_ADMINISTRATIVE_DAYS_REQUEST,
          payload: alert,
        });
  
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };
    
    const getAdminRequestsByCodEmployee = async (codEmployee) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.get(
          `api/administrative/days/employee/${codEmployee}`
        );
  
        dispatch({
          type: types.GET_DATA_ADMIN_REQUESTS_BY_CODEMPLOYEE,
          payload: response.data.data,
        });
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };


    const getAdminRequestsByRRHH = async (query = '') => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const url = query.length === 0 ?  `api/rrhh/administrative` :  `api/rrhh/administrative?query=${query}`
        const response = await axiosClient.get(url);
  
        dispatch({
          type: types.GET_REQUEST_ADMINISTRATIVE,
          payload: response.data.requests,
        });
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const getPendingAdminRequestsByCodEmployee = async (codEmployee) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.get(
          `api/administrative/days/employee/${codEmployee}/pending`
        );
  
        dispatch({
          type: types.GET_DATA_PENDING_ADMIN_REQUESTS_BY_CODEMPLOYEE,
          payload: response.data.data,
        });
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };
  
  
    const getAdminRequestsByCodBoss = async (codBoss) => {
      dispatch({
        type: types.SET_LOADING,
      });
  
      const codBossFormat = codBoss.split("-");
  
      try {
        const response = await axiosClient.get(
          `api/administrative/days/boss/${codBossFormat[0]}`
        );
        dispatch({
          type: types.GET_DATA_ADMIN_REQUESTS_BY_CODBOSS,
          payload: response.data.data,
        });

      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };


    const updateAdminRequest = async (id, data) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.put(`api/administrative/days/${id}`, data);
  
        const alert = {
          msg: response.data.message,
          category: "success",
        };
        dispatch({
          type: types.UPD_ADMINISTRATIVE_DAYS_REQUEST,
          payload: alert,
        });
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const getAdditionalAdminDaysByCodEmployee = async (codEmployee) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.get(
          `api/administrative/days/additional/${codEmployee}`
        );
  
        dispatch({
          type: types.GET_ADMINISTRATIVE_DAYS_ADDITIONAL_REQUEST,
          payload: response.data.data,
        });
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const addAdministrativeDaysRequestRRHH = async (dataForm) => {
      dispatch({
        type: types.SET_LOADING,
      });
  
      try {
        const names = dataForm.balanceDays.Nombres;
  
        const firstName = names.split(" ");
  
        const fullName = `${firstName} ${dataForm.balanceDays.Apaterno}`;
  
        const data = {
          codigo: dataForm.balanceDays.Codigo,
          rut: dataForm.balanceDays.Codigo.split("-")[0],
          nombre: fullName,
          mail: dataForm.balanceDays.Mail,
          fechaDesde:
          dataForm.days === 1 || dataForm.days === 0.5
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[0], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          fechaHasta:
          dataForm.days === 1 || dataForm.days === 0.5
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[1], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          diasHabiles: dataForm.days,
          tipo: dataForm.type,
          comentario: dataForm.comment,
          codigoJefe: dataForm.balanceDays.codigoJefe,
          nombreJefe: dataForm.balanceDays.NombreJefe,
          mailJefe: dataForm.balanceDays.CorreoJefe,
        };
        const response = await axiosClient.post(`/api/administrative/rrhh/days`, data);
        const alert = { msg: response.data.message, category: "success" };
  
        dispatch({
          type: types.ADD_ADMINISTRATIVE_DAYS_REQUEST_RRHH,
          payload: alert,
        });
  
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const updAdministrativeDaysRequestRRHH = async (dataForm) => {
      dispatch({
        type: types.SET_LOADING,
      });
  
      try {
        const data = {
          fechaDesde:
          dataForm.days === 1 || dataForm.days === 0.5
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[0], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          fechaHasta:
          dataForm.days === 1 || dataForm.days === 0.5
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[1], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          diasHabiles: dataForm.days,
          tipo: dataForm.type,
          comentario: dataForm.comment
        };
        
        const response = await axiosClient.put(`/api/administrative/rrhh/days/${dataForm.id}`, data);
        const alert = { msg: response.data.message, category: "success" };
  
        dispatch({
          type: types.UPD_ADMINISTRATIVE_DAYS_REQUEST_RRHH,
          payload: alert,
        });
  
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };


    const deleteAdminRequest = async (id, data) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.delete(`api/administrative/rrhh/days/${id}`);
  
        const alert = {
          msg: response.data.message,
          category: "success",
        };
        dispatch({
          type: types.DELETE_ADMINISTRATIVE_DAYS_REQUEST,
          payload: alert,
        });
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const getAdminRequestsModifyByCodEmployee = async (codEmployee) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.get(
          `api/administrative/request/employee/${codEmployee}`
        );
  
        dispatch({
          type: types.GET_DATA_ADMIN_REQUESTS_MODIFY_BY_CODEMPLOYEE,
          payload: response.data.data,
        });
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const addRequestModificationAdministrativeDays = async (dataForm) => {
      dispatch({
        type: types.SET_LOADING,
      });

      try {
        const data = {
          codigo: dataForm.record.Codigo,
          rut: user.username,
          nombre: dataForm.record.Nombre,
          mail: dataForm.record.Mail,
          fechaDesde:
          dataForm.days === 1 || dataForm.days === 0.5
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[0], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          fechaHasta:
          dataForm.days === 1 || dataForm.days === 0.5
              ? moment(dataForm.dateString, "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                )
              : moment(dataForm.dateString[1], "DD-MM-YYYY").format(
                  "YYYY-MM-DD[T]HH:mm:ss"
                ),
          diasHabiles: dataForm.days,
          tipo: dataForm.type,
          comentario: dataForm.comment,
          codigoJefe: dataForm.record.CodigoJefe,
          nombreJefe: dataForm.record.NombreJefe,
          mailJefe: dataForm.record.MailJefe,
          IdSolicitudAdmin: dataForm.record.Id,
        };
        
        const response = await axiosClient.post(`api/administrative/request/modifydays`, data);
        const alert = { msg: response.data.message, category: "success" };
  
        dispatch({
          type: types.ADD_ADMINISTRATIVE_DAYS_REQUEST,
          payload: alert,
        });
  
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const requestDeleteAdmin = async (id, data) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.post(`api/administrative/request/deletedays/${id}`, data);
  
        const alert = {
          msg: response.data.message,
          category: "success",
        };
        dispatch({
          type: types.DELETE_ADMINISTRATIVE_DAYS_REQUEST,
          payload: alert,
        });
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const getAdminRequestsModifyByCodBoss = async (codBoss) => {
      dispatch({
        type: types.SET_LOADING,
      });
  
      const codBossFormat = codBoss.split("-");
  
      try {
        const response = await axiosClient.get(
          `api/administrative/request/boss/${codBossFormat[0]}`
        );
        dispatch({
          type: types.GET_DATA_ADMIN_REQUESTS_MODIFY_BY_CODBOSS,
          payload: response.data.data,
        });

      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const updateAdminRequestModify = async (id, data) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.put(`api/administrative/request/days/update/${id}`, data);
  
        const alert = {
          msg: response.data.message,
          category: "success",
        };
        dispatch({
          type: types.UPD_ADMINISTRATIVE_DAYS_REQUEST,
          payload: alert,
        });
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const deleteAdminRequestModify = async (id, data) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.put(`api/administrative/request/days/delete/${id}`, data);
  
        const alert = {
          msg: response.data.message,
          category: "success",
        };
        dispatch({
          type: types.UPD_ADMINISTRATIVE_DAYS_REQUEST,
          payload: alert,
        });
        setTimeout(() => {
          dispatch({
            type: types.RESET_MESSAGE,
          });
        }, 5000);
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const getHolidays = async () => {
      try {
        dispatch({
          type: types.SET_LOADING,
        });

        const response = await axiosClient.get(
          `/api/administrative/days/holidays`
        );
  
        dispatch({
          type: types.GET_ADMINISTRATIVE_DAYS_HOLIDAYS,
          payload: response.data.days,
        });
      } catch (error) {
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: error,
        });
      }
    };

    const getConditionByCodEmployee = async (codEmployee) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.get(
          `api/administrative/days/conditions/${codEmployee}`
        );
  
        dispatch({
          type: types.GET_ADMINISTRATIVE_DAYS_CONDITIONS,
          payload: response.data.data,
        });
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    const getHistoryByCodEmployee = async (codEmployee) => {
      dispatch({
        type: types.SET_LOADING,
      });
      try {
        const response = await axiosClient.get(
          `api/administrative/days/history/${codEmployee}`
        );
  
        dispatch({
          type: types.GET_ADMINISTRATIVE_DAYS_HISTORY,
          payload: response.data.data,
        });
      } catch (error) {
        const alert = {
          msg: error.response.data.message,
          category: "error",
        };
        dispatch({
          type: types.ERROR_GET_DATA,
          payload: alert,
        });
      }
    };

    return (
        <administrativedaysContext.Provider
          value={{
            types: state.types,
            additional:state.additional,
            loading: state.loading,
            message: state.message,
            adminRequests: state.adminRequests,
            adminRequestsBoss: state.adminRequestsBoss,
            adminRequestsPending: state.adminRequestsPending,
            adminRequestsRRHH: state.adminRequestsRRHH,
            adminRequestsModify: state.adminRequestsModify,
            adminRequestsModifyBoss: state.adminRequestsModifyBoss,
            holidays: state.holidays,
            adminCondition: state.adminCondition,
            vacationHistory: state.vacationHistory,
            getTypes,
            addAdministrativeDaysRequest,
            addAdministrativeDaysRequestRRHH,
            getAdminRequestsByCodEmployee,
            getAdminRequestsByCodBoss,
            getPendingAdminRequestsByCodEmployee,
            updateAdminRequest,
            getAdditionalAdminDaysByCodEmployee,
            getAdminRequestsByRRHH,
            deleteAdminRequest,
            updAdministrativeDaysRequestRRHH,
            addRequestModificationAdministrativeDays,
            requestDeleteAdmin,
            getAdminRequestsModifyByCodEmployee,
            getAdminRequestsModifyByCodBoss,
            updateAdminRequestModify,
            deleteAdminRequestModify,
            getHolidays,
            getConditionByCodEmployee,
            getHistoryByCodEmployee,
          }}
        >
          {props.children}
        </administrativedaysContext.Provider>
      );
    };
    
export default AdministrativeDaysDataState;

