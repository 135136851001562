import React, { useReducer } from "react";
import jwtDecode from "jwt-decode";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";

import tokenAuth from "../../config/tokenAuth";
import axiosClient from "../../config/axios";

import {
  AUTHENTICATED_USER,
  SUCCESS_LOGIN,
  ERROR_LOGIN,
  CLOSE_SESION,
} from "../../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    authenticated: null,
    user: null,
    message: null,
    loading: true,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const authenticatedUser = async () => {
    const token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null;

    if (!token) {
      dispatch({
        type: ERROR_LOGIN,
      });
    } else {
      const decodedToken = jwtDecode(token);

      const currentTime = new Date().getTime() / 1000;
      if (currentTime > decodedToken.exp && !token) {
        dispatch({
          type: ERROR_LOGIN,
        });
      } else {
        tokenAuth(token);
        dispatch({
          type: AUTHENTICATED_USER,
        });
      }
    }
  };

  const logIn = async (data) => {
    try {
      const response = await axiosClient.post("/api/login", data);

      const decodedToken = jwtDecode(response.data.userToken.token);

      const dataUser = {
        token: response.data.userToken.token,
        user: {
          ...decodedToken
        },
      };

      dispatch({
        type: SUCCESS_LOGIN,
        payload: dataUser,
      });
    } catch (error) {
      const alert = {
        msg: error.response
          ? error.response.data.message
          : "Ha ocurrido un error",
        category: "error",
      };
      dispatch({
        type: ERROR_LOGIN,
        payload: alert,
      });
    }
  };

  const closeSession = () => {
    dispatch({
      type: CLOSE_SESION,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        authenticated: state.authenticated,
        user: state.user,
        message: state.message,
        loading: state.loading,
        logIn,
        authenticatedUser,
        closeSession,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
