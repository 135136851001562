import React, { useContext, useEffect, useState } from "react";
import { Tag, Space, Table, Modal as ModalAntd } from "antd";
import Modal from "../core/Modal";
import moment from "moment";

import { ExclamationCircleOutlined } from '@ant-design/icons';
import AdditionaldaysContext from "../../context/additionaldays/additionaldaysContext";
import FormEditAdditionalDay from "../additionaldays/FormAditionalDay/FormEditAdditionalDay";
import Search from "antd/lib/input/Search";

const AdditionalRRHHRequest = () => {

    const additionaldaysContext = useContext(AdditionaldaysContext);
    const {   
      loading,
      message,
      vacationRequestsRRHH,
      getVacationRequestsByRRHH,
      deleteAdditionalRequest
    } = additionaldaysContext;

    useEffect(() => {
        getVacationRequestsByRRHH();
    }, [message]);

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [width, setWidth] = useState();
  
    const showModalUpdateRequest = (record, action) => {
      
      
      if(action === 'edit'){
        setIsVisibleModal(true);
        setModalTitle("Editar Solicitud");
        setModalContent(
          <FormEditAdditionalDay
          setIsVisibleModal={setIsVisibleModal}
          record={record}
          ></FormEditAdditionalDay>
        );
        setWidth(600);
      }else{
  
        ModalAntd.confirm({
          centered: true,
          icon: <ExclamationCircleOutlined />,
          content: <p>¿Esta seguro que desea eliminar la solicitud?</p>,
          onOk() {
            deleteAdditionalRequest(record.Id);
          },
          onCancel() {
          },
        }) 
      }
  
    };

    const marginP = { margin: 0 };

    const columns = [     
      {
        title: "Código colaborador",
        dataIndex: "CodigoEmpleado",
        key: "rrhhCodigoEmpleado",
        filters: [
          ...Array.from(new Set(vacationRequestsRRHH.map(x => x.CodigoEmpleado))).map(x => { return { 'text': x, 'value': x }})         
        ],
        onFilter: (value, record) => record.CodigoEmpleado.indexOf(value) === 0,
      },
      {
        title: "Nombre colaborador",
        dataIndex: "Nombre",
        key: "2",
        filters: [
          ...Array.from(new Set(vacationRequestsRRHH.map(x => x.Nombre))).map(x => { return { 'text': x, 'value': x }})         
        ],
        onFilter: (value, record) => record.Nombre.indexOf(value) === 0,
      },
      {
        title: "Fecha solicitud",
        dataIndex: "FechaCreacion",
        key: "3",
        render: (text) => (
          <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
        ),
      },
      {
        title: "Estado",
        dataIndex: "Estado",
        key: "4",
        render: (status) => (
          <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
            {status === "P"
              ? "Pendiente"
              : status === "A"
              ? "Aprobado"
              : "Rechazado"}
          </Tag>
        ),
      },
      {
        title: "Desde",
        dataIndex: "FechaDesde",
        key: "5",
        render: (text) => (
          <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
        ),
      },
      {
        title: "Hasta",
        dataIndex: "FechaHasta",
        key: "6",
        render: (text) => (
          <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
        ),
      },
      {
        title: "Dias",
        dataIndex: "DiasSolicitados",
        key: "7",
      },
      {
        title: "Comentario colaborador",
        dataIndex: "Comentario",
        key: "8",
      },
      {
        title: "Nombre jefe",
        dataIndex: "NombreJefe",
        key: "9",
      },
      {
        title: "Comentario jefe",
        dataIndex: "ComentarioJefe",
        key: "10",
      },
      {
        title: "Acciones",
        key: "action",
        render: (text, record) => (
          <Space size="middle">        
              <>
              <a onClick={() => showModalUpdateRequest(record, "edit")}>
                Editar
              </a>
              <a onClick={() => showModalUpdateRequest(record, "delete")}>
                Eliminar
              </a>
              </>         
          </Space>
        ),
      },
    ];
  
    console.log("vacationRequestsRRHH", vacationRequestsRRHH);
    if (!vacationRequestsRRHH) {
      return "loading...";
    }

    const onSearch = (value) =>{
      console.log("onSearch", value);
      getVacationRequestsByRRHH(value.trim());
    }
  
    return (
      <>
        <Search placeholder="Busqueda por rut o nombre" allowClear={true} enterButton="Buscar" size="middle" onSearch={onSearch}/>
        <br></br>
        <br></br>
        <Table dataSource={vacationRequestsRRHH} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
        <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={width}
      >
        {modalContent}
      </Modal>
      </>
    );
}

export default AdditionalRRHHRequest;