import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";

import mainLogo from "../../assets/logo/logo_crossover.jpg";

import AuthContext from "../../context/authentication/authContext";
import useMediaQuery from "../../hooks/useMediaQuery";

const Header = (props) => {
  const authContext = useContext(AuthContext);
  const { user, closeSession } = authContext;
  const { Header } = Layout;
  const { SubMenu } = Menu;
  const [current, setCurrent] = useState("mail");

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const logout = () => {
    localStorage.clear();
    closeSession();
  };

  const isMobile = useMediaQuery("max-width", 790);

  return (
    <Header style={{ background: "white", height: "65px", padding: "0 25px" }}>
      <div
        className="logo"
        style={{ float: "left", width: "150px", height: "60px" }}
      >
        <img
          src={mainLogo}
          style={{
            width: "150px",
            height: "60px",
            padding: "2px",
          }}
          alt="logo"
        />
        {/* <AppLogo
          style={{
            width: "60px",
            height: "60px",
            padding: "2px",
          }}
        /> */}
      </div>
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        style={{ float: "right" }}
      >
        <SubMenu
          icon={<UserOutlined />}
          title={
            !user
              ? "sin usuario"
              : isMobile && user
              ? user.username
              : user.email
          }
        >
          {/* <Menu.Item key="setting:7">Cambio de contraseña</Menu.Item> */}
          <Menu.Item key="setting:8" onClick={logout}>
            Salir
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  );
};

export default withRouter(Header);
