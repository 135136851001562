import {
  GET_DATA_BALANCE_DAYS,
  ADD_LEGALVACATION_REQUEST,
  RESET_MESSAGE,
  ERROR_GET_DATA,
  GET_DATA_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_PENDING_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_LEGALVACATION_REQUESTS_BY_CODBOSS,
  UPDATE_LEGALVACATION_REQUEST,
  SET_LOADING,
  GET_REQUEST_LEGALVACATION,
  UPDATE_LEGALVACATION_REQUEST_RRHH,
  GET_DATA_EMPLOYEE_INFO,
  DELETE_LEGALVACATION_DAYS_REQUEST,
  GET_DATA_LEGALVACATION_MAIN_COUNT
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case GET_DATA_BALANCE_DAYS:
      return {
        ...state,
        balanceDays: action.payload[0],
        loading: false,
      };
      
    case GET_DATA_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE:
      return {
        ...state,
        legalvacationRequests: action.payload,
        loading: false,
      };
    case GET_DATA_PENDING_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE:
      return {
        ...state,
        legalvacationRequestPending: action.payload,
        loading: false,
      };
    case GET_DATA_LEGALVACATION_REQUESTS_BY_CODBOSS:
      return {
        ...state,
        legalvacationRequestBoss: action.payload,
        loading: false,
      };

    case GET_REQUEST_LEGALVACATION:
      return {
        ...state,
        message: "",
        legalvacationRequestsRRHH: action.payload,
        loading: false,
      };

    case GET_DATA_EMPLOYEE_INFO:
    return {
      ...state,
      message: action.payload,
      loading: false,
    };

    case ADD_LEGALVACATION_REQUEST:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    
    case UPDATE_LEGALVACATION_REQUEST:
    return {
      ...state,
      message: action.payload,
      loading: false,
    };

    case DELETE_LEGALVACATION_DAYS_REQUEST:
    case UPDATE_LEGALVACATION_REQUEST_RRHH:
    case UPDATE_LEGALVACATION_REQUEST:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };

    case UPDATE_LEGALVACATION_REQUEST:
    return {
      ...state,
      message: action.payload,
      loading: false,
    };

    case ERROR_GET_DATA:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: null,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_EMPLOYEE_INFO:
      return {
        ...state,
        infoEmployee: action.payload,
        loading: false,
      };
    case GET_DATA_LEGALVACATION_MAIN_COUNT:
      return {
        ...state,
        legalVacationRequestAprovedCount: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
