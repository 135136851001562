import React, { useContext, useEffect, useState } from "react";
import ReportsContext from "../../context/reports/reportsContext";
import { Form, Input, Button, Select, Spin } from "antd";
const Reports = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 6 },
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const reportsContext = useContext(ReportsContext);
  const {
    loading,
    reports,
    getReports,
    getReportsYears,
    years,
  } = reportsContext;
  const [url, setUrl] = useState("");
  const [year, setYear] = useState("");

  useEffect(() => {
    getReports();
    getReportsYears();
  }, []);

  const renderOptions = () => {
    return reports.map((report) => (
      <Option value={report.url} key={report.name}>
        {report.name}
      </Option>
    ));
  };

  const renderOptionsYears = () => {
    return years.map((year) => (
      <Option value={year} key={year}>
        {year}
      </Option>
    ));
  };

  const onChangeOption = (value) => {
    setUrl(value);
  };

  const onChangeYear = (value) => {
    setYear(value);
  };

  const getUrl = () => {
    return `${url}?year=${year}`
  }

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form {...layout} form={form} name="basic">
        <Form.Item label="Reporte" name="report">
          <Select
            name="report"
            placeholder={"Seleccionar"}
            onChange={onChangeOption}
          >
            {renderOptions()}
          </Select>
        </Form.Item>

        <Form.Item label="Año" name="year">
          <Select
            name="year"
            placeholder={"Seleccionar"}
            onChange={onChangeYear}
          >
            {renderOptionsYears()}
          </Select>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            href={getUrl()}
            disabled={url.length === 0 || year.length === 0}
          >
            Generar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default Reports;
