import React, { useReducer } from "react";
import permitiondaysReducer from "./permitiondaysReducer";
import permitiondaysContext from "./permitiondaysContext";
import {
  GET_DATA_BALANCE_DAYS,
  ADD_VACATION_REQUEST,
  RESET_MESSAGE,
  ERROR_GET_DATA,
  SET_LOADING,
  GET_TYPES_PERMIT_DAYS_SUCCESS,
  GET_ALL_TYPES_PERMIT_DAYS_SUCCESS,
  GET_DATA_PERMIT_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_PERMIT_REQUESTS_MODIFY_BY_CODEMPLOYEE,
  ADD_PERMIT_DAYS_REQUEST,
  GET_DATA_PERMIT_REQUESTS_BY_CODBOSS,
  GET_DATA_PERMIT_REQUESTS_MODIFY_BY_CODBOSS,
  GET_DATA_PENDING_PERMIT_REQUESTS_BY_CODEMPLOYEE,
  UPD_PERMIT_DAYS_REQUEST,
  GET_PERMIT_DAYS_REQUEST,  
  DELETE_PERMIT_DAYS_REQUEST,
  UPDATE_PERMIT_REQUEST_RRHH,
  GET_PERMIT_DAYS_IMG,
  GET_DATA_EMPLOYEE_INFO
} from "../../types";
import axiosClient from "../../config/axios";
import moment from "moment";

const PermitionDaysDataState = (props) => {
  const initialState = {
    types: [],
    allTypes: [],
    image: null,
    balanceDays: [],
    loading: true,
    message: null,
    vacationRequests: [],
    vacationRequestBoss: [],
    vacationRequestPending: [],
    vacationRequestsRRHH: [],
  };

  const [state, dispatch] = useReducer(permitiondaysReducer, initialState);

  const user = JSON.parse(localStorage.getItem("user"));

  const getInfoEmployee = async (rut) => {
    try {
      const response = await axiosClient.get(
        `/api/employee/${rut}/info`
      );
  
      dispatch({
        type: GET_DATA_EMPLOYEE_INFO,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const getTypes = async (rut) => {
    try {
      dispatch({
        type: SET_LOADING,
      });

      const response = await axiosClient.get(
        `/api/permit/days/types/${rut}`
      );

      dispatch({
        type: GET_TYPES_PERMIT_DAYS_SUCCESS,
        payload: response.data.types,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const getAllTypes = async () => {
    try {
      dispatch({
        type: SET_LOADING,
      });

      const response = await axiosClient.get(
        `/api/permit/days/types`
      );

      dispatch({
        type: GET_ALL_TYPES_PERMIT_DAYS_SUCCESS,
        payload: response.data.types,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const getBalanceDays = async (rut) => {
    try {
      const response = await axiosClient.get(
        `/api/employee/${rut}/balancedays`
      );

      dispatch({
        type: GET_DATA_BALANCE_DAYS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const renderImageByPath = async (path) => {

    try {

      const data = {
        path,
      };

      const response = await axiosClient.post(`/api/permit/days/image`, data);

      dispatch({
        type: GET_PERMIT_DAYS_IMG,
        payload: response.data,
      });

    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const addVacationRequest = async (vacationRequest) => {
    dispatch({
      type: SET_LOADING,
    });

    try {
      const names = state.balanceDays.Nombres;

      const firstName = names.split(" ");

      const fullName = `${firstName} ${state.balanceDays.Apaterno}`;

      const data = {
        codigo: state.balanceDays.Codigo,
        rut: user.username,
        nombre: fullName,
        mail: user.email,
        fechaDesde:
          vacationRequest.days === 1
            ? moment(vacationRequest.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(vacationRequest.dateString[0], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        fechaHasta:
          vacationRequest.days === 1
            ? moment(vacationRequest.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(vacationRequest.dateString[1], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        diasHabiles: vacationRequest.days + vacationRequest.days_c,
        diasHabilesPendientes: state.balanceDays.Saldo,
        comentario: vacationRequest.comment,
        codigoJefe: state.balanceDays.codigoJefe,
        nombreJefe: state.balanceDays.NombreJefe,
        mailJefe: state.balanceDays.CorreoJefe,
        comprobante: vacationRequest.imgBase64,
        comprobanteExt: vacationRequest.imgExt,
        tipo: vacationRequest.type
      };

      const response = await axiosClient.post(`/api/permit/days`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };

      dispatch({
        type: ADD_PERMIT_DAYS_REQUEST,
        payload: alert,
      });

      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
        window.location.reload();
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const addVacationRequestRRHH = async (vacationRequest) => {
    dispatch({
      type: SET_LOADING,
    });

    try {
      const {Codigo, Nombres, Apaterno, Mail, Rut, Saldo, codigoJefe, NombreJefe, CorreoJefe} = state.balanceDays;
      const fullName = `${Nombres} ${Apaterno}`;

      const data = {
        codigo: Codigo,
        rut: Rut,
        nombre: fullName,
        mail: Mail,
        fechaDesde:
          vacationRequest.days === 1
            ? moment(vacationRequest.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(vacationRequest.dateString[0], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        fechaHasta:
          vacationRequest.days === 1
            ? moment(vacationRequest.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(vacationRequest.dateString[1], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        diasHabiles: vacationRequest.days + vacationRequest.days_c,
        diasHabilesPendientes: Saldo,
        comentario: vacationRequest.comment,
        codigoJefe: state.balanceDays.codigoJefe,
        nombreJefe: state.balanceDays.NombreJefe,
        mailJefe: state.balanceDays.CorreoJefe,
        comprobante: vacationRequest.imgBase64,
        comprobanteExt: vacationRequest.imgExt,
        tipo: vacationRequest.type
      };

      const response = await axiosClient.post(`/api/permit/rrhh/days`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };

      dispatch({
        type: ADD_PERMIT_DAYS_REQUEST,
        payload: alert,
      });

      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getVacationRequestsByCodEmployee = async (codEmployee) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.get(
        `/api/permit/days/employee/${codEmployee}`
      );

      dispatch({
        type: GET_DATA_PERMIT_REQUESTS_BY_CODEMPLOYEE,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getPendingVacationRequestsByCodEmployee = async (codEmployee) => {
    try {
      const response = await axiosClient.get(
        `/api/permit/days/employee/${codEmployee}/pending`
      );

      dispatch({
        type: GET_DATA_PENDING_PERMIT_REQUESTS_BY_CODEMPLOYEE,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getVacationRequestsByCodBoss = async (codBoss) => {
    dispatch({
      type: SET_LOADING,
    });

    const codBossFormat = codBoss.split("-");

    try {
      const response = await axiosClient.get(
        `/api/permit/days/boss/${codBossFormat[0]}`
      );

      dispatch({
        type: GET_DATA_PERMIT_REQUESTS_BY_CODBOSS,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateVacationRequest = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.put(`/api/permit/days/${id}`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPD_PERMIT_DAYS_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getVacationRequestsByRRHH = async (query = '') => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const url = query.length === 0 ? '/api/rrhh/permit' : `/api/rrhh/permit?query=${query}`
      const response = await axiosClient.get(url);

      dispatch({
        type: GET_PERMIT_DAYS_REQUEST,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const deleteAdditionalRequest = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.delete(
        `/api/permit/rrhh/days/${id}`
      );

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: DELETE_PERMIT_DAYS_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateVacationRequestRRHH = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const params = {
        diasHabilesPendientes: data.days,
        comentario: data.comment,
        fechaDesde: moment(data.dateString[0], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        fechaHasta: moment(data.dateString[1], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        diasHabiles: data.days + data.days_c,
        comentario: data.comment,
        tipo: data.tipo
      };
      const response = await axiosClient.put(
        `/api/permit/rrhh/days/${id}`,
        params
      );

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPDATE_PERMIT_REQUEST_RRHH,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  return (
    <permitiondaysContext.Provider
      value={{
        image: state.image,
        types: state.types,
        allTypes: state.allTypes,
        balanceDays: state.balanceDays,
        loading: state.loading,
        message: state.message,
        vacationRequests: state.vacationRequests,
        vacationRequestBoss: state.vacationRequestBoss,
        vacationRequestPending: state.vacationRequestPending,
        vacationRequestsRRHH: state.vacationRequestsRRHH,
        infoEmployee: state.infoEmployee,
        getTypes,
        getAllTypes,
        getBalanceDays,
        addVacationRequest,
        addVacationRequestRRHH,
        getVacationRequestsByCodEmployee,
        getPendingVacationRequestsByCodEmployee,
        getVacationRequestsByCodBoss,
        getVacationRequestsByRRHH,
        updateVacationRequest,
        deleteAdditionalRequest,
        updateVacationRequestRRHH,
        renderImageByPath,
        getInfoEmployee,
      }}
    >
      {props.children}
    </permitiondaysContext.Provider>
  );
};

export default PermitionDaysDataState;
