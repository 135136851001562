import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
  AutoComplete,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import AttendanceDaysContext from "../../context/attendancedays/attendancedaysContext";
import PermitionDaysContext from "../../context/permitiondays/permitiondaysContext";
import CommonContext from "../../context/commondata/commondataContext";
import AlertContext from "../../context/alert/alertContext";
import { countDaysNotWeekend } from "../../utils";



const FormRRHHAttendanceDays = (props) => {
  const commonContext = useContext(CommonContext);
  const attendanceDaysContext = useContext(AttendanceDaysContext);
  const permitiondaysContext = useContext(PermitionDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const [disabledForm, setDisabledForm] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [messageError, setMessageError] = useState("");
  const [user, setUser] = useState();
  const [userRRHH, setUserRRHH] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const { getEmployees, employees } = commonContext;

  const initInput = {
    date: "",
    dateString: "",
    tipo: "",
    days: 0,
    days_c: 0,
    days_label: 0,
    comment: "",
    imgBase64: "",
    imgExt: "",
    view_info_days: false
  };

  const [inputs, setInputs] = useState(initInput);
  const [file, setFile] = useState({
    fileImg: null,
    base64URL: ""
  });

  const [form] = Form.useForm();

  const {
    getAttendanceRequestsByCodEmployee,
    attendanceRequestPending,
    getPendingRequestByCodEmployee,
  } = attendanceDaysContext;

  const {
    getInfoEmployee,
    infoEmployee,
    types,
    balanceDays,
    vacationRequestPending,
    loading,
    message,
    getBalanceDays,
    addVacationRequestRRHH,
    getPendingVacationRequestsByCodEmployee,
    getTypes,
  } = permitiondaysContext;

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    if (!loading) {
      onSelect(userRRHH.username);
    }
  }, [employees]);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getBalanceDays(user.username);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    async function fetchInfoUser() {
      const user = JSON.parse(localStorage.getItem("user"));
      getInfoEmployee(user.username);
    }
    fetchInfoUser();
  }, []);

  useEffect(() => {
    async function fetchRequestUser() {
      const user = JSON.parse(localStorage.getItem("user"));
      getAttendanceRequestsByCodEmployee(user.username);
    }
    fetchRequestUser();
  }, []);

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 6 },
  };

  const { NombreJefe = ""} = balanceDays ?? {};

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const setInfoBalance = () => {
    const { NombreJefe } = balanceDays;
    setInputs({
      ...inputs,
      NombreJefe,
    });
  };

  useEffect(() => {
    if (!loading) {
      setInfoEmployee();
    }
  }, [infoEmployee, loading]);

  useEffect(() => {
    if (!loading && !firstLoading) {
      setInfoBalance();
    }
  }, [balanceDays, loading]);

  useEffect(() => {
    if (!loading && firstLoading) {
      setInputs({
        ...initInput,
      });
    }
  }, [loading]);

  const setInfoEmployee = () =>{
    const { Cod_Tipo_Documento } = infoEmployee ?? '1';
    setInputs({
      ...inputs,
      Cod_Tipo_Documento
    })
  };

  const renderOptionsType = () => {
    return tipoOptions.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };

  useEffect(() => {
    if (infoEmployee) {
      if(infoEmployee[0].Cod_Tipo_Documento == 1){
        showAlert("Actualmente no dispones de esta funcionalidad", "warning");
        setDisabledForm(true);
      }
      else{
        setDisabledForm(false);
      }
    }
  }, [infoEmployee]);

  const onChangeType = (value) => {
    setInputs({ ...inputs, tipo: value });
    if (attendanceRequests.length > 0 && attendanceRequests[0].Tipo == value) {
      showAlert("Revisa tipo de marcacion", "warning");
    }
    if (attendanceRequests.length == 0 && value == "Salida") {
      showAlert(
        "si no tienes marcaciones, debes ingresar una entrada",
        "warning"
      );
    }
  };

  const onChangeDate = (date, dateString) => {
    // setInputs({ ...inputs, dateString: dateString, date: date });
    if (date != null) {
        addWorkingDays(date, inputs.days);
    }
  };

  const disabledDate = (current) => {
    return (
      current < moment().subtract("1", "d").endOf("day") ||
      moment(current).day() === 0 ||
      moment(current).day() === 6
    );
  };

  const onSelect = (value) => {
    const userSplit = value.split("-");
    const user = `${userSplit[0]}-${userSplit[1]}`.trim();
    setMessageError("");
    setUser({ username: user });
    getInfoEmployee(user);
    getBalanceDays(user);
    getAttendanceRequestsByCodEmployee(user);
    getTypes(user);
    setInputs({
      ...initInput,
    });
    setFirstLoading(false);
    form.resetFields();
  };

  const handleFileInputChange = e => {
    let { fileImg } = file;

    fileImg = e.target.files[0];

    if (fileImg.type === 'image/jpeg' || fileImg.type === 'image/jpeg' || fileImg.type === 'application/pdf') {
      getBase64(fileImg)
        .then(result => {
          fileImg["base64"] = result;
          setFile({
            base64URL: result,
            fileImg
          });
          setInputs({ ...inputs, imgBase64: result, imgExt: fileImg.name.split('.').pop() });
        })
        .catch(err => {
          console.log(err);
        });

      setFile({
        fileImg: e.target.files[0]
      });

    } else {
      setFile({
        fileImg: null,
        base64URL: ""
      });
      setInputs({ ...inputs, imgBase64: "", imgExt: "" });
      showAlert("Solo soporta imagenes jpg o png, y archivos pdf", "warning");
      return;
    }
  };  

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onChangeTypeDays = async (value) => {

    const typeDay = await types.find((day) => day.id == value);

    setInputs({
      ...inputs,
      days: typeDay.days,
      days_c: typeDay.days_c,
      days_label: typeDay.label,
      type: value,
      dateString: ["", ""],
      date: "",
      view_info_days: typeDay.view_info
    });

    form.resetFields(['date']);

    if (typeDay.days + typeDay.days_c > 0 ) {
      setDisabledForm(false);
      // setActiveRequest(true);
    } else {
      setDisabledForm(true);
      // setActiveRequest(false);
    }
  };

  const renderOptionsTypeDays = () => {
    return types.map((type) => (
      <Option value={type.id} key={type.id}>
        {type.description}
      </Option>
    ));
  };

  const addWorkingDays = (date, days) => {
    let endDate = date.clone();
    for (let i = 1; i < days; i++) {
      if (endDate.isoWeekday() !== 6 && endDate.isoWeekday() !== 7) {
        endDate = endDate.add(1, "days");
      } else {
        endDate = endDate.add(1, "days");
        i--;
      }
    }
    endDate.add(inputs.days_c, 'days');
    const dates = [date.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY")];
    setInputs({ ...inputs, dateString: dates, date: date });
  };

  const onFinish = async () => {
    const { dateString, days } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    if (!dateString || !days) {
        showAlert("La fecha es requerida", "warning");
        return;
    }

    await addVacationRequestRRHH(inputs);
    
    form.resetFields();
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form.Item {...layout} label="Empleado" name="empleado">
        <AutoComplete
          placeholder="Seleccionar"
          allowClear={true}
          options={employees.map((x) => {
            return {
              rut: x.Rut,
              value: `${x.Rut} - ${x.Nombres} ${x.Apaterno} ${x.Amaterno}`,
            };
          })}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={onSelect}
          defaultValue={`${userRRHH.rut} - ${userRRHH.name} ${userRRHH.lastName}`}
        ></AutoComplete>
      </Form.Item>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >

      <Form.Item label="Jefe a cargo" name="jefe_cargo">
        <p style={{ margin: "auto" }}>{NombreJefe ? NombreJefe : ""}</p>
      </Form.Item>

      <Form.Item label="Motivo" name="days">
          <Select name="days" placeholder="Seleccione opción"  onChange={onChangeTypeDays}>
            {renderOptionsTypeDays()}
          </Select>
        </Form.Item>

      <Form.Item label="Seleccionar dia" name="date">
        <Space direction="vertical">
          <DatePicker
            name="date"
            style={{ width: "263px" }}
            format="DD-MM-YYYY"
            disabledDate={disabledDate}
            disabledTime={false}
            onChange={onChangeDate}
            disabled={disabledForm}
            locale={esES}
          />
        </Space>
      </Form.Item>

      <Form.Item label="Dias otorgados" name="dias_otorgados">
          <p style={{ margin: "auto" }}>
            {inputs.view_info_days == 0 && inputs.days > 0 && (inputs.days + ' dias Habiles')} {inputs.view_info_days == 0 && inputs.days > 0 && inputs.days_c > 0 && (' y ')} { inputs.view_info_days == 0 && inputs.days_c > 0 && (inputs.days_c + ' dias corridos')}
            {inputs.view_info_days == 1 && inputs.days_label + ' dias Habiles'}
          </p>
        </Form.Item>

      <Form.Item label="Hasta" name="hasta">
          <p style={{ margin: "auto" }}>{inputs.dateString[1] ? inputs.dateString[1] : ""}</p>
        </Form.Item>

      <Form.Item label="Comentario" name="commentLabel">
        <TextArea
          name="comment"
          rows={4}
          onChange={onChange}
          disabled={disabledForm}
        />
      </Form.Item>

      <Form.Item label="Imagen Comprobante" name="fileLabel">
          <input type="file" name="file" onChange={handleFileInputChange} disabled={disabledForm} multiple={false} accept="image/*,application/pdf" />
        </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" disabled={disabledForm}>
          Solicitar
        </Button>
      </Form.Item>
      <Form.Item {...tailLayout}>
        {alert && <Alert message={alert.msg} type={alert.category} />}
      </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormRRHHAttendanceDays;
