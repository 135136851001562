import React from "react";
import FilesState from "../../../context/files/filesState";
import DetailsUploadFile from "./DetailsUploadFile";

const FormDetails = () => {
  return (
    <FilesState>
      <DetailsUploadFile></DetailsUploadFile>
    </FilesState>
  );
};

export default FormDetails;
