import React, { useState, useContext } from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import mainLogo from "../../assets/logo/logo_crossover.png";

import Alert from "../core/Alert";
import { validationEmail } from "../../utils/formValidation";
import AlertContext from "../../context/alert/alertContext";

import axiosClient from "../../config/axios";

const ForgotPassword = (props) => {
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { showLogin, version } = props;

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const onFinish = async () => {
    setLoading(true);
    //validate empty fields
    if (email.trim() === "") {
      showAlert("El email es requerido.", "warning");
      setLoading(false);
      return;
    }

    //validate email format
    const resultValidation = validationEmail(email);

    if (!resultValidation) {
      showAlert("El email no es valido.", "warning");
      setLoading(false);
      return;
    }

    try {
      const data = {
        email,
      };

      const response = await axiosClient.post("/api/auth/forgotpassword", data);

      if (response) {
        showAlert(
          `El correo ha sido enviado a la casilla: ${email} por favor revise para recuperar su contraseña`,
          "success"
        );
      }
      form.resetFields();
      setLoading(false);
    } catch (error) {
      form.resetFields();
      showAlert(error.response.data.message, "error");
      setLoading(false);
    }
  };

  return (
    <Form
      name="normal_login"
      style={{ width: "400px" }}
      onFinish={onFinish}
      form={form}
    >
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img src={mainLogo} alt="logo" />
      </div>
      <Form.Item name="email">
        <Input
          name="email"
          prefix={<UserOutlined />}
          placeholder="Correo electrónico"
          value={email}
          onChange={onChange}
        />
      </Form.Item>

      {alert && <Alert message={alert.msg} type={alert.category} />}

      <Form.Item>
        <a onClick={showLogin}>Volver a inicio de sesión</a>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading && true}
        >
          Enviar
        </Button>
      </Form.Item>
      <div style={{ textAlign: "center" }}>{`${version}`}</div>
    </Form>
  );
};

export default ForgotPassword;
