import React, { useReducer } from "react";
import ReportsContext from "./reportsContext";
import ReportsReducer from "./reportsReducer";
import * as types from "../../types";
import axiosClient from "../../config/axios";

const ReportsState = (props) => {
  const initialState = {
    reports: [],
    years : [],
    loading: true,
    message: null,
  };

  const [state, dispatch] = useReducer(ReportsReducer, initialState);

  const getReports = async () => {
    try {
      dispatch({
        type: types.SET_LOADING,
      });

      const response = await axiosClient.get(`/api/report`);

      dispatch({
        type: types.GET_REPORTS,
        payload: response.data.reports,
      });
    } catch (error) {
      dispatch({
        type: types.ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const getReportsYears = async () => {
    try {
      dispatch({
        type: types.SET_LOADING,
      });

      const response = await axiosClient.get(`/api/report/years`);

      dispatch({
        type: types.GET_REPORTS_YEARS,
        payload: response.data.years,
      });
    } catch (error) {
      dispatch({
        type: types.ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  return (
    <ReportsContext.Provider
      value={{
        loading: state.loading,
        reports: state.reports,
        years: state.years,
        getReports,
        getReportsYears
      }}
    >
      {props.children}
    </ReportsContext.Provider>
  );
};

export default ReportsState;
