import * as types from "../../types";

export default (state, action) => {
  switch (action.type) {
    case types.SET_LOADING:
        return {
          ...state,
          loading: true,
        };
    case types.GET_REPORTS:
      return {
        ...state,
        reports: action.payload,
        loading: false,
      };
    case types.GET_REPORTS_YEARS:
        return {
          ...state,
          years: action.payload,
          loading: false,
        };
    case types.ERROR_GET_DATA:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
