import React, { useEffect, useContext, useState } from "react";
import { Tag, Space, Table, Alert, Modal as ModalAntd } from "antd";
import Modal from "../core/Modal";
import moment from "moment";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AttendanceDaysContext from "../../context/attendancedays/attendancedaysContext";
import PermitionDaysContext from "../../context/permitiondays/permitiondaysContext";
import FormEditPermitionDay from "../permitiondays/FormPermitionDay/FormEditPermitionDay";
import AlertContext from "../../context/alert/alertContext";
import Search from "antd/lib/input/Search";

const PermitionRRHHRequest = () => {

  const permitiondaysContext = useContext(PermitionDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    balanceDays,
    vacationRequestPending,
    updateVacationRequest,
    deleteAdditionalRequest,
    vacationRequestsRRHH,
    loading,
    message,
    getBalanceDays,
    addVacationRequestRRHH,
    getVacationRequestsByRRHH,
    allTypes,
    getAllTypes,
  } = permitiondaysContext;

  const showModalUpdateRequest = (record, action) => {
    
    
    if(action === 'edit'){
      setIsVisibleModal(true);
      setModalTitle("Editar Solicitud");
      setModalContent(
        <FormEditPermitionDay
          setIsVisibleModal={setIsVisibleModal}
          updateAttendanceRequest={updateVacationRequest}
          record={record}
          alert={alert}
          message={message}
          showAlert={showAlert}
        ></FormEditPermitionDay>
      );
      setWidth(600);
    }else{

      ModalAntd.confirm({
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: <p>¿Esta seguro que desea eliminar la solicitud?</p>,
        onOk() {
            deleteAdditionalRequest(record.Id);
        },
        onCancel() {
        },
      }) 
    }
  };

  useEffect(() => {
    function fetchGetTypes() {
      getAllTypes();
    }
    fetchGetTypes();
  }, []);

  useEffect(() => {
    function fetchData() {
        getVacationRequestsByRRHH();
    }
    fetchData();
  }, [message]);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  //modal variables
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [width, setWidth] = useState();

  // const showModalUpdateRequest = (record, action) => {
  //   setIsVisibleModal(true);
  //   //set title modal
  //   setModalTitle("Actualizar solicitud");
  //   //set modal content, in this case, form edit
  //   setModalContent(
  //     <FormApproveDay
  //       record={record}
  //       action={action}
  //       setIsVisibleModal={setIsVisibleModal}
  //       updateAttendanceRequest={updateAttendanceRequest}
  //       alert={alert}
  //       message={message}
  //       showAlert={showAlert}
  //     />
  //   );
  // };

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "CodigoEmpleado",
    },
    {
      title: "Nombre colaborador",
      dataIndex: "Nombre",
      key: "Nombre",
    },
    {
      title: "Fecha solicitud",
      dataIndex: "FechaCreacion",
      key: "FechaCreacion",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "Estado",
      render: (status) => (
        <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
          {status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Fecha Desde",
      dataIndex: "FechaDesde",
      key: "FechaDesde",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Fecha Hasta",
      dataIndex: "FechaHasta",
      key: "FechaHasta",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Dias Solicitados",
      dataIndex: "DiasSolicitados",
      key: "DiasSolicitados",
      render: (text, record) => (
        <>
          { parseInt(text) }
        </>
      ),
    },
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "Tipo",
      render: (text, record) => (
        <Tag color="blue">
          { allTypes.find(data => record.Tipo === data.id)?.description }
        </Tag>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">        
            <>
            <a onClick={() => showModalUpdateRequest(record, "edit")}>
              Editar
            </a>
            <a onClick={() => showModalUpdateRequest(record, "delete")}>
              Eliminar
            </a>
            </>         
        </Space>
      ),
    },
  ];

  if (!vacationRequestsRRHH) {
    return "loading...";
  }
  

  const onSearch = (value) =>{
    console.log("onSearch", value);
    getVacationRequestsByRRHH(value.trim());
  }

  return (
    <>
      {alert && (
        <Alert
          style={{ marginBottom: "10px" }}
          message={alert.msg}
          type={alert.category}
        />
      )}
      <Search placeholder="Busqueda por rut o nombre" allowClear={true} enterButton="Buscar" size="middle" onSearch={onSearch}/>
      <br></br>
      <br></br>
      <Table dataSource={vacationRequestsRRHH} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={width}
      >
        {modalContent}
      </Modal>
    </>
  );

}

export default PermitionRRHHRequest;