import React, { useEffect, useContext, useState } from "react";
import { Tag, Space, Table, Modal as ModalAntd } from "antd";
import Modal from "../core/Modal";
import moment from "moment";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AttendanceDaysContext from "../../context/attendancedays/attendancedaysContext";
import FormEditAttendanceDay from "../attendancedays/FormAttendanceDay/FormEditAttendanceDay";
import alertContext from "../../context/alert/alertContext";
import Search from "antd/lib/input/Search";

const AttendanceRRHHRequest = () => {

  const attendanceDaysContext = useContext(AttendanceDaysContext);
  const { alert, showAlert } = alertContext;
  const { 
    message,
    attendanceRequestsRRHH,    
    loading,
    getAttendanceRequestsByRRHH,
    getAttendanceRRHH,
    updateAttendanceRequest,
    deleteAttendanceRequest
  } = attendanceDaysContext;

  const { getTypes, types } = attendanceDaysContext;

  const showModalUpdateRequest = (record, action) => {
    
    
    if(action === 'edit'){
      setIsVisibleModal(true);
      setModalTitle("Editar Solicitud");
      setModalContent(
        <FormEditAttendanceDay
        setIsVisibleModal={setIsVisibleModal}
        updateAttendanceRequest={updateAttendanceRequest}
        record={record}
        ></FormEditAttendanceDay>
      );
      setWidth(600);
    }else{

      ModalAntd.confirm({
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: <p>¿Esta seguro que desea eliminar la solicitud?</p>,
        onOk() {
          deleteAttendanceRequest(record.Id);
        },
        onCancel() {
        },
      }) 
    }
  };

  // useEffect(() => {
  //   async function fetchGetTypes() {
  //     getTypes();
  //   }
  //   fetchGetTypes();
  // }, []);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getAttendanceRequestsByRRHH();
    }
    fetchData();
  }, [message]);

  // useEffect(() => {
  //   if (message) {
  //     showAlert(message.msg, message.category);
  //   }
  // }, [message]);

  //modal variables
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [width, setWidth] = useState();

  // const showModalUpdateRequest = (record, action) => {
  //   setIsVisibleModal(true);
  //   //set title modal
  //   setModalTitle("Actualizar solicitud");
  //   //set modal content, in this case, form edit
  //   setModalContent(
  //     <FormApproveDay
  //       record={record}
  //       action={action}
  //       setIsVisibleModal={setIsVisibleModal}
  //       updateAttendanceRequest={updateAttendanceRequest}
  //       alert={alert}
  //       message={message}
  //       showAlert={showAlert}
  //     />
  //   );
  // };

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "1",
    },
    {
      title: "Nombre colaborador",
      dataIndex: "Nombre",
      key: "10",
    },
    {
      title: "Fecha solicitud",
      dataIndex: "FechaCreacion",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "2",
      render: (status) => (
        <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
          {status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Fecha Marcacion",
      dataIndex: "FechaMarcacion",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Hora Marcacion",
      dataIndex: "HoraMarcacion",
      key: "7",
    },
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "8",
      render: (tipo) => (
        <Tag color={tipo === "Entrada" ? "blue" : "red"}>
          {tipo}
        </Tag>
      ),
    },
    {
      title: "Comentario colaborador",
      dataIndex: "Comentario",
      key: "3",
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">        
            <>
            <a onClick={() => showModalUpdateRequest(record, "edit")}>
              Editar
            </a>
            <a onClick={() => showModalUpdateRequest(record, "delete")}>
              Eliminar
            </a>
            </>         
        </Space>
      ),
    },
  ];

  if (!attendanceRequestsRRHH) {
    return "loading...";
  }
  

  const onSearch = (value) =>{
    console.log("onSearch", value);
    getAttendanceRequestsByRRHH(value.trim());
  }

  return (
    <>
      <Search placeholder="Busqueda por rut o nombre" allowClear={true} enterButton="Buscar" size="middle" onSearch={onSearch}/>
      <br></br>
      <br></br>
      <Table dataSource={attendanceRequestsRRHH} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={width}
      >
        {modalContent}
      </Modal>
    </>
  );

}

export default AttendanceRRHHRequest;