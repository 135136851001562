import React, { useEffect, useContext, useState } from "react";
import { Tag, Table, Space } from "antd";
import moment from "moment";
import LegalVacationDaysContext from "../../context/legalvacationdays/legalvacationdaysContext";
import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";
import FormEditLegalVacationDay from "./FormLegalVacationDays/FormEditLegalVacationDays";
import Modal from "../core/Modal";

const MyRequests = () => {
  const legalVacationDaysContext = useContext(LegalVacationDaysContext);
  const administrativelDaysContext = useContext(AdministrativelDaysContext);

  const {
    legalvacationRequests,
    loading,
    getLegalVacationRequestsByCodEmployee,
  } = legalVacationDaysContext;

  const { getTypes, types } = administrativelDaysContext;

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [width, setWidth] = useState();

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getLegalVacationRequestsByCodEmployee(user.username);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchGetTypes() {
      getTypes();
    }
    fetchGetTypes();
  }, []);

  const showModalUpdateRequest = (record, action) => {      
      
    if(action === 'edit'){
      setIsVisibleModal(true);
      setModalTitle("Editar Solicitud");
      setModalContent(
        <FormEditLegalVacationDay
        setIsVisibleModal={setIsVisibleModal}
        record={record}
        ></FormEditLegalVacationDay>
      );
      setWidth(600);
    }else{

      ModalAntd.confirm({
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: <p>¿Esta seguro que desea eliminar la solicitud?</p>,
        onOk() {
          deleteLegalVacationRequest(record.Id);
        },
        onCancel() {
        },
      }) 
    }

  };

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "1",
    },
    {
      title: "Origen",
      dataIndex: "Origen",
      key: "2",
      render: (status) => (
        <Tag color={status && status === "WEB" ? "blue" : "green"}>
          {status ? status : 'PAYROLL'}
        </Tag>
      ),
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "3",
      render: (status) => (
        <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
          {status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Fecha desde",
      dataIndex: "FechaDesde",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Fecha hasta",
      dataIndex: "FechaHasta",
      key: "7",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Dias",
      dataIndex: "DiasSolicitados",
      key: "8",
    },
    {
      title: "Mi comentario",
      dataIndex: "Comentario",
      key: "3",
    },
    {
      title: "Nombre jefe",
      dataIndex: "NombreJefe",
      key: "4",
    },
    {
      title: "Comentario jefe",
      dataIndex: "ComentarioJefe",
      key: "5",
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">        
            <>
            {record.Estado === 'A' && 
            <a onClick={() => showModalUpdateRequest(record, "edit")}>
              Editar
            </a>
            }
            </>         
        </Space>
      ),
    },
  ];

  if (!legalvacationRequests) {
    return "loading...";
  }

  return (
    <>
    <Table dataSource={legalvacationRequests} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
    <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={width}
      >
        {modalContent}
      </Modal>
    </> 
  );
};

export default MyRequests;
