import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import { countDaysNotWeekend } from "../../../utils";

const TYPE_NACIMIENTO_HIJO = 1;
const TYPE_PERMISO_MEDIA_DIA = 0.5;
const TYPE_PERMISO_DIA = 1;
const DIA_ADMINISTRATIVO = 5;
const MEDIO_DIA_ADMINISTRATIVO = 6;

const FormAdministrativeDays = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const {
    loading,
    message,
    alert,
    showAlert,
    types,
    balanceDays,
    additional,
    holidays,
    adminCondition,
    vacationHistory,
    adminRequestsPending,
    addAdministrativeDaysRequest,
    getAdditionalAdminDaysByCodEmployee,
    getPendingAdminRequestsByCodEmployee,
  } = props;

  const [inputs, setInputs] = useState({
    date: "",
    dateString: "",
    days: "",
    comment: "",
    type: TYPE_NACIMIENTO_HIJO,
  });

  const [disabledForm, setDisabledForm] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [activeRequest, setActiveRequest] = useState(false);
  const [messageError, setMessageError] = useState("");

  const { NombreJefe } = balanceDays;

  const initialValues = {};

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    if (!loading && firstLoading) {
      setInputs({ ...inputs });
      setFirstLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    validateSaldo();
  }, [additional, loading]);

  useEffect(() => {
    validateRequestPending();
  });

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 6 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 6 },
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  const onChangeTypeDays = (value) => {
    setInputs({
      ...inputs,
      days: types.find((day) => day.id === value).days,
      type: value,
    });

    if (value === DIA_ADMINISTRATIVO || value === MEDIO_DIA_ADMINISTRATIVO) {
      getAdditionalAdminDaysByCodEmployee(user.username);
      getPendingAdminRequestsByCodEmployee(user.username);
    } else {
      setDisabledForm(false);
      setActiveRequest(false);
    }
  };

  const validateRequestPending = () => {
    if (adminRequestsPending.length > 0) {
      setActiveRequest(true);
      setMessageError("Ya se encuentra una solicitud en proceso");
      setDisabledForm(true);
    } else {
      setActiveRequest(false);
      setDisabledForm(false);
      setMessageError("");
      validateSaldo();
    }
  };

  const validateSaldo = () => {
    if (
      inputs.type === DIA_ADMINISTRATIVO ||
      inputs.type === MEDIO_DIA_ADMINISTRATIVO
    ) {
      if (additional.Saldo < inputs.days) {
        setMessageError(
          "Actualmente no dispones de saldo para tomar dias administrativos"
        );
        setDisabledForm(true);
      } else {
        if (additional.payroll && !additional.payroll.enabled) {
          setMessageError(additional.payroll.message);
          setDisabledForm(true);
        } else {
          setMessageError("");
          setDisabledForm(false);
        }
      }
    }
  };

  const renderOptionsTypeDays = () => {
    return types.map((type) => (
      <Option value={type.id} key={type.id}>
        {type.description}
      </Option>
    ));
  };

  const disabledDate = (current) => {

    let disabledHolidays = holidays.map((data) => moment(data.Fecha));
    let finalHolidays = [];
    let vacations = [];

    for (let i = 0; i < vacationHistory.length; i++) {
      let inicio = moment(vacationHistory[i].Fecha_Inicio);
      let fin = moment(vacationHistory[i].Fecha_Fin);
      vacations = vacations.concat(enumerateDaysBetweenDates(inicio, fin));
    }

    for (let i = 0; i < disabledHolidays.length; i++) {
      let currDate = moment(disabledHolidays[i]).startOf('day');
      if (adminCondition.PermiteVisperaFeriados === 0) {
        let beforeDate = moment(currDate.clone().add(-1, 'days').format('YYYY-MM-DD'));
        finalHolidays.push(beforeDate);
        finalHolidays.push(currDate.clone());
        let afterDate = moment(currDate.clone().add(1, 'days').format('YYYY-MM-DD'));
        finalHolidays.push(afterDate);
      } else {
        finalHolidays.push(currDate.clone());
      }
    }

    const disabledDates = vacations.concat(finalHolidays);    

    return disabledDates.find(date => date.isSame(current, 'day')) || moment(current).day() === 0 || moment(current).day() === 6;
  };

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    let dates = [];

    let currDate = moment(startDate).startOf('day');
    let lastDate = moment(endDate).startOf('day');

    if (adminCondition.PermiteVisperaVacaciones === 0) {
      currDate = moment(currDate.add(-1, 'days').format('YYYY-MM-DD'));
      lastDate = moment(lastDate.add(1, 'days').format('YYYY-MM-DD'));
    }

    dates.push(moment(currDate.clone().format('YYYY-MM-DD')));

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(moment(currDate.clone().format('YYYY-MM-DD')));
    }

    dates.push(moment(lastDate.clone().format('YYYY-MM-DD')));

    return dates;
};

  /**
   * @author Hector Martinez
   * @description submit form
   */
  const onFinish = async () => {
    const { dateString, days, type } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays =
      typeof dateString === "string"
        ? days === TYPE_PERMISO_MEDIA_DIA
          ? TYPE_PERMISO_MEDIA_DIA
          : TYPE_PERMISO_DIA
        : countDaysNotWeekend(startDay, endDay);

    if (!type) {
      showAlert("Motivo es requerido", "warning");
      return;
    }

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    if((days > 1 && 
      (startDay.year() !== moment().year() || endDay.year() !== moment().year())) 
      || 
    (days <= 1 && 
      moment(dateString, "DD-MM-YYYY").year() !== moment().year()))
    {
      showAlert(`La fecha solicitada debe ser dentro del mismo año (${moment().year()})`, "warning");
      return;
    }

    if (diffDays < days) {
      showAlert("El rango indicado es menor a lo solicitado.", "warning");
      return;
    }

    if (diffDays > days) {
      showAlert("El rango indicado es mayor a lo solicitado.", "warning");
      return;
    }

    if (adminRequestsPending.length > 0) {
      showAlert("Ya se encuentra una solicitud en proceso", "warning");
      return;
    } 
    await addAdministrativeDaysRequest({ ...inputs, balanceDays });

    form.resetFields();
  };

  // console.log("inputs", inputs.days);
  // console.log("disabledForm", disabledForm);
  // console.log("additional", additional)
  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{NombreJefe ? NombreJefe : ""}</p>
        </Form.Item>

        <Form.Item label="Motivo" name="days">
          <Select name="days" placeholder="Seleccione opción"  onChange={onChangeTypeDays}>
            {renderOptionsTypeDays()}
          </Select>
        </Form.Item>

        <Form.Item label="Dias a solicitar" name="dias_otorgados">
          <p style={{ margin: "auto" }}>{inputs.days}</p>
        </Form.Item>

        {inputs.type === DIA_ADMINISTRATIVO ||
        inputs.type == MEDIO_DIA_ADMINISTRATIVO ? (
          <Form.Item label="Saldo" name="saldo">
            <p style={{ margin: "auto" }}>{additional.Saldo}</p>
          </Form.Item>
        ) : null}

        {messageError.length > 0 ? (
          <Alert
            message={messageError}
            type="info"
            showIcon
            style={{
              marginLeft: "10%",
              minWidth: "40%",
              maxWidth: "45%",
              marginBottom: "20px",
            }}
          />
        ) : null}

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
            {inputs.days === 1 || inputs.days === 0.5 ? (
              <DatePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
              />
            ) : (
              <RangePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
              />
            )}
          </Space>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
            disabled={disabledForm}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            Solicitar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormAdministrativeDays;
