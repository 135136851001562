import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Space,
  Spin,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import AdministrativelDaysContext from "../../../context/administrativedays/administrativedaysContext";
import { countDaysNotWeekend } from "../../../utils";

const TYPE_NACIMIENTO_HIJO = 1;
const TYPE_PERMISO_MEDIA_DIA = 0.5;
const TYPE_PERMISO_DIA = 1;
const DIA_ADMINISTRATIVO = 5;
const MEDIO_DIA_ADMINISTRATIVO = 6;

const initInput = {
  dateOri:"",
  date: "",
  dateStringOri: "",
  dateString: "",
  daysOri: "",
  days:"",
  commentOri: "",
  comment: "",
  typeOri: "",
  type: "",
  NombreJefeOri: "",
  NombreJefe: "",
};

const FormDetailsAdministrativeDays = (props) => {
  const { record, recordDetails, setIsVisibleModal } = props;

  const administrativelDaysContext = useContext(AdministrativelDaysContext);

  const {
    getTypes,
    additional,
    loading,
    message,
    types,
    getAdditionalAdminDaysByCodEmployee,
    getPendingAdminRequestsByCodEmployee,
    updAdministrativeDaysRequestRRHH,
    getAdminRequestsByRRHH
  } = administrativelDaysContext;

  const [inputs, setInputs] = useState(initInput);

  const [disabledForm, setDisabledForm] = useState(false);
  const [messageError, setMessageError] = useState("");
  let initialValues 

  if(recordDetails){
    initialValues = {
      days: recordDetails.Tipo,
      daysOri: record.Tipo,
      commentLabel: recordDetails.Comentario,
      commentLabelOri: record.Comentario,
    };
  }else{
    initialValues = {
      daysOri: record.Tipo,
      commentLabelOri: record.Comentario,
    };
  }

  

  useEffect(() => {
    console.log("record", record);
    form.resetFields();
    getTypes();
    if(recordDetails){
      getAdditionalAdminDaysByCodEmployee(recordDetails.CodigoEmpleado);
      setInputs({
        NombreJefeOri: record.NombreJefe,
        NombreJefe: recordDetails.NombreJefe,
        daysOri: types.find((day) => day.id === record.Tipo).days,
        days: types.find((day) => day.id === recordDetails.Tipo).days,
        typeOri: record.Tipo,
        type: recordDetails.Tipo,
        dateOri: moment(record.FechaDesde, "YYYY-MM-DD"),
        date: moment(recordDetails.FechaDesde, "YYYY-MM-DD"),
        commentOri: record.Comentario,
        comment: recordDetails.Comentario,
        dateStringOri: moment(record.FechaDesde, "YYYY-MM-DD").format("DD-MM-YYYY"),
        dateString: moment(recordDetails.FechaDesde, "YYYY-MM-DD").format("DD-MM-YYYY"),
      });
    }else{
      setInputs({
        NombreJefeOri: record.NombreJefe,
        daysOri: types.find((day) => day.id === record.Tipo).days,
        typeOri: record.Tipo,
        dateOri: moment(record.FechaDesde, "YYYY-MM-DD"),
        commentOri: record.Comentario,
        dateStringOri: moment(record.FechaDesde, "YYYY-MM-DD").format("DD-MM-YYYY"),
      });
    }
  }, [recordDetails]);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    setDisabledForm(true);
  });

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 }
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const onChange = (e) => {
    console.log(e);
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDate = (date, dateString) => {
    console.log("onChangeDate", date, dateString);
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  const onChangeTypeDays = (value) => {
    setInputs({
      ...inputs,
      days: types.find((day) => day.id === value).days,
      type: value,
    });

    if (value === DIA_ADMINISTRATIVO || value === MEDIO_DIA_ADMINISTRATIVO) {
      getAdditionalAdminDaysByCodEmployee(recordDetails.CodigoEmpleado);
      getPendingAdminRequestsByCodEmployee(recordDetails.CodigoEmpleado);
      setDisabledForm(true);
    } else {
      setDisabledForm(true);
    }
  };

  const renderOptionsTypeDays = () => {
    return types.map((type) => (
      <Option value={type.id} key={type.id}>
        {type.description}
      </Option>
    ));
  };

  const disabledDate = (current) => {
    return moment(current).day() === 0 || moment(current).day() === 6;
  };

  

  /**
   * @author Hector Martinez
   * @description submit form
   */
  const onFinish = async () => {
    const { dateString, days, type } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays =
      typeof dateString === "string"
        ? days === TYPE_PERMISO_MEDIA_DIA
          ? TYPE_PERMISO_MEDIA_DIA
          : TYPE_PERMISO_DIA
        : countDaysNotWeekend(startDay, endDay);

    if (!type) {
      showAlert("Motivo es requerido", "warning");
      return;
    }

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    if((days > 1 && 
      (startDay.year() !== moment().year() || endDay.year() !== moment().year())) 
      || 
    (days <= 1 && 
      moment(dateString, "DD-MM-YYYY").year() !== moment().year()))
    {
      showAlert(`La fecha solicitada debe ser dentro del mismo año (${moment().year()})`, "warning");
      return;
    }

    if (diffDays < days) {
      showAlert("El rango indicado es menor a lo solicitado.", "warning");
      return;
    }

    if (diffDays > days) {
      showAlert("El rango indicado es mayor a lo solicitado.", "warning");
      return;
    }

    updAdministrativeDaysRequestRRHH({ ...inputs, id : recordDetails.Id});
    getAdminRequestsByRRHH();
    setIsVisibleModal(false);

  };

  return (
    //
    <Spin tip="Cargando..." spinning={loading}>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={initialValues}
        preserve={false}
        onFinish={onFinish}
      >
        
        <Form.Item label="Rut" name="rut_colaborador">
          <p style={{ margin: "auto" }}>
            {record.CodigoEmpleado ? record.CodigoEmpleado : ""}
          </p>
        </Form.Item>

        <Form.Item label="Nombre" name="nombre_colaborador">
          <p style={{ margin: "auto" }}>{record.Nombre ? record.Nombre : ""}</p>
        </Form.Item>

        <div>

          <h2>Solicitud Original</h2>

          <Form.Item label="Jefe a cargo" name="jefe_cargo">
            <p style={{ margin: "auto"}}>
              {inputs.NombreJefeOri}
            </p>
          </Form.Item>

          <Form.Item label="Motivo" name="daysOri">
            <Select name="daysOri" onChange={onChangeTypeDays} disabled={setDisabledForm}>
              {renderOptionsTypeDays()}
            </Select>
          </Form.Item>

          <Form.Item label="Dia solicitado" name="dias_otorgados_Ori">
            <p style={{ margin: "auto" }}>{inputs.daysOri}</p>
          </Form.Item>

          {inputs.type === DIA_ADMINISTRATIVO ||
            inputs.type == MEDIO_DIA_ADMINISTRATIVO ? (
            <Form.Item label="Saldo" name="saldoOri">
              <p style={{ margin: "auto" }}>
                {+additional.Saldo + + record.DiasSolicitados} - {record.DiasSolicitados} ={" "}
                {+additional.Saldo + + record.DiasSolicitados - record.DiasSolicitados}
              </p>
            </Form.Item>
          ) : null}

          <Form.Item label="Fecha Solicitada" name="dateOri">
            <Space direction="vertical">
              {
               <DatePicker
                  name="date"
                  style={{ width: "263px" }}
                  format="DD-MM-YYYY"
                  disabledDate={disabledDate}
                  onChange={onChangeDate}
                  disabled={disabledForm}
                  locale={esES}
                  defaultValue={moment(record.FechaDesde, "YYYY-MM-DD")}
                />
              }
            </Space>
          </Form.Item>

          <Form.Item label="Comentario" name="commentLabelOri">
            <TextArea
              name="commentOri"
              rows={4}
              onChange={onChange}
              disabled={disabledForm}
            />
          </Form.Item>
        </div>


        <div>
          {recordDetails ? (  
            <>
          <h2>Solicitud de Modificación</h2>
            <Form.Item label="Jefe a cargo" name="jefe_cargo">
              <p style={{ margin: "auto" }}>
                {recordDetails.NombreJefe ? recordDetails.NombreJefe : ""}
              </p>
            </Form.Item>

            <Form.Item label="Motivo" name="days">
              <Select name="days" onChange={onChangeTypeDays} disabled={setDisabledForm}>
                {renderOptionsTypeDays()}
              </Select>
            </Form.Item>

            <Form.Item label="Dia a solicitar" name="dias_otorgados">
              <p style={{ margin: "auto" }}>{inputs.days}</p>
            </Form.Item>

            {inputs.type === DIA_ADMINISTRATIVO ||
              inputs.type == MEDIO_DIA_ADMINISTRATIVO ? (
              <Form.Item label="Nuevo Saldo" name="saldo">
                <p style={{ margin: "auto" }}>
                  {+additional.Saldo + + record.DiasSolicitados} - {inputs.days} ={" "}
                  {+additional.Saldo + + record.DiasSolicitados - inputs.days}
                </p>
              </Form.Item>
            ) : null}

            <Form.Item label="Fecha Solicitada" name="date">
              <Space direction="vertical">
                {
                  <DatePicker
                    name="date"
                    style={{ width: "263px" }}
                    format="DD-MM-YYYY"
                    disabledDate={disabledDate}
                    onChange={onChangeDate}
                    disabled={disabledForm}
                    locale={esES}
                    // value={inputs.date}
                    defaultValue={moment(recordDetails.FechaDesde, "YYYY-MM-DD")}
                  />
                }
              </Space>
            </Form.Item>

            <Form.Item label="Comentario" name="commentLabel">
              <TextArea
                name="comment"
                rows={4}
                onChange={onChange}
                disabled={disabledForm}
              />
            </Form.Item>
            </>   
          ) :<></>}
        </div>
      </Form>
    </Spin>
  );
};

export default FormDetailsAdministrativeDays;
