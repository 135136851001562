import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import { countDaysNotWeekend } from "../../../utils";

const FormLegalVacationsDays = (props) => {
  const {
    balanceDays,
    loading,
    message,
    alert,
    showAlert,
    addLegalVacationRequest,
    legalvacationRequestPending
  } = props;

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    if(!loading){
      setInfoBalance();
    }
  }, [balanceDays, loading]);

  const [inputs, setInputs] = useState({
    date: "",
    dateString: "",
    days: 1,
    comment: "",
  });

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 6 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 6 },
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const setInfoBalance = () =>{
    const { NombreJefe } = balanceDays;
    setInputs({
      ...inputs,
      NombreJefe
    })
  }

  const onChangeDate = (date, dateString) => {
    // setInputs({ ...inputs, dateString: dateString, date: date });
    if (date != null) {
      addWorkingDays(date, inputs.days);
    }
  };

  const addWorkingDays = (date, days) => {
    let endDate = date.clone();
    for (let i = 1; i < days; i++) {
      if (endDate.isoWeekday() !== 6 && endDate.isoWeekday() !== 7) {
        endDate = endDate.add(1, "days");
      } else {
        endDate = endDate.add(1, "days");
        i--;
      }
    }
    endDate.add(inputs.days_c, 'days');
    const dates = [date.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY")];
    setInputs({ ...inputs, dateString: dates, date: date });
  };

  const onChangeCountDays = (value) => {
    const { dateString } = inputs;
    if (dateString[0] != null) {
      let date = moment(dateString[0], "DD-MM-YYYY");
      addWorkingDays(date, parseInt(value));
    }
    setInputs({ ...inputs, days: value });
  };

  const renderOptionsBalance = (balance) => {
    let array = [];
    for (var i = 1; i <= balance; i++) {
      array.push(i);
    }

    return array.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    return (
      (current && current > moment().endOf("day")) || moment(current).day() === 0 || moment(current).day() === 6
    );
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  const onFinish = async () => {
    const { dateString, days } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays = countDaysNotWeekend(startDay,endDay);

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }
    
    if (diffDays < days && days !== 1) {
      showAlert("El rango indicado es menor a lo solicitado.", "warning");
      return;
    }

    if (diffDays > days && days !== 1) {
      showAlert("El rango indicado es mayor a lo solicitado.", "warning");
      return;
    }

    await addLegalVacationRequest(inputs);

    form.resetFields();
  };

  const { Saldo = 0, NombreJefe = '',  DiasOtorgados = '' } = balanceDays ?? {};

  let disabledForm = true;
  
  if(balanceDays && Saldo){
    disabledForm = Saldo === 0 ? true : false;
  }

  disabledForm = false;

  return (
    <Spin tip="Cargando..." spinning={loading}>

      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        {disabledForm && (
              <Alert
                message="Actualmente no dispones de saldo para tomar dias adicionales"
                type="info"
                showIcon
                style={{ marginLeft: "10%", minWidth: "40%", maxWidth:"45%", marginBottom: "20px" }}
              />
        )}

        {/* <Form.Item label="Dias otorgados" name="dias_otorgados">
          <p style={{ margin: "auto" }}>
            {DiasOtorgados ? DiasOtorgados : "0"}
          </p>
        </Form.Item>

        <Form.Item label="Saldo de dias" name="saldo_dias">
          <p style={{ margin: "auto" }}>{Saldo ? Saldo : 0}</p>
        </Form.Item> */}

        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{NombreJefe ? NombreJefe : ""}</p>
        </Form.Item>

        <Form.Item label="Dias a solicitar" name="days">
          <Select
            defaultValue={1}
            name="days"
            onChange={onChangeCountDays}
            disabled={disabledForm}
          >
            {renderOptionsBalance(15)}
          </Select>
        </Form.Item>

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
            <DatePicker
              name="date"
              style={{ width: "263px" }}
              format="DD-MM-YYYY"
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
              onChange={onChangeDate}
              disabled={disabledForm}
              locale={esES}
            />
          </Space>
        </Form.Item>

        <Form.Item label="Hasta" name="hasta">
          <p style={{ margin: "auto" }}>{inputs.dateString[1] ? inputs.dateString[1] : ""}</p>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
            disabled={disabledForm}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            Solicitar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormLegalVacationsDays;
