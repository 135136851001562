import React, { useContext, useEffect, useState } from "react";
import FilesoutputContext from "../../context/filesoutput/filesoutputContext";
import { Form, Input, Button, Select, Spin, Space, DatePicker } from "antd";
import esES from "antd/lib/locale-provider/es_ES";
import moment from "moment";
const filesoutput = () => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 6 },
  };
  
  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const fspContext = useContext(FilesoutputContext);
  const {
    loading,
    getFilesOutput,
    files
  } = fspContext;
  const [url, setUrl] = useState("");
  const [date, setDate] = useState("");
  const [dateString, setDateString] = useState("");

  useEffect(() => {
      getFilesOutput();
  }, []);

  const { RangePicker } = DatePicker;

  const [inputs, setInputs] = useState({
    date: "",
    dateString: "",
    days: 1,
    comment: "",
  });

  const renderOptions = () => {
    return files.map((files) => (
      <Option value={files.url} key={files.name}>
        {files.name}
      </Option>
    ));
  };

  const onChangeOption = (value) => {
    setUrl(value);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
    setDate(date);
    setDateString({dateString})
  };

  const getUrl = () => {

    if(date){
      return `${url}/${moment(date[0], "DD-MM-YYYY").format("YYYY-MM-DD")}/${moment(date[1], "DD-MM-YYYY").format("YYYY-MM-DD")}`
    }
  }
  
  let disabledForm = false;

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form.Item label="A continuación puede descargar archivos en formatos especificos de cada sistema (Payroll, GeoVictoria,etc)"></Form.Item>
      <Form {...layout} form={form} name="basic">
        <Form.Item label="Archivo" name="archivo">
          <Select
            name="report"
            placeholder={"Seleccionar"}
            onChange={onChangeOption}
          >
            {renderOptions()}
          </Select>
        </Form.Item>

        <Form.Item label="Seleccionar días" name="date">
          <Space direction="vertical">
              <RangePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
              />
          </Space>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            href={getUrl()}
            disabled={url.length === 0 || date === null }
          >
            Generar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default filesoutput;
