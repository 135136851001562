import { GET_DEALERS, GET_SUCURSALES, ERROR_GET_DATA, GET_EMPLOYEES } from "../../types";

export default (state, action) => {
  switch (action.type) {
    case GET_DEALERS:
      return {
        ...state,
        dealers: action.payload.dealers,
        loading: false,
        error: false,
      };

    case GET_SUCURSALES:
      return {
        ...state,
        sucursales: action.payload.sucursales,
        loading: false,
        error: false,
      };

    case GET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
        loading: false,
        error: false,
      };

    case ERROR_GET_DATA:
      return {
        ...state,
        dealers: [],
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};
