import React from "react";
import { withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import { SolutionOutlined, CalendarOutlined } from "@ant-design/icons";

const SiderMenu = (props) => {
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const { collapsed } = props;

  const user = JSON.parse(localStorage.getItem("user"));
  
  return (
    <Sider width={230} trigger={null} collapsible collapsed={collapsed} className="site-layout-background">
      {user.role !== 4 ? (
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <SubMenu key="sub1" icon={<SolutionOutlined />} title="My Days">
          <Menu.Item
            key="1"
            onClick={() => props.history.push(`/app/dias-adicionales`)}
          >
            Crear solicitud
          </Menu.Item>
          <Menu.Item
            key="2"
            onClick={() => props.history.push(`/app/mis-solicitudes`)}
          >
            Mis solicitudes
          </Menu.Item>
          {user.role === 3 && (
            <Menu.Item
              key="3"
              onClick={() =>
                props.history.push(`/app/solicitudes-mis-empleados`)
              }
            >
              Sol. mis empleados
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key="sub2"
          icon={<SolutionOutlined />}
          title="Días administrativos"
        >
          <Menu.Item
            key="4"
            onClick={() => props.history.push(`/app/dias-administrativos`)}
          >
            Crear solicitud
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={() =>
              props.history.push(`/app/mis-solicitudes-administrativas`)
            }
          >
            Mis solicitudes
          </Menu.Item>
          {user.role === 3 && (
            <Menu.Item
              key="6"
              onClick={() =>
                props.history.push(
                  `/app/solicitudes-mis-empleados-administrativos`
                )
              }
            >
              Sol. mis empleados
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key="sub3"
          icon={<SolutionOutlined />}
          title="Registro de Asistencia"
        >
          <Menu.Item
            key="7"
            onClick={() => props.history.push(`/app/dias-asistencia`)}
          >
            Crear solicitud
          </Menu.Item>
          <Menu.Item
            key="8"
            onClick={() =>
              props.history.push(`/app/mis-solicitudes-asistencia`)
            }
          >
            Mis solicitudes
          </Menu.Item>
          {user.role === 3 && (
            <Menu.Item
              key="9"
              onClick={() =>
                props.history.push(
                  `/app/solicitudes-mis-empleados-asistencia`
                )
              }
            >
              Sol. mis empleados
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key="sub4"
          icon={<SolutionOutlined />}
          title="Días de Permiso"
        >
          <Menu.Item
            key="10"
            onClick={() => props.history.push(`/app/dias-permiso`)}
          >
            Crear solicitud
          </Menu.Item>
          <Menu.Item
            key="11"
            onClick={() =>
              props.history.push(`/app/mis-solicitudes-permiso`)
            }
          >
            Mis solicitudes
          </Menu.Item>
          {user.role === 3 && (
            <Menu.Item
              key="12"
              onClick={() =>
                props.history.push(
                  `/app/solicitudes-mis-empleados-permisos`
                )
              }
            >
              Sol. mis empleados
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key="sub5"
          icon={<SolutionOutlined />}
          title="Vacaciones atrasadas"
        >
          <Menu.Item
            key="13"
            onClick={() => props.history.push(`/app/vacaciones-atrasadas`)}
          >
            Crear solicitud
          </Menu.Item>
          <Menu.Item
            key="14"
            onClick={() =>
              props.history.push(`/app/mis-vacaciones-atrasadas`)
            }
          >
            Mis solicitudes
          </Menu.Item>
          {user.role === 3 && (
            <Menu.Item
              key="15"
              onClick={() =>
                props.history.push(
                  `/app/solicitudes-mis-empleados-vacaciones-atrasadas`
                )
              }
            >
              Sol. mis empleados
            </Menu.Item>
          )}
          {user.role === 5 && (
            <Menu.Item
              key="16"
              onClick={() =>
                props.history.push(
                  `/app/vacaciones-atrasadas-solicitudes`
                )
              }
            >
              Revision General
            </Menu.Item>)}
        </SubMenu>
      </Menu>
      ) : null}
      {user.role === 4 ? (
        <Menu
        mode="inline"
        defaultSelectedKeys={["9"]}
        defaultOpenKeys={["sub5"]}
        style={{ height: "100%", borderRight: 0 }}>
          
          <SubMenu key="sub6" icon={<SolutionOutlined />} title="My Days">
            <Menu.Item
              key="17"
              onClick={() => props.history.push(`/app/dias-adicionales-rrhh`)}
            >
              Crear Solicitud
            </Menu.Item>

            <Menu.Item
              key="18"
              onClick={() =>
                props.history.push(`/app/dias-adicionales-solicitudes`)
              }
            >
              Solicitudes
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="sub7"
            icon={<SolutionOutlined />}
            title="Días administrativos"
          >
            <Menu.Item
              key="19"
              onClick={() =>
                props.history.push(`/app/dias-administrativos-rrhh`)
              }
            >
              Crear Solicitud
            </Menu.Item>

            <Menu.Item
              key="20"
              onClick={() =>
                props.history.push(`/app/dias-administrativos-solicitudes`)
              }
            >
              Solicitudes
            </Menu.Item>
          </SubMenu>
          <SubMenu
          key="sub8"
          icon={<SolutionOutlined />}
          title="Registro de Asistencia"
        >
          <Menu.Item
            key="21"
            onClick={() => props.history.push(`/app/dias-asistencia-solicitudes`)}
          >
            Crear solicitud
          </Menu.Item>
          <Menu.Item
            key="22"
            onClick={() => props.history.push(`/app/asistencias-solicitudes`)}
            >
              Solicitudes
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub9" icon={<SolutionOutlined />} title="Vacaciones Atrasadas">
            <Menu.Item
              key="23"
              onClick={() => props.history.push(`/app/vacaciones-atrasadas-rrhh`)}
            >
              Crear Solicitud
            </Menu.Item>

            <Menu.Item
              key="24"
              onClick={() =>
                props.history.push(`/app/vacaciones-atrasadas-solicitudes`)
              }
            >
              Solicitudes
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub10" icon={<SolutionOutlined />} title="Días Permiso">
              <Menu.Item
                key="7"
                onClick={() => props.history.push(`/app/dias-permiso-solicitudes`)}
              >
                Crear solicitud
              </Menu.Item>
              <Menu.Item
                key="8"
                onClick={() => props.history.push(`/app/permisos-solicitudes`)}
                >
                  Solicitudes
              </Menu.Item>
          </SubMenu>
          <SubMenu key="sub11" icon={<SolutionOutlined />} title="RRHH">
            <Menu.Item
              key="25"
              onClick={() => props.history.push(`/app/archivos`)}
            >
              Archivos
            </Menu.Item>
            <Menu.Item
              key="26"
              onClick={() => props.history.push(`/app/reportes`)}
            >
              Reportes
            </Menu.Item>
            <Menu.Item
              key="27"
              onClick={() => props.history.push(`/app/archivos-de-salida`)}
            >
              Archivos de salida
            </Menu.Item>
          </SubMenu>
        </Menu>
      ) : null}
    </Sider>
  );
};

export default withRouter(SiderMenu);
