import React, { useEffect, useState } from "react";
import { Form, Input, Button, Tag } from "antd";

const FormApproveDay = (props) => {
  const { record, action, updateAttendanceRequest, setIsVisibleModal } = props;

  const [form] = Form.useForm();
  const [comment, setComment] = useState("");
  const { TextArea } = Input;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const onChangeComment = (e) => {
    setComment(e.target.value);
  };

  const onFinish = async () => {
    const data = {
      status: action === "approve" ? "A" : "R",
      comment: comment,
    };
    updateAttendanceRequest(record.Id, data);
    setIsVisibleModal(false);
  };

  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item label="Acción" name="action">
        <Tag color={action === "approve" ? "blue" : "red"}>
          {action === "approve" ? "Aprobar" : "Rechazar"}
        </Tag>
      </Form.Item>
      <Form.Item label="Comentario" name="commentLabel">
        <TextArea name="comment" rows={4} onChange={onChangeComment} />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          danger={action !== "approve" && true}
        >
          {action === "approve" ? "Aprobar" : "Rechazar"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormApproveDay;
