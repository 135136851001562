export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const SUCCESS_REGISTRATION = "SUCCESS_REGISTRATION";
export const ERROR_REGISTRATION = "ERROR_REGISTRATION";
export const AUTHENTICATED_USER = "AUTHENTICATED_USER";
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const CLOSE_SESION = "CLOSE_SESION";
export const GET_DATA_EMPLOYEE_INFO = "GET_DATA_EMPLOYEE_INFO";

export const GET_DATA_BALANCE_DAYS = "GET_DATA_BALANCE_DAYS";
export const ADD_VACATION_REQUEST = "ADD_VACATION_REQUEST";
export const ERROR_ADD_VACATION_REQUEST = "ERROR_ADD_VACATION_REQUEST";
export const GET_DATA_VACATION_REQUESTS_BY_CODEMPLOYEE =
  "GET_DATA_VACATION_REQUESTS_BY_CODEMPLOYEE";
export const GET_DATA_PENDING_VACATION_REQUESTS_BY_CODEMPLOYEE =
  "GET_DATA_PENDING_VACATION_REQUESTS_BY_CODEMPLOYEE";
export const GET_DATA_VACATION_REQUESTS_BY_CODBOSS =
  "GET_DATA_VACATION_REQUESTS_BY_CODBOSS";
export const UPDATE_VACATION_REQUEST = "UPDATE_VACATION_REQUEST";
export const RESET_MESSAGE = "RESET_MESSAGE";

export const SET_LOADING = "SET_LOADING";

export const GET_SALE_BY_ID_SUCCESS = "GET_SALE_BY_ID_SUCCESS";
export const ADD_FILTER_SALE = "ADD_FILTER_SALE";
export const REMOVE_SPECIFIC_FILTER = "REMOVE_SPECIFIC_FILTER";
export const CHANGE_PAGE = "CHANGE_PAGE";
export const UPDATE_SALE = "UPDATE_SALE";
export const RESET_DATES = "RESET_DATES";

export const GET_DEALERS = "GET_DEALERS";
export const GET_SUCURSALES = "GET_SUCURSALES";
export const SET_QUERY_FILTERS = "SET_QUERY_FILTERS";
export const SET_NAME_DEALER = "SET_NAME_DEALER";
export const SET_NAME_SUCURSAL = "SET_NAME_SUCURSAL";

export const SET_URL_FILTERS = "SET_URL_FILTERS";
export const ERROR_GET_DATA = "ERROR_GET_DATA";

export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR";
export const RESET_FILE_SUCCESS = "RESET_FILE_SUCCESS";
export const RESET_FILE_ERROR = "RESET_FILE_ERROR";

export const GET_DATA = "GET_DATA";

export const GET_TYPES_ADMINISTRATIVE_DAYS_SUCCESS = "[Administrative Days] GET_TYPES_ADMINISTRATIVE_DAYS_SUCCESS";
export const GET_DATA_ADMIN_REQUESTS_BY_CODEMPLOYEE = "[Administrative Days] GET_DATA_ADMIN_REQUESTS_BY_CODEMPLOYEE";
export const GET_DATA_ADMIN_REQUESTS_MODIFY_BY_CODEMPLOYEE = "[Administrative Days] GET_DATA_ADMIN_REQUESTS_MODIFY_BY_CODEMPLOYEE";
export const ADD_ADMINISTRATIVE_DAYS_REQUEST = "[Administrative Days] ADD_ADMINISTRATIVE_DAYS_REQUEST";
export const GET_DATA_ADMIN_REQUESTS_BY_CODBOSS = "[Administrative Days] GET_DATA_ADMIN_REQUESTS_BY_CODBOSS";
export const GET_DATA_ADMIN_REQUESTS_MODIFY_BY_CODBOSS = "[Administrative Days] GET_DATA_ADMIN_REQUESTS_MODIFY_BY_CODBOSS";
export const GET_DATA_PENDING_ADMIN_REQUESTS_BY_CODEMPLOYEE = "[Administrative Days] GET_DATA_PENDING_ADMIN_REQUESTS_BY_CODEMPLOYEE";
export const UPD_ADMINISTRATIVE_DAYS_REQUEST = "[Administrative Days] UPD_ADMINISTRATIVE_DAYS_REQUEST";
export const GET_ADMINISTRATIVE_DAYS_ADDITIONAL_REQUEST = "[Administrative Days] GET_ADMINISTRATIVE_DAYS_ADDITIONAL_REQUEST";
export const GET_ADMINISTRATIVE_DAYS_HOLIDAYS = "[Administrative Days] GET_ADMINISTRATIVE_DAYS_HOLIDAYS";
export const GET_ADMINISTRATIVE_DAYS_CONDITIONS = "[Administrative Days] GET_ADMINISTRATIVE_DAYS_CONDITIONS";
export const GET_ADMINISTRATIVE_DAYS_HISTORY = "[Administrative Days] GET_ADMINISTRATIVE_DAYS_HISTORY";

export const GET_FILES_REQUEST = "[RRHH] GET_FILES_REQUEST";
export const GET_FILES_REQUEST_DETAILS = "[RRHH] GET_FILES_REQUEST_DETAILS";
export const GET_REQUEST_ADMINISTRATIVE = "[RRHH] GET_REQUEST_ADMINISTRATIVE";
export const ADD_ADMINISTRATIVE_DAYS_REQUEST_RRHH = "[RRHH] ADD_ADMINISTRATIVE_DAYS_REQUEST";
export const DELETE_ADMINISTRATIVE_DAYS_REQUEST = "[RRHH] DELETE_ADMINISTRATIVE_DAYS_REQUEST";
export const UPD_ADMINISTRATIVE_DAYS_REQUEST_RRHH = "[RRHH] UPD_ADMINISTRATIVE_DAYS_REQUEST";
export const GET_EMPLOYEES = '[COMMON] GET_EMPLOYEES'
export const GET_FILES = "[RRHH] GET_FILES";
export const GET_FILE_DATE = "[RRHH] GET_FILE_DATE";


export const GET_REQUEST_VACATION = "[RRHH] GET_REQUEST_VACATION";
export const DELETE_VACATION_DAYS_REQUEST = "[RRHH] DELETE_VACATION_DAYS_REQUEST";
export const UPDATE_VACATION_REQUEST_RRHH = "[RRHH] UPDATE_VACATION_REQUEST_RRHH";

export const GET_REPORTS = "[RRHH] GET_REPORTS";
export const GET_REPORTS_YEARS = "[RRHH] GET_REPORTS_YEARS";

export const ADD_ATTENDANCE_REQUEST = "ADD_ATTENDANCE_REQUEST";
export const GET_DATA_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE = "GET_DATA_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE";
export const GET_DATA_ATTENDANCE_REQUESTS_BY_CODBOSS = "GET_DATA_ATTENDANCE_REQUESTS_BY_CODBOSS";
export const GET_DATA_ATTENDANCE_REQUESTS_BY_RRHH = "GET_DATA_ATTENDANCE_REQUESTS_BY_RRHH";
export const UPDATE_ATTENDANCE_REQUEST = "UPDATE_ATTENDANCE_REQUEST";

export const GET_DATA_PENDING_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE = "GET_DATA_PENDING_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE";

export const GET_REQUEST_ATTENDANCE = "[RRHH] GET_REQUEST_ATTENDANCE";
export const UPDATE_ATTENDANCE_REQUEST_RRHH = "[RRHH] UPDATE_ATTENDANCE_REQUEST_RRHH";
export const DELETE_ATTENDANCE_DAYS_REQUEST = "[RRHH] DELETE_ATTENDANCE_DAYS_REQUEST";

export const GET_TYPES_PERMIT_DAYS_SUCCESS = "GET_TYPES_PERMIT_DAYS_SUCCESS";
export const GET_ALL_TYPES_PERMIT_DAYS_SUCCESS = "GET_ALL_TYPES_PERMIT_DAYS_SUCCESS";
export const GET_PERMIT_DAYS_REQUEST = "GET_PERMIT_DAYS_REQUEST";
export const GET_DATA_PERMIT_REQUESTS_BY_CODEMPLOYEE = "GET_DATA_PERMIT_REQUESTS_BY_CODEMPLOYEE";
export const GET_DATA_PERMIT_REQUESTS_MODIFY_BY_CODEMPLOYEE = "GET_DATA_PERMIT_REQUESTS_MODIFY_BY_CODEMPLOYEE";
export const ADD_PERMIT_DAYS_REQUEST = "ADD_PERMIT_DAYS_REQUEST";
export const GET_DATA_PERMIT_REQUESTS_BY_CODBOSS = "GET_DATA_PERMIT_REQUESTS_BY_CODBOSS";
export const GET_DATA_PERMIT_REQUESTS_MODIFY_BY_CODBOSS = "GET_DATA_PERMIT_REQUESTS_MODIFY_BY_CODBOSS";
export const GET_DATA_PENDING_PERMIT_REQUESTS_BY_CODEMPLOYEE = "GET_DATA_PENDING_PERMIT_REQUESTS_BY_CODEMPLOYEE";
export const UPD_PERMIT_DAYS_REQUEST = "UPD_PERMIT_DAYS_REQUEST";
export const GET_PERMIT_DAYS_ADDITIONAL_REQUEST = "GET_PERMIT_DAYS_ADDITIONAL_REQUEST";
export const DELETE_PERMIT_DAYS_REQUEST = "DELETE_PERMIT_DAYS_REQUEST";
export const UPDATE_PERMIT_REQUEST_RRHH = "UPDATE_PERMIT_REQUEST_RRHH";
export const GET_PERMIT_DAYS_IMG = "GET_PERMIT_DAYS_IMG";

export const ADD_LEGALVACATION_REQUEST = "ADD_LEGALVACATION_REQUEST";
export const GET_DATA_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE = "GET_DATA_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE";
export const GET_DATA_LEGALVACATION_REQUESTS_BY_CODBOSS = "GET_DATA_LEGALVACATION_REQUESTS_BY_CODBOSS";
export const GET_DATA_LEGALVACATION_REQUESTS_BY_RRHH = "GET_DATA_LEGALVACATION_REQUESTS_BY_RRHH";
export const UPDATE_LEGALVACATION_REQUEST = "UPDATE_LEGALVACATION_REQUEST";
export const GET_DATA_PENDING_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE = "GET_DATA_PENDING_LEGALVACATION_REQUESTS_BY_CODEMPLOYEE";
export const GET_REQUEST_LEGALVACATION = "[RRHH] GET_REQUEST_LEGALVACATION";
export const UPDATE_LEGALVACATION_REQUEST_RRHH = "[RRHH] UPDATE_LEGALVACATION_REQUEST_RRHH";
export const DELETE_LEGALVACATION_DAYS_REQUEST = "[RRHH] DELETE_LEGALVACATION_DAYS_REQUEST";
export const GET_DATA_LEGALVACATION_MAIN_COUNT = "GET_DATA_LEGALVACATION_MAIN_COUNT";
