export function years(back) {
  const now = new Date().getUTCFullYear();
  const result = Array(now - (now - 20))
    .fill("")
    .map((v, idx) => now - idx);
  return result;
}

export function months() {
  const months = [
    {
      id: 1,
      label: "Enero",
    },
    { id: 2, label: "Febrero" },
    { id: 3, label: "Marzo" },
    { id: 4, label: "Abril" },
    { id: 5, label: "Mayo" },
    { id: 6, label: "Junio" },
    { id: 7, label: "Julio" },
    { id: 8, label: "Agosto" },
    { id: 9, label: "Septiembre" },
    { id: 10, label: "Octubre" },
    { id: 11, label: "Noviembre" },
    { id: 12, label: "Diciembre" },
  ];

  return months;
}

/**
 * @author Hector Martinez
 * @description count days without considering weekends
 * @param {moment} startDay start day
 * @param {moment} endDay end day
 * @returns {number} count days
 */
export const countDaysNotWeekend = (startDay, endDay) => {
  let day = startDay;
  let count = 0;

  while (day <= endDay) {
    if (day.isoWeekday() !== 6 && day.isoWeekday() !== 7) {
      count++;
    }
    day = day.add(1, "days");
  }
  return count;
};
