import React from "react";
import FilesState from "../../../context/files/filesState";
import ManualUploadFile from "./ManualUploadFile";

const FormManualUpload = () => {
  return (
    <FilesState>
      <ManualUploadFile></ManualUploadFile>
    </FilesState>
  );
};

export default FormManualUpload;