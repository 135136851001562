import React, { useState } from "react";
import { Layout as LayoutAntd, Breadcrumb } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, BarsOutlined } from "@ant-design/icons";
import Header from "./Header";
import SiderMenu from "./SiderMenu";
import Footer from "./Footer";
import NavBottom from "../core/NavBottom";
import useMediaQuery from "../../hooks/useMediaQuery";

const Layout = ({ children }) => {
  const { Content } = LayoutAntd;
  let url = window.location.pathname;
  let result = url.split("/");
  const isMobile = useMediaQuery("max-width", 790);
  const [collapsed, setCollapsed] = useState(false);

  const setTrueCollapsed = () =>{
    setCollapsed(true)
  }

  const setFalseCollapsed = () =>{
    setCollapsed(false)
  }

  return (
    <>
      <LayoutAntd className="layout">
        <Header >
        </Header>
        <LayoutAntd>
          <SiderMenu  collapsed={collapsed} />
          <LayoutAntd style={{ padding: "0 24px 24px" }}>            
            <Breadcrumb style={{ margin: "16px 0" }}>
              {collapsed 
                ? <MenuUnfoldOutlined onClick={setFalseCollapsed} style={{paddingRight : '20px', color: 'black', cursor:'pointer'}} />
                : <MenuFoldOutlined onClick={setTrueCollapsed} style={{paddingRight : '20px', color: 'black', cursor:'pointer'}} />   
              } 
              <Breadcrumb.Item>{result[1].toUpperCase()}</Breadcrumb.Item>
              <Breadcrumb.Item>{result[2].toUpperCase()}</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 444,
                background: "#fff",
              }}
            >
              {children}
            </Content>
            {!isMobile && <Footer />}
          </LayoutAntd>
        </LayoutAntd>
      </LayoutAntd>
      {isMobile && <NavBottom />}
    </>
  );
};

export default Layout;
