import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
  AutoComplete,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import AlertContext from "../../context/alert/alertContext";
import AdditionalDaysContext from "../../context/additionaldays/additionaldaysContext";
import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";
import LegalVacationDaysContext from "../../context/legalvacationdays/legalvacationdaysContext"
import CommonContext from "../../context/commondata/commondataContext";
import { countDaysNotWeekend } from "../../utils";

const formRRHHLegalVacationsDays = () => {

    const additionalDaysContext = useContext(AdditionalDaysContext);
    const legalvacationdaysContext = useContext(LegalVacationDaysContext);
  const alertContext = useContext(AlertContext);
  const commonContext = useContext(CommonContext);
  const { alert, showAlert } = alertContext;

  const {
    addLegalVacationRequestRRHH,
    message,
    getBalanceDays,
    balanceDays,
    loading: loadingAdditional,
  } = legalvacationdaysContext;

  const { getEmployees, employees, loading } = commonContext;

  const initInput = {
    date: "",
    dateString: "",
    days: 1,
    comment: "",
  };

  const [inputs, setInputs] = useState(initInput);

  const [disabledForm, setDisabledForm] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [user, setUser] = useState();
  const [userRRHH, setUserRRHH] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [messageError, setMessageError] = useState("");

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    if (!loading) {
      onSelect(userRRHH.username);
    }
  }, [employees]);

//   useEffect(() => {
//     validateRequestPending();
//   }, [vacationRequestPending, loading, loadingAdditional]);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 6 },
  };

  const { Saldo = 0, NombreJefe = "", DiasOtorgados = "" } = balanceDays ?? {};

  const validateSaldo = () => {
    if (Saldo === 0) {
      setMessageError(
        "Actualmente no dispones de saldo para tomar dias administrativos"
      );
      setDisabledForm(true);
    } else {
      setDisabledForm(false);
    }
  };

  const onSelect = (value) => {
    const userSplit = value.split("-");
    const user = `${userSplit[0]}-${userSplit[1]}`.trim();
    setMessageError("");
    setUser({ username: user });
    getBalanceDays(user);
    // getPendingVacationRequestsByCodEmployee(user);
    setInputs({
      ...initInput,
    });
    setDisabledForm(false);
    setFirstLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    const { dateString, days } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays = countDaysNotWeekend(startDay, endDay);

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    if((days > 1 && 
        (startDay.year() !== moment().year() || endDay.year() !== moment().year())) 
        || 
      (days <= 1 && 
        moment(dateString, "DD-MM-YYYY").year() !== moment().year()))
    {
      showAlert(`La fecha solicitada debe ser dentro del mismo año (${moment().year()})`, "warning");
      return;
    }

    if (diffDays < days && days !== 1) {
      showAlert("El rango indicado es menor a lo solicitado.", "warning");
      return;
    }

    if (diffDays > days && days !== 1) {
      showAlert("El rango indicado es mayor a lo solicitado.", "warning");
      return;
    }

    
    await addLegalVacationRequestRRHH({ ...inputs, balanceDays });
    getBalanceDays(user.username);
    // getPendingVacationRequestsByCodEmployee(user.username);
    setInputs({
      ...initInput,
    });
    form.resetFields();
  };

  const validateRequestPending = () => {
    if (vacationRequestPending.length > 0) {
      setMessageError("Ya se encuentra una solicitud en proceso");
      setDisabledForm(true);
    } else {
      if (!firstLoading) {
        setDisabledForm(false);
        setMessageError("");
        // validateSaldo();
      }
    }
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  const onChangeCountDays = (value) => {
    setInputs({ ...inputs, days: value });
  };

  const renderOptionsBalance = (balance) => {
    let array = [];
    for (var i = 1; i <= balance; i++) {
      array.push(i);
    }

    return array.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    return (
        (current && current > moment().subtract("1", "d").endOf("day")) ||
        moment(current).day() === 0 ||
        moment(current).day() === 6
      );
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  return (
    <Spin tip="Cargando..." spinning={loading && loadingAdditional}>
      <Form.Item {...layout} label="Empleado" name="empleado">
        <AutoComplete
          placeholder="Seleccionar"
          allowClear={true}
          options={employees.map((x) => {
            return {
              rut: x.Rut,
              value: `${x.Rut} - ${x.Nombres} ${x.Apaterno} ${x.Amaterno}`,
            };
          })}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={onSelect}
          defaultValue={`${userRRHH.rut} - ${userRRHH.name} ${userRRHH.lastName}`}
        ></AutoComplete>
      </Form.Item>
      <Form {...layout} form={form} name="basic" onFinish={onFinish}>

        {/* <Form.Item label="Dias otorgados" name="dias_otorgados">
          <p style={{ margin: "auto" }}>
            {DiasOtorgados ? DiasOtorgados : "0"}
          </p>
        </Form.Item>

        <Form.Item label="Saldo de dias" name="saldo_dias">
          <p style={{ margin: "auto" }}>{Saldo ? Saldo : 0}</p>
        </Form.Item> */}

        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{NombreJefe ? NombreJefe : ""}</p>
        </Form.Item>

        <Form.Item label="Dias a solicitar" name="days">
          <Select
            defaultValue={1}
            name="days"
            onChange={onChangeCountDays}
            disabled={disabledForm}
          >
            {renderOptionsBalance(15)}
          </Select>
        </Form.Item>

        {messageError.length > 0 ? (
          <Alert
            message={messageError}
            type="info"
            showIcon
            style={{
              marginLeft: "10%",
              minWidth: "40%",
              maxWidth: "45%",
              marginBottom: "20px",
            }}
          />
        ) : null}

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
            {inputs.days === 1 ? (
              <DatePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
              />
            ) : (
              <RangePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
              />
            )}
          </Space>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
            disabled={disabledForm}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            Solicitar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
    );
};

export default formRRHHLegalVacationsDays;
