import React, { useContext, useEffect } from "react";
import { Tag, Table, Space } from "antd";
import moment from "moment";
import FilesContext from "../../../context/files/filesContext";
import { useParams } from "react-router-dom";

const STATUS_VALID_DATA = {
  ERROR: "Error",
  VALIDO: "Válido",
};

const DetailsUploadFile = () => {
  let { id } = useParams();

  const filesContext = useContext(FilesContext);

  const { loading, details, getFilesDetails } = filesContext;

  useEffect(() => {
    console.log("useEffect getFilesDetails", details);
    getFilesDetails(id);
  }, []);

  const marginP = { margin: 0 };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "0",
      width: "10%",
    },
    {
      title: "Rut",
      dataIndex: "Rut",
      key: "1",
    },
    {
      title: "FechaDesde",
      dataIndex: "FechaDesde",
      key: "3",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "FechaHasta",
      dataIndex: "FechaHasta",
      key: "4",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },

    {
      title: "Dias",
      dataIndex: "DiasSolicitados",
      key: "5",
    },
    {
      title: "Comentario",
      dataIndex: "Comentario",
      key: "6",
    },
    {
      title: "Validación",
      dataIndex: "Mensaje",
      key: "7",
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "4",
      render: (status) => (
        <Tag color={status.trim() === "V" ? "green" : "red"}>
          {status.trim() === "V"
            ? STATUS_VALID_DATA.VALIDO
            : STATUS_VALID_DATA.ERROR}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <Table
        key="3"
        dataSource={details}
        columns={columns}
        loading={loading}
        scroll={{ x: "auto" }}
      />
    </>
  );
};

export default DetailsUploadFile;
