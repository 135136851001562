import React, { useReducer } from "react";
import FilesContext from './filesContext';
import filesReducer from "./filesReducer";
import * as types from "../../types";
import axiosClient from "../../config/axios";

const FilesState = (props) => {
    const initialState = {
      files: [],
      loading: true,
      message: null,
    };
  
    const [state, dispatch] = useReducer(filesReducer, initialState);

    const getFiles = async () => {
        try {
          dispatch({
            type: types.SET_LOADING
          });

          const response = await axiosClient.get(
            `/api/file`
          );
    
          dispatch({
            type: types.GET_FILES_REQUEST,
            payload: response.data.files,
          });
        } catch (error) {
          dispatch({
            type: types.ERROR_GET_DATA,
            payload: error,
          });
        }
      };
    
    const getFilesDetails = async (id) => {
        try {
          dispatch({
            type: types.SET_LOADING
          });

          const response = await axiosClient.get(
            `/api/file/${id}`
          );
    
          dispatch({
            type: types.GET_FILES_REQUEST_DETAILS,
            payload: response.data.details,
          });
        } catch (error) {
          dispatch({
            type: types.ERROR_GET_DATA,
            payload: error,
          });
        }
      };

    return (
        <FilesContext.Provider
          value={{
            files: state.files,
            loading: state.loading,
            message: state.message,
            details: state.details,
            getFiles,
            getFilesDetails
          }}
        >
          {props.children}
        </FilesContext.Provider>
      );
    };
    
    export default FilesState;
    