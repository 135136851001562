import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import AuthLayout from "../auth/AuthLayout";
import AppLayout from "../app/AppLayout";

/* const NotFound = () => <h1>Not Found</h1>; */

const Layouts = () => {
  return (
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/app" component={AppLayout} />
      <Redirect from="/" to="/auth/login" />
    </Switch>
  );
};

export default Layouts;
