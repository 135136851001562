import {
  SUCCESS_REGISTRATION,
  ERROR_REGISTRATION,
  AUTHENTICATED_USER,
  SUCCESS_LOGIN,
  ERROR_LOGIN,
  CLOSE_SESION,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case SUCCESS_REGISTRATION:
    case SUCCESS_LOGIN:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      return {
        ...state,
        authenticated: true,
        message: null,
        loading: false,
        user: action.payload.user,
        token: action.payload.token,
      };
    case AUTHENTICATED_USER:
      return {
        ...state,
        authenticated: true,
        loading: false,
        user: JSON.parse(localStorage.getItem("user")),
      };

    case CLOSE_SESION:

    case ERROR_LOGIN:
    case ERROR_REGISTRATION:
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      return {
        ...state,
        token: null,
        user: null,
        authenticated: null,
        message: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
