import React, { useEffect, useContext } from "react";
import { Alert } from "antd";
import FormLegalVacationDays from "./FormLegalVacationDays";
import LegalVacationDaysContext from "../../context/legalvacationdays/legalvacationdaysContext"
import AdditionalDaysContext from "../../context/additionaldays/additionaldaysContext";
import AlertContext from "../../context/alert/alertContext";

const LegalVacationDays = () => {
  const legalvacationdaysContext = useContext(LegalVacationDaysContext);
  const alertContext = useContext(AlertContext);
  const additionalDaysContext = useContext(AdditionalDaysContext);
  const { alert, showAlert } = alertContext;

  const {
    getTypes,
    additional,
    loading,
    message,
    types,
    balanceDays,
    getBalanceDays,
    legalvacationRequestPending,
    getLegalVacationRequestsByCodEmployee,
    addLegalVacationRequest
  } = legalvacationdaysContext;

  useEffect(() => {
    function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getBalanceDays(user.username);
      getLegalVacationRequestsByCodEmployee(user.username);
    }
    fetchData();
  }, []);

  return (
    <>     
    { legalvacationRequestPending.length === 0 ? (
        <FormLegalVacationDays
          balanceDays={balanceDays} 
          legalvacationRequestPending={legalvacationRequestPending} 
          addLegalVacationRequest={addLegalVacationRequest}
          loading={loading}
          message={message}
          alert={alert}
          showAlert={showAlert}
        />    
        ) : (
          <Alert
            message="Solicitud pendiente"
            description="Usted tiene una solicitud pendiente, favor contactar a supervisor."
            type="info"
            showIcon
            style={{ width: "50%" }}
          />
      )} 
    </>
  );
};

export default LegalVacationDays;
