import React, { useEffect, useContext } from "react";
import { Alert } from "antd";
import FormAdministrativeDays from "./FormAdministrativeDays/FormAdministrativeDays";
import AdditionalDaysContext from "../../context/additionaldays/additionaldaysContext";
import AlertContext from "../../context/alert/alertContext";
import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";

const AdministrativeDays = () => {
  const additionalDaysContext = useContext(AdditionalDaysContext);
  const administrativelDaysContext = useContext(AdministrativelDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    balanceDays,
    getBalanceDays,
  } = additionalDaysContext;

  const {
    getTypes,
    additional,
    loading,
    message,
    types,
    holidays,
    adminCondition,
    vacationHistory,
    adminRequestsPending,
    addAdministrativeDaysRequest,
    getAdditionalAdminDaysByCodEmployee,
    getPendingAdminRequestsByCodEmployee,
    getHolidays,
    getConditionByCodEmployee,
    getHistoryByCodEmployee,
  } = administrativelDaysContext;

  useEffect(() => {
    async function fetchGetTypes() {
      getTypes();
    }
    fetchGetTypes();
  }, []);

  useEffect(() => {
    async function fetchGetHolidays() {
      getHolidays();
    }
    fetchGetHolidays();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getBalanceDays(user.username);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchCondition() {
      const user = JSON.parse(localStorage.getItem("user"));
      getConditionByCodEmployee(user.username);
    }
    fetchCondition();
  }, []);

  useEffect(() => {
    async function fetchHistory() {
      const user = JSON.parse(localStorage.getItem("user"));
      getHistoryByCodEmployee(user.username);
    }
    fetchHistory();
  }, []);

  return (
    <>     
        <FormAdministrativeDays
          balanceDays={balanceDays}
          types={types}
          holidays={holidays}
          adminCondition={adminCondition}
          vacationHistory={vacationHistory}
          addAdministrativeDaysRequest={addAdministrativeDaysRequest}
          getAdditionalAdminDaysByCodEmployee={getAdditionalAdminDaysByCodEmployee}
          getPendingAdminRequestsByCodEmployee={getPendingAdminRequestsByCodEmployee}
          additional={additional}
          adminRequestsPending={adminRequestsPending}
          loading={loading}
          message={message}
          alert={alert}
          showAlert={showAlert}
        />     
    </>
  );
};

export default AdministrativeDays;
