import React, { useEffect, useContext, useState } from "react";
import { Tag, Table, Space } from "antd";
import moment from "moment";

import PermitionDaysContext from "../../context/permitiondays/permitiondaysContext";
import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";
import AlertContext from "../../context/alert/alertContext";
import FormViewPermitionDay from "./FormPermitionDay/FormViewPermitionDay";
import Modal from "../core/Modal";

const MyRequestsPermit = () => {
  const permitiondaysContext = useContext(PermitionDaysContext);
  const administrativelDaysContext = useContext(AdministrativelDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    types,
    message,
    vacationRequests,
    loading,
    getVacationRequestsByCodEmployee,
    updateVacationRequest,
    getAllTypes, 
  } = permitiondaysContext;

  
  //modal variables
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getVacationRequestsByCodEmployee(user.username);
    }
    fetchData();
  }, []);

  useEffect(() => {
    function fetchGetTypes() {
      getAllTypes();
    }
    fetchGetTypes();
  }, []);

  const showModalUpdateRequest = (record, action) => {
    setIsVisibleModal(true);
    setModalTitle("Revisar solicitud");
    setModalContent(
      <FormViewPermitionDay
        record={record}
        types={types}
        action={action}
        setIsVisibleModal={setIsVisibleModal}
        updateVacationRequest={updateVacationRequest}
        alert={alert}
        message={message}
        showAlert={showAlert}
      />
    );
  };

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "1",
    },
    {
      title: "Tipo",
      key: "Tipo",
      render: (text, record) => (
        <Space size="middle">
            <Tag color="blue">
              { types.find(data => record.Tipo === data.id)?.description }
            </Tag>
        </Space>
      ),
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "2",
      render: (status) => (
        <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
          {status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Fecha desde",
      dataIndex: "FechaDesde",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Fecha hasta",
      dataIndex: "FechaHasta",
      key: "7",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Dias",
      dataIndex: "DiasSolicitados",
      key: "8",
    },
    {
      title: "Mi comentario",
      dataIndex: "Comentario",
      key: "3",
    },
    {
      title: "Nombre jefe",
      dataIndex: "NombreJefe",
      key: "4",
    },
    {
      title: "Comentario jefe",
      dataIndex: "ComentarioJefe",
      key: "5",
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
            <a onClick={() => showModalUpdateRequest(record, "review")}>
              Revisar
            </a>
        </Space>
      ),
    },
  ];

  if (!vacationRequests) {
    return "loading...";
  }

  return <>
    <Table dataSource={vacationRequests} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
    <Modal
          title={modalTitle}
          isVisible={isVisibleModal}
          setIsVisible={setIsVisibleModal}
          width="1000px"
        >
          {modalContent}
        </Modal>
    </> ;
  };

export default MyRequestsPermit;
