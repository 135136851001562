import React, { useReducer } from "react";
import additionaldaysReducer from "./additionaldaysReducer";
import additionaldaysContext from "./additionaldaysContext";
import {
  GET_DATA_BALANCE_DAYS,
  ADD_VACATION_REQUEST,
  RESET_MESSAGE,
  GET_DATA_VACATION_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_PENDING_VACATION_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_VACATION_REQUESTS_BY_CODBOSS,
  ERROR_GET_DATA,
  UPDATE_VACATION_REQUEST,
  SET_LOADING,
  GET_REQUEST_VACATION,
  DELETE_VACATION_DAYS_REQUEST,
  UPDATE_VACATION_REQUEST_RRHH,
} from "../../types";
import axiosClient from "../../config/axios";
import moment from "moment";

const AdditionalDaysDataState = (props) => {
  const initialState = {
    balanceDays: [],
    loading: true,
    message: null,
    vacationRequests: [],
    vacationRequestBoss: [],
    vacationRequestPending: [],
    vacationRequestsRRHH: [],
  };

  const [state, dispatch] = useReducer(additionaldaysReducer, initialState);

  const user = JSON.parse(localStorage.getItem("user"));

  const getBalanceDays = async (rut) => {
    try {
      const response = await axiosClient.get(
        `/api/employee/${rut}/balancedays`
      );

      dispatch({
        type: GET_DATA_BALANCE_DAYS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const addVacationRequest = async (vacationRequest) => {
    dispatch({
      type: SET_LOADING,
    });

    try {
      const names = state.balanceDays.Nombres;

      const firstName = names.split(" ");

      const fullName = `${firstName} ${state.balanceDays.Apaterno}`;

      const data = {
        codigo: state.balanceDays.Codigo,
        rut: user.username,
        nombre: fullName,
        mail: user.email,
        fechaDesde:
          vacationRequest.days === 1
            ? moment(vacationRequest.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(vacationRequest.dateString[0], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        fechaHasta:
          vacationRequest.days === 1
            ? moment(vacationRequest.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(vacationRequest.dateString[1], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        diasHabilesPendientes: state.balanceDays.Saldo,
        comentario: vacationRequest.comment,
        codigoJefe: state.balanceDays.codigoJefe,
        nombreJefe: state.balanceDays.NombreJefe,
        mailJefe: state.balanceDays.CorreoJefe,
      };

      const response = await axiosClient.post(`/api/vacationrequest`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };

      dispatch({
        type: ADD_VACATION_REQUEST,
        payload: alert,
      });

      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
        window.location.reload();
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const addVacationRequestRRHH = async (vacationRequest) => {
    dispatch({
      type: SET_LOADING,
    });

    try {
      const {Codigo, Nombres, Apaterno, Mail, Rut, Saldo, codigoJefe, NombreJefe, CorreoJefe} = vacationRequest.balanceDays
      const fullName = `${Nombres} ${Apaterno}`;

      const data = {
        codigo: Codigo,
        rut: Rut,
        nombre: fullName,
        mail: Mail,
        fechaDesde:
          vacationRequest.days === 1
            ? moment(vacationRequest.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(vacationRequest.dateString[0], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        fechaHasta:
          vacationRequest.days === 1
            ? moment(vacationRequest.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(vacationRequest.dateString[1], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        diasHabilesPendientes: Saldo,
        comentario: vacationRequest.comment,
        codigoJefe: codigoJefe,
        nombreJefe: NombreJefe,
        mailJefe: CorreoJefe
      };

      const response = await axiosClient.post(`/api/rrhh/vacationrequest`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };

      dispatch({
        type: ADD_VACATION_REQUEST,
        payload: alert,
      });

      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getVacationRequestsByCodEmployee = async (codEmployee) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.get(
        `api/vacationrequest/${codEmployee}`
      );

      dispatch({
        type: GET_DATA_VACATION_REQUESTS_BY_CODEMPLOYEE,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getPendingVacationRequestsByCodEmployee = async (codEmployee) => {
    try {
      const response = await axiosClient.get(
        `api/vacationrequest/pending/${codEmployee}`
      );

      dispatch({
        type: GET_DATA_PENDING_VACATION_REQUESTS_BY_CODEMPLOYEE,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getVacationRequestsByCodBoss = async (codBoss) => {
    dispatch({
      type: SET_LOADING,
    });

    const codBossFormat = codBoss.split("-");

    try {
      const response = await axiosClient.get(
        `api/vacationrequest/boss/${codBossFormat[0]}`
      );

      dispatch({
        type: GET_DATA_VACATION_REQUESTS_BY_CODBOSS,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateVacationRequest = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.put(`api/vacationrequest/${id}`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPDATE_VACATION_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getVacationRequestsByRRHH = async (query = '') => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const url = query.length === 0 ? 'api/rrhh/vacationrequest' : `api/rrhh/vacationrequest?query=${query}`
      const response = await axiosClient.get(url);

      dispatch({
        type: GET_REQUEST_VACATION,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const deleteAdditionalRequest = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.delete(
        `/api/rrhh/vacationrequest/${id}`
      );

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: DELETE_VACATION_DAYS_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateVacationRequestRRHH = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const params = {
        diasHabilesPendientes: data.days,
        comentario: data.comment,
        fechaDesde:
          data.days === 1
            ? moment(data.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(data.dateString[0], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
        fechaHasta:
          data.days === 1
            ? moment(data.dateString, "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              )
            : moment(data.dateString[1], "DD-MM-YYYY").format(
                "YYYY-MM-DD[T]HH:mm:ss"
              ),
      };
      const response = await axiosClient.put(
        `api/rrhh/vacationrequest/${id}`,
        params
      );

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPDATE_VACATION_REQUEST_RRHH,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  return (
    <additionaldaysContext.Provider
      value={{
        balanceDays: state.balanceDays,
        loading: state.loading,
        message: state.message,
        vacationRequests: state.vacationRequests,
        vacationRequestBoss: state.vacationRequestBoss,
        vacationRequestPending: state.vacationRequestPending,
        vacationRequestsRRHH: state.vacationRequestsRRHH,
        getBalanceDays,
        addVacationRequest,
        addVacationRequestRRHH,
        getVacationRequestsByCodEmployee,
        getPendingVacationRequestsByCodEmployee,
        getVacationRequestsByCodBoss,
        getVacationRequestsByRRHH,
        updateVacationRequest,
        deleteAdditionalRequest,
        updateVacationRequestRRHH,
      }}
    >
      {props.children}
    </additionaldaysContext.Provider>
  );
};

export default AdditionalDaysDataState;
