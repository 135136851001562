import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
  AutoComplete,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import AdditionalDaysContext from "../../context/additionaldays/additionaldaysContext";
import AlertContext from "../../context/alert/alertContext";
import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";
import CommonContext from "../../context/commondata/commondataContext";
import { countDaysNotWeekend } from "../../utils";


const TYPE_NACIMIENTO_HIJO = 1;
const TYPE_PERMISO_MEDIA_DIA = 0.5;
const TYPE_PERMISO_DIA = 1;
const DIA_ADMINISTRATIVO = 5;
const MEDIO_DIA_ADMINISTRATIVO = 6;

const initInput = {
  date: "",
  dateString: "",
  days: "",
  comment: "",
  type: "",
};

const FormRRHHAdministrativeDays = (props) => {
  const additionalDaysContext = useContext(AdditionalDaysContext);
  const administrativelDaysContext = useContext(AdministrativelDaysContext);
  const alertContext = useContext(AlertContext);
  const commonContext = useContext(CommonContext);
  const { alert, showAlert } = alertContext;

  const { balanceDays, getBalanceDays } = additionalDaysContext;

  const {
    getTypes,
    additional,
    loading,
    message,
    types,
    adminRequestsPending,
    addAdministrativeDaysRequestRRHH,
    getAdditionalAdminDaysByCodEmployee,
    getPendingAdminRequestsByCodEmployee,
  } = administrativelDaysContext;

  const { getEmployees, employees } = commonContext;

  const [inputs, setInputs] = useState(initInput);

  const [disabledForm, setDisabledForm] = useState(true);
  const [disabledFormSaldo, setDisabledFormSaldo] = useState(true);  
  const [firstLoading, setFirstLoading] = useState(true);
  const [user, setUser] = useState();
  const [messageError, setMessageError] = useState("");
  const [userRRHH, setUserRRHH] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const initialValues = {
  };

  useEffect(() => {
    getTypes();
    getEmployees();
  }, []);

  useEffect(() => {
    if (!loading) {
      onSelect(userRRHH.username);
    }
  }, [employees]);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    if (!loading && firstLoading) {
      setInputs({
        ...initInput,
      });
     
    }
  }, [loading]);

  useEffect(() => {
    validateRequestPending();
  }, [adminRequestsPending, loading]);

  useEffect(() => {
    if(!loading  && !firstLoading){
      setInfoBalance();
    }
  }, [balanceDays, loading]);

  useEffect(() => {
    validateSaldo();
  }, [additional, loading]);

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 6 },
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const setInfoBalance = () =>{
    const { NombreJefe } = balanceDays;
    setInputs({
      ...inputs,
      NombreJefe
    })
  }

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  const onChangeTypeDays = (value) => {
    setInputs({
      ...inputs,
      days: types.find((day) => day.id === value).days,
      type: value,
    });

    
    if (value === DIA_ADMINISTRATIVO || value === MEDIO_DIA_ADMINISTRATIVO) {
      getAdditionalAdminDaysByCodEmployee(user.username);
      getPendingAdminRequestsByCodEmployee(user.username);
    } else {
      setDisabledForm(false);
    }
  };

  const validateRequestPending = () => {
    if (adminRequestsPending.length > 0 ) {
      setMessageError("Ya se encuentra una solicitud en proceso");
      setDisabledForm(true);
      setDisabledFormSaldo(true);
    } else {
      if(!firstLoading){
        setDisabledForm(false);
        setDisabledFormSaldo(false);
        setMessageError("");
        validateSaldo();
      }
     
    }
  };

  const validateSaldo = () => {
    if (
      inputs.type === DIA_ADMINISTRATIVO ||
      inputs.type === MEDIO_DIA_ADMINISTRATIVO
    ) {
      if (additional.Saldo < inputs.days) {
        setMessageError(
          "Actualmente no dispones de saldo para tomar dias administrativos"
        );
        setDisabledForm(true);
        setDisabledFormSaldo(false);
      } else {
        if (additional.payroll && !additional.payroll.enabled) {
          setMessageError(additional.payroll.message);
          setDisabledForm(true);
          setDisabledFormSaldo(false);
        } else {
          setMessageError("");
          setDisabledForm(false);
          setDisabledFormSaldo(false);
        }
      }
    }
  };

  const renderOptionsTypeDays = () => {
    return types.map((type) => (
      <Option value={type.id} key={type.id}>
        {type.description}
      </Option>
    ));
  };

  const disabledDate = (current) => {
    return moment(current).day() === 0 || moment(current).day() === 6;
  };


  /**
   * @author Hector Martinez
   * @description submit form
   */
  const onFinish = async () => {
    const { dateString, days, type } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays =
      typeof dateString === "string"
        ? days === TYPE_PERMISO_MEDIA_DIA
          ? TYPE_PERMISO_MEDIA_DIA
          : TYPE_PERMISO_DIA
        : countDaysNotWeekend(startDay, endDay);


    if (!type) {
      showAlert("Motivo es requerido", "warning");
      return;
    }

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    if((days > 1 && 
      (startDay.year() !== moment().year() || endDay.year() !== moment().year())) 
      || 
    (days <= 1 && 
      moment(dateString, "DD-MM-YYYY").year() !== moment().year()))
    {
      showAlert(`La fecha solicitada debe ser dentro del mismo año (${moment().year()})`, "warning");
      return;
    }

    if (diffDays < days) {
      showAlert("El rango indicado es menor a lo solicitado.", "warning");
      return;
    }

    if (diffDays > days) {
      showAlert("El rango indicado es mayor a lo solicitado.", "warning");
      return;
    }

    await addAdministrativeDaysRequestRRHH({ ...inputs, balanceDays });

    setInputs({
      ...inputs,
      type: '',
    });
    form.resetFields();
  };

  const onSelect = (value) => {
    const userSplit = value.split("-");
    const user = `${userSplit[0]}-${userSplit[1]}`.trim();
    setUser({ username: user });
    getBalanceDays(user);
    getPendingAdminRequestsByCodEmployee(user);
    setInputs({
      ...initInput
    });    
    setDisabledForm(false);
    setDisabledFormSaldo(false);
    setFirstLoading(false);
    form.resetFields();
  };

  return (
    
    <Spin tip="Cargando..." spinning={loading}>
      <Form.Item {...layout} label="Empleado" name="empleado">
          <AutoComplete
            placeholder="Seleccionar"
            allowClear={true}
            options={employees.map((x) => {
              return { rut: x.Rut, value: `${x.Rut} - ${x.Nombres} ${x.Apaterno} ${x.Amaterno}` };
            })}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onSelect={onSelect}
            defaultValue={`${userRRHH.rut} - ${userRRHH.name} ${userRRHH.lastName}`}
          ></AutoComplete>
        </Form.Item>

      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
      >        
        <Form.Item  label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{inputs.NombreJefe ? inputs.NombreJefe : ""}</p>
        </Form.Item>

         
        <Form.Item  label="Motivo" name="days">
          <Select disabled={disabledFormSaldo} name="days" onChange={onChangeTypeDays}>
            {renderOptionsTypeDays()}
          </Select>
        </Form.Item>

        <Form.Item disabled={disabledForm} label="Dias" name="dias_otorgados">
          <p style={{ margin: "auto" }}>{inputs.days}</p>
        </Form.Item>

        {inputs.type === DIA_ADMINISTRATIVO ||
        inputs.type == MEDIO_DIA_ADMINISTRATIVO ? (
          <Form.Item label="Saldo" name="saldo">
            <p style={{ margin: "auto" }}>{additional.Saldo}</p>
          </Form.Item>
        ) : null}

        {messageError.length > 0 ? (
          <Alert
            message={messageError}
            type="info"
            showIcon
            style={{
              marginLeft: "10%",
              minWidth: "40%",
              maxWidth: "45%",
              marginBottom: "20px",
            }}
          />
        ) : null}

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
            {inputs.days === 1 || inputs.days === 0.5 ? (
              <DatePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
              />
            ) : (
              <RangePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
              />
            )}
          </Space>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
            disabled={disabledForm}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            Solicitar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormRRHHAdministrativeDays;
