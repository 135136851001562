import React, { useReducer } from "react";
import FileoutputContext from "./filesoutputContext";
import FileoutpuReducer from "./filesoutputReducer";
import * as types from "../../types";
import axiosClient from "../../config/axios";

const FilesoutputState = (props) => {
  const initialState = {
    files: [],
    loading: true,
    message: null,
  };

  const [state, dispatch] = useReducer(FileoutpuReducer, initialState);

  const getFilesOutput = async () => {
    try {
      dispatch({
        type: types.SET_LOADING,
      });

      const response = await axiosClient.get(`/api/filesoutput`);

      dispatch({
        type: types.GET_FILES,
        payload: response.data.files,
      });
    } catch (error) {
      dispatch({
        type: types.ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  // const getfile = async () => {
  //   try {
  //     dispatch({
  //       type: types.SET_LOADING,
  //     });

  //     const response = await axiosClient.get(`/api/filesoutput`);

  //     dispatch({
  //       type: types.GET_FILES,
  //       payload: response.data.files,
  //     });
  //   } catch (error) {
  //     dispatch({
  //       type: types.ERROR_GET_DATA,
  //       payload: error,
  //     });
  //   }
  // };

  // const getReportsYears = async () => {
  //   try {
  //     dispatch({
  //       type: types.SET_LOADING,
  //     });

  //     const response = await axiosClient.get(`/api/report/years`);

  //     dispatch({
  //       type: types.GET_REPORTS_YEARS,
  //       payload: response.data.years,
  //     });
  //   } catch (error) {
  //     dispatch({
  //       type: types.ERROR_GET_DATA,
  //       payload: error,
  //     });
  //   }
  // };

  return (
    <FileoutputContext.Provider
      value={{
        loading: state.loading,
        files: state.files,
        getFilesOutput
      }}
    >
      {props.children}
    </FileoutputContext.Provider>
  );
};

export default FilesoutputState;
