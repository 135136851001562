import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import mainLogo from "../../assets/logo/logo_crossover.png";

import AlertContext from "../../context/alert/alertContext";
import AuthContext from "../../context/authentication/authContext";

import Alert from "../core/Alert";

const LoginForm = (props) => {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const { alert, showAlert } = alertContext;
  const { logIn, authenticated, message } = authContext;
  const { showForgotPassword, version } = props;

  useEffect(() => {
    if (authenticated) {
      const user = JSON.parse(localStorage.getItem("user"));
      user.role === 4 ? props.history.push("/app/archivos") : props.history.push("/app/dias-adicionales");
    }

    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message, authenticated, props.history]);

  const [user, setUser] = useState({
    rut: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const { rut, password } = user;

  const onFinish = () => {
    setLoading(true);
    //validate empty fields
    if (rut.trim() === "" || password.trim() === "") {
      showAlert("Todos los campos son obligatorios.", "warning");
      setLoading(false);
      return;
    }

    setTimeout(() => {
      logIn({ email : rut, password });
      setLoading(false);
    }, 3000);
  };

  return (
    <Form
      name="normal_login"
      initialValues={{
        remember: true,
      }}
      style={{ width: "400px" }}
      onFinish={onFinish}
    >
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img src={mainLogo} alt="logo" />
      </div>
      <Form.Item name="rut">
        <Input
          prefix={<UserOutlined />}
          placeholder="Rut"
          name="rut"
          value={rut}
          onChange={onChange}
        />
      </Form.Item>
      <Form.Item name="password">
        <Input.Password
          prefix={<LockOutlined />}
          type="Contraseña"
          placeholder="Contraseña"
          name="password"
          value={password}
          onChange={onChange}
        />
      </Form.Item>
      {alert && <Alert message={alert.msg} type={alert.category} />}
      <Form.Item>
        <a onClick={showForgotPassword}>Recuperar contraseña</a>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          loading={loading && true}
        >
          Ingresar
        </Button>
      </Form.Item>
      <div style={{ textAlign: "center" }}>{`${version}`}</div>
    </Form>
  );
};

export default withRouter(LoginForm);
