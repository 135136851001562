import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Menu, Dropdown, Button } from "antd";
import {
  CarOutlined,
  ToolOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";

const NavBottom = (props) => {
  const [selectedButton, setSelectedButton] = useState(0);

  const onClickSelectedButton = (value) => {
    setSelectedButton(value);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "70px",
          display: "flex",
          position: "fixed",
          left: "0px",
          bottom: "0px",
          background: "white",
          justifyContent: "center"
        }}
      >
        <div style={{ width: "33.3%", padding: "20px", textAlign: "center" }}>
          <Dropdown overlay={menuSales(props)} placement="topCenter">
            <Button
              type="text"
              style={{ color: selectedButton === 0 && "#337ab7" }}
              onClick={() => onClickSelectedButton(0)}
            >
              {" "}
              <CarOutlined />
              Ventas
            </Button>
          </Dropdown>
        </div>
      </div>
      {/* <div style={{ width: "33.3%", padding: "20px", textAlign: "center" }}>
          <Dropdown overlay={menuOTs(props)} placement="topCenter">
            <Button
              type="text"
              style={{ color: selectedButton === 1 && "#337ab7" }}
              onClick={() => onClickSelectedButton(1)}
            >
              {" "}
              <ToolOutlined />
              OTs
            </Button>
          </Dropdown>
        </div>
        <div style={{ width: "33.3%", padding: "20px", textAlign: "center" }}>
          <Dropdown overlay={menuRepuestos(props)} placement="topCenter">
            <Button
              type="text"
              style={{ color: selectedButton === 2 && "#337ab7" }}
              onClick={() => onClickSelectedButton(2)}
            >
              {" "}
              <SettingOutlined />
              Repuestos
            </Button>
          </Dropdown>
        </div>
      </div> */}
    </>
  );
};

const menuSales = (props) => {
  return (
    <Menu>
      {/* <Menu.Item>
        <a onClick={() => props.history.push(`/app/ventas`)}>Dashboard</a>
      </Menu.Item> */}
      <Menu.Item>
        <a onClick={() => props.history.push(`/app/buscar-ventas`)}>
          Buscar ventas
        </a>
      </Menu.Item>
    </Menu>
  );
};

const menuOTs = (props) => {
  return (
    <Menu>
      <Menu.Item>
        <a onClick={() => props.history.push(`/app/ventas`)}>Dashboard</a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.taobao.com/"
        >
          Buscar OTs
        </a>
      </Menu.Item>
    </Menu>
  );
};

const menuRepuestos = (props) => {
  return (
    <Menu>
      <Menu.Item>
        <a onClick={() => props.history.push(`/app/ventas`)}>Dashboard</a>
      </Menu.Item>
      <Menu.Item>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.taobao.com/"
        >
          Buscar Repuestos
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(NavBottom);
