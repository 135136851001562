import React from "react";
import { Spin as SpinAntd, Space } from "antd";

const Spin = (props) => {
  const { height } = props;
  return (
    <Space style={{ width: "100%", height: height, justifyContent: "center" }}>
      <SpinAntd size="large" />
    </Space>
  );
};

export default Spin;
