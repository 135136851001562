import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.less";

import Layouts from "./components/Layout/Layouts";

import AuthState from "./context/authentication/authState";
import AlertState from "./context/alert/alertState";
import CommonDataState from "./context/commondata/commondataState";
import AditionalDaysDataState from "./context/additionaldays/additionaldaysState";
import AttendanceDaysDataState from "./context/attendancedays/attendancedaysState";
import AdministrativeDaysDataState from "./context/administrativedays/administrativedaysState";
import ReportsState from "./context/reports/reportsState";
import FilesoutputState from "./context/filesoutput/filesoutputState";
import PermitionDaysDataState from "./context/permitiondays/permitiondaysState";
import LegalVacationDaysDataState from "./context/legalvacationdays/legalvacationdaysState";

function App() {
  return (
    <AuthState>
      <AditionalDaysDataState>
        <AttendanceDaysDataState>
          <PermitionDaysDataState>
            <AdministrativeDaysDataState>
              <LegalVacationDaysDataState>
                <ReportsState>
                  <FilesoutputState>
                    <CommonDataState>
                      <AlertState>
                        <Router>
                          <Layouts />
                        </Router>
                      </AlertState>
                    </CommonDataState>
                  </FilesoutputState>
                </ReportsState>
              </LegalVacationDaysDataState>
            </AdministrativeDaysDataState>
          </PermitionDaysDataState>
        </AttendanceDaysDataState>
      </AditionalDaysDataState>
    </AuthState>
  );
}

export default App;
