import React, { useContext, useState } from "react";
import TableManualUploadFiles from "./TableManualUploadFiles";
import ModalUploadFile from "./ModalUploadFile";
import { Button, Form } from "antd";
import { FileExcelOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import FilesContext from "../../../context/files/filesContext";

const ManualUploadFile = () => {
  const filesContext = useContext(FilesContext);
  const { getFiles } = filesContext;

  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Form.Item>
        <div className="flex-container">
          <div className="flex-item-left">
            <Button
              type="primary"
              onClick={handleOpenModal}
              icon={<FileExcelOutlined />}
            >
              Cargar
            </Button>
          </div>
          <div className="flex-item-right">
            <Button
              style={{marginRight:20}}
              type="primary"
              icon={<CloudDownloadOutlined />}
              href={`${process.env.REACT_APP_BACKEND_URL}/api/template`}
            />
            <Button type="primary" onClick={getFiles}>
              Actualizar
            </Button>
          </div>
        </div>
      </Form.Item>

      <ModalUploadFile
        visible={visible}
        handleCloseModal={handleCloseModal}
      ></ModalUploadFile>
      <TableManualUploadFiles></TableManualUploadFiles>
    </>
  );
};

export default ManualUploadFile;
