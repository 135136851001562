import React, { useEffect, useContext } from "react";
import { Tag, Table } from "antd";
import moment from "moment";

import AdditionalDaysContext from "../../context/additionaldays/additionaldaysContext";
import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";

const MyRequests = () => {
  const additionalDaysContext = useContext(AdditionalDaysContext);
  const administrativelDaysContext = useContext(AdministrativelDaysContext);

  const {
    vacationRequests,
    loading,
    getVacationRequestsByCodEmployee,
  } = additionalDaysContext;

  const { getTypes, types } = administrativelDaysContext;

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getVacationRequestsByCodEmployee(user.username);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchGetTypes() {
      getTypes();
    }
    fetchGetTypes();
  }, []);

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "1",
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "2",
      render: (status) => (
        <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
          {status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Fecha desde",
      dataIndex: "FechaDesde",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Fecha hasta",
      dataIndex: "FechaHasta",
      key: "7",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Dias",
      dataIndex: "DiasSolicitados",
      key: "8",
    },
    {
      title: "Mi comentario",
      dataIndex: "Comentario",
      key: "3",
    },
    {
      title: "Nombre jefe",
      dataIndex: "NombreJefe",
      key: "4",
    },
    {
      title: "Comentario jefe",
      dataIndex: "ComentarioJefe",
      key: "5",
    },
  ];

  if (!vacationRequests) {
    return "loading...";
  }

  return <Table dataSource={vacationRequests} columns={columns} loading={loading} scroll={{ x: 'auto' }} /> ;
};

export default MyRequests;
