import React, { useEffect, useContext, useState } from "react";
import { Tag, Space, Table, Modal as ModalAntd } from "antd";
import Modal from "../core/Modal";
import moment from "moment";

import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";
import AlertContext from "../../context/alert/alertContext";
import RequestFormEditAdministrativeDays from "../administrativedays/FormAdministrativeDays/RequestFormEditAdministrativeDays";
import FormDeleteAdministrativeDays from "../administrativedays/FormAdministrativeDays/FormDeleteAdministrativeDays"

const MyRequestsAdmin = () => {
  const administrativelDaysContext = useContext(AdministrativelDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;
  const startOfMonth = moment().utc().add(-1,"month").startOf('month').format('YYYY-MM-DD HH:mm:ss');
  


  const {
    adminRequests,
    message,
    loading,
    getAdminRequestsByCodEmployee,
    getAdminRequestsModifyByCodEmployee,
    requestDeleteAdmin,
    getTypes, 
    types,
    adminRequestsModify,
  } = administrativelDaysContext;

  //modal variables
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [width, setWidth] = useState();

  async function fetchData() {
    const user = JSON.parse(localStorage.getItem("user"));
    getAdminRequestsByCodEmployee(user.username);
  }

  const showModalUpdateRequest = (record, action) => {
    
    
    if(action === 'edit'){
      setIsVisibleModal(true);
      setModalTitle("Editar Solicitud");
      setModalContent(
        <RequestFormEditAdministrativeDays
          setIsVisibleModal={setIsVisibleModal}
          record={record}
          message={message}
        ></RequestFormEditAdministrativeDays>
      );
      setWidth(600);
    }else{
      setIsVisibleModal(true);
      setModalTitle("Solicitud de Eliminación");
      setModalContent(
        <FormDeleteAdministrativeDays
          requestDeleteAdmin ={requestDeleteAdmin}
          record={record}
          setIsVisibleModal={setIsVisibleModal}
        ></FormDeleteAdministrativeDays>
      );
      setWidth(600);
    }
  };

  useEffect(() => {
    async function fetchDataModify() {
      const user = JSON.parse(localStorage.getItem("user"));
      getAdminRequestsModifyByCodEmployee(user.username);
    }
    fetchDataModify();
  }, [message]);

  useEffect(() => {
    fetchData();
  }, [message]);
  
  useEffect(() => {
    async function fetchGetTypes() {
      getTypes();
    }
    fetchGetTypes();
  }, []);

  useEffect(() => {
    if (message) {
      fetchData();
      showAlert(message.msg, message.category);
    }
  }, [message]);

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "Tipo",
      width: "10%",
      render: (status) => (
       <p> { types.find(type => type.id == status)?.description  } </p>
      ), 
    },
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "CodigoEmpleado",
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "2",
      render: (status, record) => (
        <Tag color={adminRequestsModify.find(data => record.Id === data.IdSolicitudAdmin)?.Estado === "P" ? "blue" :
                                                                                          status === "P"  ? "blue" :
                                                                                          status === "A"  ? "green" : "red"}>
          {adminRequestsModify.find(data => record.Id === data.IdSolicitudAdmin)?.Estado === "P" ? 
          (adminRequestsModify.find(data => record.Id === data.IdSolicitudAdmin)?.TipoSolicitud === "PM" ? "Pend. Modificacion" :
          adminRequestsModify.find(data => record.Id === data.IdSolicitudAdmin)?.TipoSolicitud === "PD" ? "Pend. Eliminacion" : "Pendiente")
          :
            status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Fecha desde",
      dataIndex: "FechaDesde",
      key: "FechaDesde",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Fecha hasta",
      dataIndex: "FechaHasta",
      key: "FechaHasta7",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Dias",
      dataIndex: "DiasSolicitados",
      key: "DiasSolicitados",
    },
    {
      title: "Mi comentario",
      dataIndex: "Comentario",
      key: "Comentario",
    },
    {
      title: "Nombre jefe",
      dataIndex: "NombreJefe",
      key: "NombreJefe4",
    },
    {
      title: "Comentario jefe",
      dataIndex: "ComentarioJefe",
      key: "ComentarioJefe",
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">  
          { 
            adminRequestsModify.find(data => record.Id === data.IdSolicitudAdmin)?.Estado != "P" && record.Estado === "A" ?
              (startOfMonth > moment.utc(record.FechaDesde).format('YYYY-MM-DD HH:mm:ss') ?
                ""
                :
                <>
                  <a onClick={() => showModalUpdateRequest(record, "edit")}>
                    Editar
                  </a>
                  <a onClick={() => showModalUpdateRequest(record, "delete")}>
                    Eliminar
                  </a>
                </>
              ) : 
              adminRequestsModify.find(data => record.Id === data.IdSolicitudAdmin)?.Estado === "P"  ?
              (
                <>
                  <Tag color="blue">
                    {adminRequestsModify.find(data => record.Id === data.IdSolicitudAdmin)?.TipoSolicitud === "PM" ? "Pend. Modificacion" : "Pend. Eliminacion"}
                  </Tag>
                </>
              )
              :
              ""
          }
        </Space>
      ),
    },
  ];
  
  if (!adminRequests) {
    return "loading...";
  }

  // return <Table dataSource={adminRequests} columns={columns} loading={loading} scroll={{ x: 'auto' }} /> ;
  const reload=()=>window.location.reload();
  return (
    <>
      <Table dataSource={adminRequests} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={width}
      >
        {modalContent}
      </Modal>
    </>
  );
};

export default MyRequestsAdmin;
