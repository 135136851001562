import React, { useEffect, useContext } from "react";
import { Alert } from "antd";
import FormPermitionDays from "./FormPermitionDay";
import PermitionDaysContext from "../../context/permitiondays/permitiondaysContext";
import AlertContext from "../../context/alert/alertContext";

const PermitionDays = () => {
  const permitiondaysContext = useContext(PermitionDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    types,
    balanceDays,
    vacationRequestPending,
    loading,
    message,
    getBalanceDays,
    addVacationRequest,
    getPendingVacationRequestsByCodEmployee,
    getTypes,
  } = permitiondaysContext;

  useEffect(() => {
    async function fetchPendingVacationRequest() {
      const user = JSON.parse(localStorage.getItem("user"));
      getPendingVacationRequestsByCodEmployee(user.username);
    }
    fetchPendingVacationRequest();
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    getTypes(user.username);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getBalanceDays(user.username);
    }
    fetchData();
  }, []);

  return (
    <>     
    { vacationRequestPending.length > -1 ? (
        <FormPermitionDays
          types={types}
          balanceDays={balanceDays}
          loading={loading}
          message={message}
          addVacationRequest={addVacationRequest}
          alert={alert}
          showAlert={showAlert}
        />    
        ) : (
          <Alert
            message="Solicitud pendiente"
            description="Usted tiene una solicitud pendiente, favor contactar a supervisor."
            type="info"
            showIcon
            style={{ width: "50%" }}
          />
      )} 
    </>
  );
};

export default PermitionDays;
