import React, { useEffect, useContext, useState } from "react";
import { Tag, Space, Table, Modal as ModalAntd } from "antd";
import Modal from "../core/Modal";
import moment from "moment";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";
import FormEditAdministrativeDays from "../administrativedays/FormAdministrativeDays/FormEditAdminstrativeDays";
import Search from "antd/lib/input/Search";

const AdminRRHHRequest = () => {

  const administrativelDaysContext = useContext(AdministrativelDaysContext);
  const { getTypes, 
    types,      
    loading,
    message,
    adminRequestsRRHH,
    getAdminRequestsByRRHH,
    deleteAdminRequest
  } = administrativelDaysContext;


   //modal variables
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [width, setWidth] = useState();

  const showModalUpdateRequest = (record, action) => {
    
    
    if(action === 'edit'){
      setIsVisibleModal(true);
      setModalTitle("Editar Solicitud");
      setModalContent(
        <FormEditAdministrativeDays
        setIsVisibleModal={setIsVisibleModal}
        record={record}
        ></FormEditAdministrativeDays>
      );
      setWidth(600);
    }else{

      ModalAntd.confirm({
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: <p>¿Esta seguro que desea eliminar la solicitud?</p>,
        onOk() {
          deleteAdminRequest(record.Id);
        },
        onCancel() {
        },
      }) 
    }

  };

  useEffect(() => {
    async function fetchGetTypes() {
      getTypes();
    }
    fetchGetTypes();
  }, []);

  useEffect(() => {
    async function fetchData() {
      getAdminRequestsByRRHH();
    }
    fetchData();
  }, [message]);

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "0",
      width: "10%",
      render: (status) => (
       <p style={marginP}> { types.find(type => type.id == status)?.description } </p>
      ), 
      filters: [
        ...types.map(x => { return { 'text': x.description, 'value': x.id }})         
      ],
      onFilter: (value, record) =>  record.Tipo.toString().indexOf(value) === 0
    },
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "rrhhCodigoEmpleado",
      filters: [
        ...Array.from(new Set(adminRequestsRRHH.map(x => x.CodigoEmpleado))).map(x => { return { 'text': x, 'value': x }})         
      ],
      onFilter: (value, record) => record.CodigoEmpleado.indexOf(value) === 0,
    },
    {
      title: "Nombre colaborador",
      dataIndex: "Nombre",
      key: "2",
      filters: [
        ...Array.from(new Set(adminRequestsRRHH.map(x => x.Nombre))).map(x => { return { 'text': x, 'value': x }})         
      ],
      onFilter: (value, record) => record.Nombre.indexOf(value) === 0,
    },
    {
      title: "Fecha solicitud",
      dataIndex: "FechaCreacion",
      key: "3",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "4",
      render: (status) => (
        <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
          {status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Desde",
      dataIndex: "FechaDesde",
      key: "5",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Hasta",
      dataIndex: "FechaHasta",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Dias",
      dataIndex: "DiasSolicitados",
      key: "7",
    },
    {
      title: "Comentario colaborador",
      dataIndex: "Comentario",
      key: "8",
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">        
            <>
              <a onClick={() => showModalUpdateRequest(record, "edit")}>
                Editar
              </a>
              <a onClick={() => showModalUpdateRequest(record, "delete")}>
                Eliminar
              </a>
            </>         
        </Space>
      ),
    },
  ];

  if (!adminRequestsRRHH) {
    return "loading...";
  }

  const onSearch = (value) =>{
    console.log("onSearch", value);
    getAdminRequestsByRRHH(value.trim());
  }

  return (
    <>
      <Search placeholder="Busqueda por rut o nombre" allowClear={true} enterButton="Buscar" size="middle" onSearch={onSearch}/>
      <br></br>
      <br></br>
      <Table dataSource={adminRequestsRRHH} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={width}
      >
        {modalContent}
      </Modal>
    </>
  );

}

export default AdminRRHHRequest;