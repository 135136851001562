import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import { countDaysNotWeekend } from "../../../utils";

const FormPermitionDays = (props) => {
  const {
    types,
    balanceDays,
    loading,
    message,
    alert,
    showAlert,
    addVacationRequest,
  } = props;

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    if(!loading){
      setInfoBalance();
    }
  }, [balanceDays, loading]);

  const [inputs, setInputs] = useState({
    date: "",
    dateString: "",
    days: 0,
    days_c: 0,
    days_label: 0,
    comment: "",
    imgBase64: "",
    imgExt: "",
    view_info_days: false
  });

  const [disabledForm, setDisabledForm] = useState(false);
  const [file, setFile] = useState({
    fileImg: null,
    base64URL: ""
  });

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 6 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 6 },
  };

  const onChangeTypeDays = async (value) => {

    const typeDay = await types.find((day) => day.id == value);

    setInputs({
      ...inputs,
      days: typeDay.days,
      days_c: typeDay.days_c,
      days_label: typeDay.label,
      type: value,
      dateString: ["", ""],
      date: "",
      view_info_days: typeDay.view_info
    });

    form.resetFields(['date']);

    if (typeDay.days + typeDay.days_c > 0 ) {
      setDisabledForm(false);
      // setActiveRequest(true);
    } else {
      setDisabledForm(true);
      // setActiveRequest(false);
    }
  };

  const renderOptionsTypeDays = () => {
    return types.map((type) => (
      <Option value={type.id} key={type.id}>
        {type.description}
      </Option>
    ));
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const setInfoBalance = () =>{
    const { NombreJefe } = balanceDays;
    setInputs({
      ...inputs,
      NombreJefe
    })
  }

  const onChangeDate = (date, dateString) => {
    if (date != null) {
      addWorkingDays(date, inputs.days);
    }
  };

  const onChangeCountDays = (value) => {
    setInputs({ ...inputs, days: value });
  };

  const addWorkingDays = (date, days) => {
    let endDate = date.clone();
    for (let i = 1; i < days; i++) {
      if (endDate.isoWeekday() !== 6 && endDate.isoWeekday() !== 7) {
        endDate = endDate.add(1, "days");
      } else {
        endDate = endDate.add(1, "days");
        i--;
      }
    }
    endDate.add(inputs.days_c, 'days');
    const dates = [date.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY")];
    setInputs({ ...inputs, dateString: dates, date: date });
  };

  const renderOptionsBalance = (balance) => {
    let array = [];
    for (var i = 1; i <= balance; i++) {
      array.push(i);
    }

    return array.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    return (
      (current && current < moment().subtract("1", "d").endOf("day")) ||
      moment(current).day() === 0 ||
      moment(current).day() === 6
    );
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  const getBase64 = file => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleFileInputChange = e => {
    let { fileImg } = file;

    fileImg = e.target.files[0];

    if (fileImg.type === 'image/jpeg' || fileImg.type === 'image/jpeg' || fileImg.type === 'application/pdf') {
      getBase64(fileImg)
        .then(result => {
          fileImg["base64"] = result;
          setFile({
            base64URL: result,
            fileImg
          });
          setInputs({ ...inputs, imgBase64: result, imgExt: fileImg.name.split('.').pop() });
        })
        .catch(err => {
          console.log(err);
        });

      setFile({
        fileImg: e.target.files[0]
      });

    } else {
      setFile({
        fileImg: null,
        base64URL: ""
      });
      setInputs({ ...inputs, imgBase64: "", imgExt: "" });
      showAlert("Solo soporta imagenes jpg o png, y archivos pdf", "warning");
      return;
    }
  };

  const onFinish = async () => {
    const { dateString, days, days_c } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays = countDaysNotWeekend(startDay,endDay);

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }
    await addVacationRequest(inputs);

    form.resetFields();
  };

  const { Saldo = 0, NombreJefe = '',  DiasOtorgados = '' } = balanceDays ?? {};

  return (
    <Spin tip="Cargando..." spinning={loading}>

      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        
        <Form.Item label="Dias otorgados" name="dias_otorgados">
          <p style={{ margin: "auto" }}>
            {inputs.view_info_days == 0 && inputs.days > 0 && (inputs.days + ' dias Habiles')} {inputs.view_info_days == 0 && inputs.days > 0 && inputs.days_c > 0 && (' y ')} { inputs.view_info_days == 0 && inputs.days_c > 0 && (inputs.days_c + ' dias corridos')}
            {inputs.view_info_days == 1 && inputs.days_label + ' dias Habiles'}
          </p>
        </Form.Item>

        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{NombreJefe ? NombreJefe : ""}</p>
        </Form.Item>

        <Form.Item label="Motivo" name="days">
          <Select name="days" placeholder="Seleccione opción"  onChange={onChangeTypeDays}>
            {renderOptionsTypeDays()}
          </Select>
        </Form.Item>

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
            <DatePicker
              name="date"
              style={{ width: "263px" }}
              format="DD-MM-YYYY"
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
              onChange={onChangeDate}
              disabled={disabledForm}
              locale={esES}
            />
          </Space>
        </Form.Item>

        <Form.Item label="Hasta" name="hasta">
          <p style={{ margin: "auto" }}>{inputs.dateString[1] ? inputs.dateString[1] : ""}</p>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
            disabled={disabledForm}
          />
        </Form.Item>

        <Form.Item label="Imagen Comprobante" name="fileLabel">
          <input type="file" name="file" onChange={handleFileInputChange} disabled={disabledForm} multiple={false} accept="image/*,application/pdf" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            Solicitar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormPermitionDays;
