import React, { useEffect, useContext, useState } from "react";
import { Tag, Space, Alert, Table } from "antd";
import moment from "moment";

import Modal from "../core/Modal";
// import Table from "../core/Table";
import FormApproveDay from "./FormApproveDay";
import AttendanceDaysContext from "../../context/attendancedays/attendancedaysContext";
import AlertContext from "../../context/alert/alertContext";
import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";

const RequestFromMyEmployees = () => {
  const attendanceDaysContext = useContext(AttendanceDaysContext);
  const administrativelDaysContext = useContext(AdministrativelDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    message,
    vacationRequestBoss,
    loading,
    getAttendanceRequestsByCodBoss,
    updateAttendanceRequest,
  } = attendanceDaysContext;

  const { getTypes, types } = administrativelDaysContext;

  useEffect(() => {
    async function fetchGetTypes() {
      getTypes();
    }
    fetchGetTypes();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getAttendanceRequestsByCodBoss(user.username);
    }
    fetchData();
  }, [message]);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  //modal variables
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const showModalUpdateRequest = (record, action) => {
    setIsVisibleModal(true);
    //set title modal
    setModalTitle("Actualizar solicitud");
    //set modal content, in this case, form edit
    setModalContent(
      <FormApproveDay
        record={record}
        action={action}
        setIsVisibleModal={setIsVisibleModal}
        updateAttendanceRequest={updateAttendanceRequest}
        alert={alert}
        message={message}
        showAlert={showAlert}
      />
    );
  };

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "1",
    },
    {
      title: "Nombre colaborador",
      dataIndex: "Nombre",
      key: "10",
    },
    {
      title: "Fecha solicitud",
      dataIndex: "FechaCreacion",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "2",
      render: (status) => (
        <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
          {status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Fecha Marcacion",
      dataIndex: "FechaMarcacion",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Hora Marcacion",
      dataIndex: "HoraMarcacion",
      key: "7",
    },
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "8",
      render: (tipo) => (
        <Tag color={tipo === "Entrada" ? "blue" : "red"}>
          {tipo}
        </Tag>
      ),
    },
    {
      title: "Comentario colaborador",
      dataIndex: "Comentario",
      key: "3",
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {record.Estado === "P" ? (
            <>
              <a onClick={() => showModalUpdateRequest(record, "approve")}>
                Aprobar
              </a>
              <a onClick={() => showModalUpdateRequest(record, "refuse")}>
                Rechazar
              </a>
            </>
          ) : (
            <Tag color="green">Finalizada</Tag>
          )}
        </Space>
      ),
    },
  ];

  if (!vacationRequestBoss) {
    return "loading...";
  }

  return (
    <>
      {alert && (
        <Alert
          style={{ marginBottom: "10px" }}
          message={alert.msg}
          type={alert.category}
        />
      )}
      <Table dataSource={vacationRequestBoss} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="1000px"
      >
        {modalContent}
      </Modal>
    </>
  );
};

export default RequestFromMyEmployees;
