import React, { useEffect, useState } from "react";
import { Form, Input, Button, Tag } from "antd";
import renderEmpty from "antd/lib/config-provider/renderEmpty";

const FormDeleteAdministrativeDays = (props) => {
  const { record, requestDeleteAdmin, setIsVisibleModal } = props;

  const [form] = Form.useForm();
  const [comment, setComment] = useState("");
  const { TextArea } = Input;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const onChangeComment = (e) => {
    setComment(e.target.value);
  };

  const onFinish = async () => {
    const data = {
      comment: comment,
    };
    requestDeleteAdmin(record.Id, data);
    setIsVisibleModal(false);
    renderEmpty()
  };

  return (
    <Form
      {...layout}
      form={form}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item label="Comentario" name="commentLabel">
        <TextArea name="comment" rows={4} onChange={onChangeComment} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button
          type="primary"
          htmlType="submit"
          danger={true}
        >
          {"Solicitar Eliminación"}
        </Button>
      </Form.Item>
      
      



      {/* // ModalAntd.confirm({
      //   centered: true,
      //   icon: <ExclamationCircleOutlined />,
      //   content: <p>¿Esta seguro que desea solicitar la eliminacion del día administrativo?</p>,
      //   onOk() {
      //     requestDeleteAdmin(record.Id);
      //     fetchData();
      //   },
      //   onCancel() {
      //   },
      // })  */}


    </Form>
  );
};

export default FormDeleteAdministrativeDays;
