import React, { useReducer } from "react";
import commondataReducer from "./commondataReducer";
import commondataContext from "./commondataContext";
import { GET_DEALERS, GET_SUCURSALES, ERROR_GET_DATA, GET_EMPLOYEES } from "../../types";
import axiosClient from "../../config/axios";

const CommonDataState = (props) => {
  const initialState = {
    dealers: [],
    sucursales: [],
    employees: [],
    loading: true,
    error: false,
  };

  const [state, dispatch] = useReducer(commondataReducer, initialState);

  const getDealers = async () => {
    try {
      const response = await axiosClient.get(`/api/dealers`);
      dispatch({
        type: GET_DEALERS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const getSucursales = async () => {
    try {
      const response = await axiosClient.get(`/api/sucursales`);
      dispatch({
        type: GET_SUCURSALES,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const getEmployees = async () => {
    try {
      const response = await axiosClient.get(`/api/employee`);
      dispatch({
        type: GET_EMPLOYEES,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  return (
    <commondataContext.Provider
      value={{
        dealers: state.dealers,
        sucursales: state.sucursales,
        loading: state.loading,
        error: state.error,
        employees: state.employees,
        getDealers,
        getSucursales,
        getEmployees
      }}
    >
      {props.children}
    </commondataContext.Provider>
  );
};

export default CommonDataState;
