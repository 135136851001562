import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
} from "antd";

import PermitionDaysContext from "../../../context/permitiondays/permitiondaysContext";
import AlertContext from "../../../context/alert/alertContext";
import esES from "antd/lib/locale-provider/es_ES";
import { countDaysNotWeekend } from "../../../utils";

const FormEditPermitionDay = (props) => {
  const { 
    record, 
    setIsVisibleModal, 
    message,
    alert,
    showAlert, 
  } = props;
  const permitiondaysContext = useContext(PermitionDaysContext);
  const {
    types,
    balanceDays,
    loading,
    getBalanceDays,
    updateVacationRequestRRHH,
    getTypes,
  } = permitiondaysContext;
  
  const [finputs, setfinputs] = useState({
    date: "",
    dateString: "",
    days: 0,
    days_c: 0,
    days_label: 0,
    comment: "",
    view_info_days: false
  });

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const initialValues = {
    commentLabel: record.Comentario,
    days: record.DiasSolicitados,
    tipo: record.Tipo
  };

  useEffect(() => {
    getTypes(record.CodigoEmpleado);
  }, [record]);

  useEffect(() => {
    async function initForm() {
      if (types) {
        form.resetFields();
        getBalanceDays(record.CodigoEmpleado);

        const typeDay = types.find((day) => day.id == record.Tipo);

        if (typeDay) {

          setfinputs({
            ...finputs,
            NombreJefe: record.NombreJefe,
            date: moment(record.FechaDesde, "YYYY-MM-DD"),
            dateString: [
              moment(record.FechaDesde, "YYYY-MM-DD").format("DD-MM-YYYY"),
              moment(record.FechaHasta, "YYYY-MM-DD").format("DD-MM-YYYY"),
            ],
            days: typeDay.days,
            days_c: typeDay.days_c,
            days_label: typeDay.label,
            view_info_days: typeDay.view_info,
            tipo: record.Tipo,
          });

        }
      }
    }

    initForm();

  }, [types]);

  const { Saldo = 0, NombreJefe = '',  DiasOtorgados = '' } = balanceDays ?? {};

  const onChange = (e) => {
    setfinputs({
      ...finputs,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDate = (date, dateString) => {
    if (date != null) {
      addWorkingDays(date, finputs.days);
    }
  };

  const addWorkingDays = (date, days) => {
    let endDate = date.clone();
    for (let i = 1; i < days; i++) {
      if (endDate.isoWeekday() !== 6 && endDate.isoWeekday() !== 7) {
        endDate = endDate.add(1, "days");
      } else {
        endDate = endDate.add(1, "days");
        i--;
      }
    }
    endDate.add(finputs.days_c, 'days');
    const dates = [date.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY")];
    record.FechaHasta = endDate.format("YYYY-MM-DD");
    setfinputs({ ...finputs, dateString: dates, date: date });
  };

  const renderOptionsTypeDays = () => {
    return types.map((type) => (
      <Option value={type.id} key={type.id}>
        {type.description}
      </Option>
    ));
  };

  const onChangeTypeDays = async (value) => {

    const typeDay = types.find((day) => day.id == value);

    let fechaDesde = moment(finputs.dateString[0], "DD-MM-YYYY");
    addWorkingDays(fechaDesde, typeDay.days);

    await setfinputs({
      ...finputs,
      days: typeDay.days,
      days_c: typeDay.days_c,
      days_label: typeDay.label,
      tipo: typeDay.id,
      view_info_days: typeDay.view_info
    });
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) =>  moment(current).day() === 0 || moment(current).day() === 6;

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  const onFinish = async () => {
    const { dateString, days } = finputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays = countDaysNotWeekend(startDay,endDay);

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    await updateVacationRequestRRHH(record.Id, finputs)
    form.resetFields();
    setIsVisibleModal(false);
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
      >

        <Form.Item label="Rut" name="rut_colaborador">
          <p style={{ margin: "auto" }}>
            {record.CodigoEmpleado ? record.CodigoEmpleado : ""}
          </p>
        </Form.Item>

        <Form.Item label="Nombre" name="nombre_colaborador">
          <p style={{ margin: "auto" }}>{record.Nombre ? record.Nombre : ""}</p>
        </Form.Item>

        <Form.Item label="Dias otorgados" name="dias_otorgados">
          <p style={{ margin: "auto" }}>
            {finputs.view_info_days == 0 && finputs.days > 0 && (finputs.days + ' dias Habiles')} {finputs.view_info_days == 0 && finputs.days > 0 && finputs.days_c > 0 && (' y ')} { finputs.view_info_days == 0 && finputs.days_c > 0 && (finputs.days_c + ' dias corridos')}
            {finputs.view_info_days == 1 && finputs.days_label + ' dias Habiles'}
          </p>
        </Form.Item>

        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{record.NombreJefe ? record.NombreJefe : ""}</p>
        </Form.Item>

        <Form.Item label="Motivo" name="tipo">
          <Select name="tipo" value={finputs.tipo} placeholder="Seleccione opción" onChange={onChangeTypeDays}>
            {renderOptionsTypeDays()}
          </Select>
        </Form.Item>

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
            <DatePicker
              name="date"
              style={{ width: "263px" }}
              format="DD-MM-YYYY"
              disabledDate={disabledDate}
              disabledTime={disabledDateTime}
              onChange={onChangeDate}
              locale={esES}
              defaultValue={moment(record.FechaDesde, "YYYY-MM-DD")}
            />
          </Space>
        </Form.Item>

        <Form.Item label="Hasta" name="hasta">
          <p style={{ margin: "auto" }}>
            {record && moment(record.FechaHasta, "YYYY-MM-DD").format("DD-MM-YYYY")}
          </p>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" >
            Modificar
          </Button>
        </Form.Item>
        
      </Form>
    </Spin>
  );
};

export default FormEditPermitionDay;
