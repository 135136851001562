import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
  AutoComplete,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import AttendanceDaysContext from "../../context/attendancedays/attendancedaysContext";
import CommonContext from "../../context/commondata/commondataContext";
import AlertContext from "../../context/alert/alertContext";
import { countDaysNotWeekend } from "../../utils";



const FormRRHHAttendanceDays = (props) => {
  const commonContext = useContext(CommonContext);
  const attendanceDaysContext = useContext(AttendanceDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const [disabledForm, setDisabledForm] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [messageError, setMessageError] = useState("");
  const [user, setUser] = useState();
  const [userRRHH, setUserRRHH] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const tipoOptions = ["Entrada", "Salida"];

  const { getEmployees, employees } = commonContext;

  const initInput = {
    date: "",
    dateString: "",
    days: 1,
    comment: "",
    tipo: tipoOptions[0],
  };

  const [inputs, setInputs] = useState(initInput);

  const [form] = Form.useForm();

  const {
    attendanceRequests,
    message,
    loading,
    addAttendanceRequestRRHH,
    getInfoEmployee,
    infoEmployee,
    getAttendanceRequestsByCodEmployee,
    balanceDays,
    getBalanceDays,
    attendanceRequestPending,
    getPendingRequestByCodEmployee,
  } = attendanceDaysContext;


  useEffect(() => {
    // getTypes();
    getEmployees();
  }, []);

  useEffect(() => {
    if (!loading) {
      onSelect(userRRHH.username);
    }
  }, [employees]);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getBalanceDays(user.username);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    async function fetchInfoUser() {
      const user = JSON.parse(localStorage.getItem("user"));
      getInfoEmployee(user.username);
    }
    fetchInfoUser();
  }, []);

  useEffect(() => {
    async function fetchRequestUser() {
      const user = JSON.parse(localStorage.getItem("user"));
      getAttendanceRequestsByCodEmployee(user.username);
    }
    fetchRequestUser();
  }, []);

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 10 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 6 },
  };

  const { NombreJefe = ""} = balanceDays ?? {};

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const setInfoBalance = () => {
    const { NombreJefe } = balanceDays;
    setInputs({
      ...inputs,
      NombreJefe,
    });
  };

  const setInfoBalanceDays = () => {
    const { Cod_Tipo_Documento } = infoEmployee ?? "1";
    setInputs({
      ...inputs,
      Cod_Tipo_Documento,
    });
  };

  useEffect(() => {
    if (!loading) {
      setInfoEmployee();
    }
  }, [infoEmployee, loading]);

  useEffect(() => {
    if (!loading && !firstLoading) {
      setInfoBalance();
    }
  }, [balanceDays, loading]);

  useEffect(() => {
    if (!loading && firstLoading) {
      setInputs({
        ...initInput,
      });
    }
  }, [loading]);

  useEffect(() => {
    if (!loading && !firstLoading) {
      setInfoBalance();
    }
  }, [balanceDays, loading]);

  const setInfoEmployee = () =>{
    const { Cod_Tipo_Documento } = infoEmployee ?? '1';
    setInputs({
      ...inputs,
      Cod_Tipo_Documento
    })
  };

  const renderOptionsType = () => {
    return tipoOptions.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };

  useEffect(() => {
    if (attendanceRequests) {
      if (
        attendanceRequests.length > 0 &&
        attendanceRequests[0].Tipo == "Entrada"
      ) {
        showAlert("Ultimo Registro es Entrada. Debe marcar Salida", "warning");
      }
    }
  }, [attendanceRequests]);

  useEffect(() => {
    if (infoEmployee) {
      if(infoEmployee[0].Cod_Tipo_Documento == 1){
        showAlert("Actualmente no dispones de esta funcionalidad", "warning");
        setDisabledForm(true);
      }
      else{
        setDisabledForm(false);
      }
    }
  }, [infoEmployee]);

  const onChangeType = (value) => {
    setInputs({ ...inputs, tipo: value });
    if (attendanceRequests.length > 0 && attendanceRequests[0].Tipo == value) {
      showAlert("Revisa tipo de marcacion", "warning");
    }
    if (attendanceRequests.length == 0 && value == "Salida") {
      showAlert(
        "si no tienes marcaciones, debes ingresar una entrada",
        "warning"
      );
    }
  };

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  const disabledDate = (current) => {
    return (
      current >= moment().subtract("1", "d").endOf("day") ||
      moment(current).day() === 0 ||
      moment(current).day() === 6
    );
  };

  const onSelect = (value) => {
    const userSplit = value.split("-");
    const user = `${userSplit[0]}-${userSplit[1]}`.trim();
    setMessageError("");
    setUser({ username: user });
    getInfoEmployee(user);
    getBalanceDays(user);
    getAttendanceRequestsByCodEmployee(user);
    setInputs({
      ...initInput,
    });
    setFirstLoading(false);
    form.resetFields();
  };


  const onFinish = async () => {
    const { dateString } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");
    let diffDays = countDaysNotWeekend(startDay, endDay);

    if (!dateString) {
      showAlert("La fecha es requerida", "warning");
      return;
    }
      
    if (attendanceRequests) {
      if (
        attendanceRequests.length > 0 &&
        attendanceRequests[0].Tipo == inputs.tipo
      ) {
        showAlert("Revisa tipo de marcacion", "warning");
        return;
      } else if (
        attendanceRequests.length > 0 &&
        attendanceRequests[0].Tipo != inputs.tipo
      ) {
        let date0 = moment(dateString, "DD-MM-YYYY HH:mm");
        let dateNew = moment(
          attendanceRequests[0].FechaMarcacion +
            " " +
            attendanceRequests[0].HoraMarcacion,
          "YYYY-MM-DD HH:mm"
        );
        let isBefore = dateNew.isBefore(date0);
        if (!isBefore) {
          showAlert(
            "Revisa fecha y hora de marcacion, segun ultimo registro",
            "warning"
          );
          return;
        }
      } else if (attendanceRequests.length == 0 && inputs.tipo == "Salida") {
        showAlert(
          "si no tienes marcaciones, debes ingresar una entrada",
          "warning"
        );
        return;
      }
    }

    await addAttendanceRequestRRHH(inputs);
    
    form.resetFields();
  };

  const { Cod_Tipo_Documento } = infoEmployee ?? {};

  if (Cod_Tipo_Documento == "1") {
    disabledForm = true;
  }

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form.Item {...layout} label="Empleado" name="empleado">
        <AutoComplete
          placeholder="Seleccionar"
          allowClear={true}
          options={employees.map((x) => {
            return {
              rut: x.Rut,
              value: `${x.Rut} - ${x.Nombres} ${x.Apaterno} ${x.Amaterno}`,
            };
          })}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
          onSelect={onSelect}
          defaultValue={`${userRRHH.rut} - ${userRRHH.name} ${userRRHH.lastName}`}
        ></AutoComplete>
      </Form.Item>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >

      <Form.Item label="Jefe a cargo" name="jefe_cargo">
        <p style={{ margin: "auto" }}>{NombreJefe ? NombreJefe : ""}</p>
      </Form.Item>

      <Form.Item label="Marcacion" name="types">
        <Select
          value={{ value: tipoOptions[0], label: tipoOptions[0] }}
          defaultValue={tipoOptions[0]}
          name="tipo"
          onChange={onChangeType}
          disabled={disabledForm}
        >
          {renderOptionsType()}
        </Select>
      </Form.Item>

      <Form.Item label="Seleccionar dia" name="date">
        <Space direction="vertical">
          <DatePicker
            name="date"
            style={{ width: "263px" }}
            format="DD-MM-YYYY HH:mm"
            showTime={true}
            disabledDate={disabledDate}
            disabledTime={false}
            onChange={onChangeDate}
            disabled={disabledForm}
            locale={esES}
          />
        </Space>
      </Form.Item>

      <Form.Item label="Comentario" name="commentLabel">
        <TextArea
          name="comment"
          rows={4}
          onChange={onChange}
          disabled={disabledForm}
        />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" disabled={disabledForm}>
          Solicitar
        </Button>
      </Form.Item>
      <Form.Item {...tailLayout}>
        {alert && <Alert message={alert.msg} type={alert.category} />}
      </Form.Item>
      {/* {disabledForm && (
              <Alert
                message=""
                type="info"
                showIcon
                style={{ marginLeft: "10%", minWidth: "40%", maxWidth:"45%", marginBottom: "20px" }}
              />
        )} */}
      </Form>
    </Spin>
  );
};

export default FormRRHHAttendanceDays;
