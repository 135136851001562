import React, { useState } from "react";
import { Row } from "antd";

import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";

import packageJson from "../../../package.json";

const Login = () => {
  const [forgotPassword, setForgotPassword] = useState(false);

  const showForgotPassword = () => {
    setForgotPassword(true);
  };

  const showLogin = () => {
    setForgotPassword(false);
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh" }}
    >
      {forgotPassword ? (
        <ForgotPassword showLogin={showLogin} version={packageJson.version} />
      ) : (
        <LoginForm
          showForgotPassword={showForgotPassword}
          version={packageJson.version}
        />
      )}
    </Row>
  );
};

export default Login;
