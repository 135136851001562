import React, { useEffect, useContext, useState } from "react";
import { Tag, Space, Alert, Table } from "antd";
import moment from "moment";

import Modal from "../core/Modal";
// import Table from "../core/Table";
import FormApproveDay from "./FormApproveDay";
import PermitionDaysContext from "../../context/permitiondays/permitiondaysContext";
import AlertContext from "../../context/alert/alertContext";
import FormViewPermitionDay from "./FormPermitionDay/FormViewPermitionDay";

const RequestPermitFromMyEmployees = () => {
  const permitiondaysContext = useContext(PermitionDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    getAllTypes, 
    allTypes,
    message,
    vacationRequestBoss,
    loading,
    getVacationRequestsByCodBoss,
    updateVacationRequest,
  } = permitiondaysContext;

  useEffect(() => {
    async function fetchGetTypes() {
      getAllTypes();
    }
    fetchGetTypes();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getVacationRequestsByCodBoss(user.username);
    }
    fetchData();
  }, [message]);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  //modal variables
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);

  const showModalUpdateRequest = (record, action) => {
    setIsVisibleModal(true);
    //set title modal
    //set modal content, in this case, form edit

    if (action === 'review') {
      setModalTitle("Revisar solicitud");
      setModalContent(
        <FormViewPermitionDay
          record={record}
          types={allTypes}
          action={action}
          setIsVisibleModal={setIsVisibleModal}
          updateVacationRequest={updateVacationRequest}
          alert={alert}
          message={message}
          showAlert={showAlert}
        />
      );
    } else {
      setModalTitle("Actualizar solicitud");
      setModalContent(
        <FormApproveDay
          record={record}
          action={action}
          setIsVisibleModal={setIsVisibleModal}
          updateVacationRequest={updateVacationRequest}
          alert={alert}
          message={message}
          showAlert={showAlert}
        />
      );
    }
  };

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "1",
    },
    {
      title: "Nombre colaborador",
      dataIndex: "Nombre",
      key: "10",
    },
    {
      title: "Tipo",
      key: "Tipo",
      render: (text, record) => (
        <Space size="middle">
            <Tag color="blue">
              { allTypes.find(data => record.Tipo === data.id)?.description }
            </Tag>
        </Space>
      ),
    },
    {
      title: "Fecha solicitud",
      dataIndex: "FechaCreacion",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "2",
      render: (status) => (
        <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
          {status === "P"
            ? "Pendiente"
            : status === "A"
            ? "Aprobado"
            : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Desde",
      dataIndex: "FechaDesde",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Hasta",
      dataIndex: "FechaHasta",
      key: "7",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Dias",
      dataIndex: "DiasSolicitados",
      key: "8",
    },
    {
      title: "Comentario colaborador",
      dataIndex: "Comentario",
      key: "3",
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {record.Estado === "P" ? (
            <>
              <a onClick={() => showModalUpdateRequest(record, "review")}>
                Revisar
              </a>
              <a onClick={() => showModalUpdateRequest(record, "approve")}>
                Aprobar
              </a>
              <a onClick={() => showModalUpdateRequest(record, "refuse")}>
                Rechazar
              </a>
            </>
          ) : (
            <>
              <Tag color="green">Finalizada</Tag>

              <a onClick={() => showModalUpdateRequest(record, "review")}>
                  Revisar
              </a>
            </>
          )}
        </Space>
      ),
    },
  ];

  if (!vacationRequestBoss) {
    return "loading...";
  }

  return (
    <>
      {alert && (
        <Alert
          style={{ marginBottom: "10px" }}
          message={alert.msg}
          type={alert.category}
        />
      )}
      <Table dataSource={vacationRequestBoss} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="1000px"
      >
        {modalContent}
      </Modal>
    </>
  );
};

export default RequestPermitFromMyEmployees;
