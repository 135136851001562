import React, { useEffect, useContext } from "react";
import { Alert } from "antd";
import FormAttendanceDay from "./FormAttendanceDay";
import AttendanceDaysContext from "../../context/attendancedays/attendancedaysContext";
import AlertContext from "../../context/alert/alertContext";

const AdditionalDays = () => {
  const attendanceDaysContext = useContext(AttendanceDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    balanceDays,
    vacationRequestPending,
    attendanceRequests,
    getAttendanceRequestsByCodEmployee,
    loading,
    message,
    getBalanceDays,
    addAttendanceRequest,
    // getPendingVacationRequestsByCodEmployee,
    infoEmployee,
    getInfoEmployee,
  } = attendanceDaysContext;

  // useEffect(() => {
  //   async function fetchPendingVacationRequest() {
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     getPendingVacationRequestsByCodEmployee(user.username);
  //   }
  //   fetchPendingVacationRequest();
  // }, []);

  useEffect(() => {
    async function fetchData() {
      const user = JSON.parse(localStorage.getItem("user"));
      getBalanceDays(user.username);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchInfoUser() {
      const user = JSON.parse(localStorage.getItem("user"));
      getInfoEmployee(user.username);
    }
    fetchInfoUser();
  }, []);

  useEffect(() => {
    async function fetchRequestUser() {
      const user = JSON.parse(localStorage.getItem("user"));
      getAttendanceRequestsByCodEmployee(user.username);
    }
    fetchRequestUser();
  }, []);

  return (
    <>     
    { vacationRequestPending.length === 0 ? (
        <FormAttendanceDay
          balanceDays={balanceDays}
          attendanceRequests={attendanceRequests}
          loading={loading}
          message={message}
          addAttendanceRequest={addAttendanceRequest}
          alert={alert}
          showAlert={showAlert} 
          infoEmployee={infoEmployee}
        />    
        ) : (
          <Alert
            message="Solicitud pendiente"
            description="Usted tiene una solicitud pendiente, favor contactar a supervisor."
            type="info"
            showIcon
            style={{ width: "50%" }}
          />
      )} 
    </>
  );
};

export default AdditionalDays;
