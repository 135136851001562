import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import AdministrativelDaysContext from "../../../context/administrativedays/administrativedaysContext";
import AlertContext from "../../../context/alert/alertContext";
import { countDaysNotWeekend } from "../../../utils";

const TYPE_NACIMIENTO_HIJO = 1;
const TYPE_PERMISO_MEDIA_DIA = 0.5;
const TYPE_PERMISO_DIA = 1;
const DIA_ADMINISTRATIVO = 5;
const MEDIO_DIA_ADMINISTRATIVO = 6;

const initInput = {
  date: "",
  dateString: "",
  days: "",
  comment: "",
  type: "",
  NombreJefe: "",
};

const FormEditAdministrativeDays = (props) => {
  const { record, setIsVisibleModal } = props;

  const administrativelDaysContext = useContext(AdministrativelDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const {
    getTypes,
    additional,
    loading,
    message,
    types,
    getAdditionalAdminDaysByCodEmployee,
    getPendingAdminRequestsByCodEmployee,
    updAdministrativeDaysRequestRRHH,
    getAdminRequestsByRRHH
  } = administrativelDaysContext;

  const [inputs, setInputs] = useState(initInput);

  const [disabledForm, setDisabledForm] = useState(false);
  const [messageError, setMessageError] = useState("");

  const initialValues = {
    days: record.Tipo,
    commentLabel: record.Comentario,
  };

  useEffect(() => {
    form.resetFields();
    getTypes();
    getAdditionalAdminDaysByCodEmployee(record.CodigoEmpleado);
    setInputs({
      NombreJefe: record.NombreJefe,
      days: types.find((day) => day.id === record.Tipo).days,
      type: record.Tipo,
      date: moment(record.FechaDesde, "YYYY-MM-DD"),
      dateString:
        +record.DiasSolicitados > 1
          ? [
              moment(record.FechaDesde, "YYYY-MM-DD").format("DD-MM-YYYY"),
              moment(record.FechaHasta, "YYYY-MM-DD").format("DD-MM-YYYY"),
            ]
          : moment(record.FechaDesde, "YYYY-MM-DD").format("DD-MM-YYYY"),
      comment: record.Comentario,
    });
  }, [record]);

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    validateSaldo();
  }, [additional]);

  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  const onChangeTypeDays = (value) => {
    setInputs({
      ...inputs,
      days: types.find((day) => day.id === value).days,
      type: value,
    });

    if (value === DIA_ADMINISTRATIVO || value === MEDIO_DIA_ADMINISTRATIVO) {
      getAdditionalAdminDaysByCodEmployee(record.CodigoEmpleado);
      getPendingAdminRequestsByCodEmployee(record.CodigoEmpleado);
    } else {
      setDisabledForm(false);
    }
  };

  const validateSaldo = () => {
    if (
      inputs.type === DIA_ADMINISTRATIVO ||
      inputs.type === MEDIO_DIA_ADMINISTRATIVO
    ) {
      if (additional.Saldo < inputs.days) {
        setMessageError(
          "Actualmente no dispones de saldo para tomar dias administrativos"
        );
        setDisabledForm(true);
      } else {
        if (additional.payroll && !additional.payroll.enabled) {
          setMessageError(additional.payroll.message);
          setDisabledForm(true);
        } else {
          setMessageError("");
          setDisabledForm(false);
        }
      }
    }
  };

  const renderOptionsTypeDays = () => {
    return types.map((type) => (
      <Option value={type.id} key={type.id}>
        {type.description}
      </Option>
    ));
  };

  const disabledDate = (current) => {
    return moment(current).day() === 0 || moment(current).day() === 6;
  };

  

  /**
   * @author Hector Martinez
   * @description submit form
   */
  const onFinish = async () => {
    const { dateString, days, type } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays =
      typeof dateString === "string"
        ? days === TYPE_PERMISO_MEDIA_DIA
          ? TYPE_PERMISO_MEDIA_DIA
          : TYPE_PERMISO_DIA
        : countDaysNotWeekend(startDay, endDay);

    if (!type) {
      showAlert("Motivo es requerido", "warning");
      return;
    }

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    if((days > 1 && 
      (startDay.year() !== moment().year() || endDay.year() !== moment().year())) 
      || 
    (days <= 1 && 
      moment(dateString, "DD-MM-YYYY").year() !== moment().year()))
    {
      showAlert(`La fecha solicitada debe ser dentro del mismo año (${moment().year()})`, "warning");
      return;
    }

    if (diffDays < days) {
      showAlert("El rango indicado es menor a lo solicitado.", "warning");
      return;
    }

    if (diffDays > days) {
      showAlert("El rango indicado es mayor a lo solicitado.", "warning");
      return;
    }

    updAdministrativeDaysRequestRRHH({ ...inputs, id : record.Id});
    getAdminRequestsByRRHH();
    setIsVisibleModal(false);

  };

  return (
    //
    <Spin tip="Cargando..." spinning={loading}>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={initialValues}
        preserve={false}
        onFinish={onFinish}
      >
        <Form.Item label="Rut" name="rut_colaborador">
          <p style={{ margin: "auto" }}>
            {record.CodigoEmpleado ? record.CodigoEmpleado : ""}
          </p>
        </Form.Item>

        <Form.Item label="Nombre" name="nombre_colaborador">
          <p style={{ margin: "auto" }}>{record.Nombre ? record.Nombre : ""}</p>
        </Form.Item>

        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>
            {record.NombreJefe ? record.NombreJefe : ""}
          </p>
        </Form.Item>

        <Form.Item label="Motivo" name="days">
          <Select name="days" onChange={onChangeTypeDays}>
            {renderOptionsTypeDays()}
          </Select>
        </Form.Item>

        <Form.Item label="Dias a solicitar" name="dias_otorgados">
          <p style={{ margin: "auto" }}>{inputs.days}</p>
        </Form.Item>

        {(inputs.type === DIA_ADMINISTRATIVO ||
        inputs.type == MEDIO_DIA_ADMINISTRATIVO) ? (
          <Form.Item label="Nuevo Saldo" name="saldo">
            <p style={{ margin: "auto" }}>
              {record.Estado==="A"?(+additional.Saldo + + record.DiasSolicitados):(+additional.Saldo) } - {inputs.days} = 
              {record.Estado==="A"?(+additional.Saldo + + record.DiasSolicitados - inputs.days):(+additional.Saldo - inputs.days)} 
            </p>
          </Form.Item>
        ) : null}

        {messageError.length > 0 ? (
          <Alert
            message={messageError}
            type="info"
            showIcon
            style={{
              marginLeft: "10%",
              minWidth: "40%",
              maxWidth: "45%",
              marginBottom: "20px",
            }}
          />
        ) : null}

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
            {inputs.days === 1 || inputs.days === 0.5 ? (
              <DatePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
                // value={inputs.date}
                defaultValue={moment(record.FechaDesde, "YYYY-MM-DD")}
              />
            ) : (
              <RangePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
                // value={inputs.date}
                defaultValue={[
                  moment(record.FechaDesde, "YYYY-MM-DD"),
                  moment(record.FechaHasta, "YYYY-MM-DD"),
                ]}
              />
            )}
          </Space>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
            disabled={disabledForm}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            Modificar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormEditAdministrativeDays;
