import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, Row } from "antd";
import { LockOutlined } from "@ant-design/icons";
import mainLogo from "../../assets/logo/logo_crossover.png";

import Spin from "../core/Spin";
import Alert from "../core/Alert";
import AlertContext from "../../context/alert/alertContext";
import tokenAuth from "../../config/tokenAuth";
import axiosClient from "../../config/axios";

const ChangePasswordForm = (props) => {
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;
  const [id, setId] = useState(null);
  const [tokenOk, setTokenOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    newPassword: "",
    repeatPassword: "",
  });

  const [successPass, setSuccessPass] = useState(false);

  useEffect(() => {
    setSuccessPass(false);
    let url = new URL(window.location.href);
    let token = url.searchParams.get("authorization");

    async function validateToken() {
      setLoading(true);
      const data = {
        token,
      };

      try {
        const response = await axiosClient.post(
          "/api/auth/validateToken",
          data
        );

        if (response.status === 200) {
          setId(response.data.id);
          tokenAuth(token);
          setTokenOk(true);
          setLoading(false);
        }
      } catch (error) {
        setTokenOk(false);
        setLoading(false);
      }
    }

    validateToken();
  }, []);

  const { newPassword, repeatPassword } = user;

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onFinish = async () => {
    if (newPassword.trim() === "" || repeatPassword.trim() === "") {
      showAlert("Debe ingresar ambas contraseñas", "warning");
      return;
    }

    if (newPassword !== repeatPassword) {
      showAlert("Los password no son iguales", "warning");
      return;
    }

    const data = {
      newPassword,
    };

    try {
      const response = await axiosClient.put(`/api/user/${id}`, data);
      showAlert(response.data.message, "success");
      setSuccessPass(true);
    } catch (error) {
      showAlert("Su solicitud ha expirado favor solicitar nuevamente", "error");
      return;
    }
  };

  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh" }}
    >
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        style={{ width: "400px" }}
        onFinish={onFinish}
      >
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={mainLogo} alt="logo" />
        </div>
        {tokenOk && (
          <div style={{ textAlign: "center" }}>
            {successPass ? (
              <span>
                Volver al{" "}
                <a onClick={() => props.history.push("/")}>inicio de sesión</a>
              </span>
            ) : (
              <>
                <Form.Item name="newPassword">
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Nueva contraseña"
                    name="newPassword"
                    value={newPassword}
                    onChange={onChange}
                  />
                </Form.Item>
                <Form.Item name="repeatPassword">
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirmar contraseña"
                    name="repeatPassword"
                    value={repeatPassword}
                    onChange={onChange}
                  />
                </Form.Item>
              </>
            )}
            {alert && (
              <Alert
                style={{ marginBottom: "20px" }}
                message={alert.msg}
                type={alert.category}
              />
            )}

            {!successPass && (
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Actualizar
                </Button>
              </Form.Item>
            )}
          </div>
        )}
        {!tokenOk && !loading && (
          <div style={{ textAlign: "center" }}>
            <span>
              Su solicitud ha expirado, por favor vuelva a solicitar la
              recuperación de contraseña en el inicio de sesión
            </span>
            <a onClick={() => props.history.push("/")}>aquí</a>
          </div>
        )}
        {loading && <Spin />}
      </Form>
    </Row>
  );
};

export default withRouter(ChangePasswordForm);
