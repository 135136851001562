import * as types from "../../types";

export default (state, action) => {
    switch (action.type) {
      case types.SET_LOADING:
        return {
          ...state,
          loading: true,
        };
      case types.GET_TYPES_ADMINISTRATIVE_DAYS_SUCCESS:
        return {
          ...state,
          message: '',
          types: action.payload,
          loading: false,
        };
      case types.ADD_ADMINISTRATIVE_DAYS_REQUEST_RRHH:
      case types.ADD_ADMINISTRATIVE_DAYS_REQUEST:
        return {
          ...state,
          message: action.payload,
          loading: false,
        };
      case types.GET_DATA_ADMIN_REQUESTS_BY_CODEMPLOYEE:
        return {
          ...state,
          message: '',
          adminRequests: action.payload,
          loading: false,
        }
        case types.GET_DATA_ADMIN_REQUESTS_MODIFY_BY_CODEMPLOYEE:
          return {
            ...state,
            message: '',
            adminRequestsModify: action.payload,
            loading: false,
          }
      case types.GET_DATA_PENDING_ADMIN_REQUESTS_BY_CODEMPLOYEE:
        return {
          ...state,
          message: '',
          adminRequestsPending: action.payload,
          loading: false,
        }
        
      case types.GET_DATA_ADMIN_REQUESTS_BY_CODBOSS:
        return {
          ...state,
          message: '',
          adminRequestsBoss: action.payload,
          loading: false,
        }
        
      case types.GET_DATA_ADMIN_REQUESTS_MODIFY_BY_CODBOSS:
      return {
        ...state,
        message: '',
        adminRequestsModifyBoss: action.payload,
        loading: false,
      }
      case types.DELETE_ADMINISTRATIVE_DAYS_REQUEST:
      case types.UPD_ADMINISTRATIVE_DAYS_REQUEST:  
      case types.UPD_ADMINISTRATIVE_DAYS_REQUEST_RRHH:
        return {
          ...state,
          message: action.payload,
          loading: false,
        };
      case types.GET_ADMINISTRATIVE_DAYS_ADDITIONAL_REQUEST:
        return {
          ...state,
          additional: action.payload,
          loading: false,
        };
      case types.RESET_MESSAGE:
        return {
          ...state,
          message: null,
        };
      case types.ERROR_GET_DATA:
        return {
          ...state,
          message: action.payload,
          loading: false,
        };
      case types.GET_REQUEST_ADMINISTRATIVE:
        return {
          ...state,
          message: '',
          adminRequestsRRHH: action.payload,
          loading: false,
        }
      case types.GET_ADMINISTRATIVE_DAYS_HOLIDAYS:
        return {
          ...state,
          message: '',
          holidays: action.payload,
          loading: false,
        }
      case types.GET_ADMINISTRATIVE_DAYS_CONDITIONS:
        return {
          ...state,
          message: '',
          adminCondition: action.payload,
          loading: false,
        }
      case types.GET_ADMINISTRATIVE_DAYS_HISTORY:
        return {
          ...state,
          message: '',
          vacationHistory: action.payload,
          loading: false,
        }
    }
}  