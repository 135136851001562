import {
  GET_DATA_BALANCE_DAYS,
  ADD_VACATION_REQUEST,
  RESET_MESSAGE,
  ERROR_GET_DATA,
  GET_DATA_VACATION_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_PENDING_VACATION_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_VACATION_REQUESTS_BY_CODBOSS,
  UPDATE_VACATION_REQUEST,
  SET_LOADING,
  GET_REQUEST_VACATION,
  UPDATE_VACATION_REQUEST_RRHH,
  DELETE_VACATION_DAYS_REQUEST,
} from "../../types";

export default (state, action) => {
  switch (action.type) {
    case GET_DATA_BALANCE_DAYS:
      return {
        ...state,
        balanceDays: action.payload[0],
        loading: false,
      };

    case GET_DATA_VACATION_REQUESTS_BY_CODEMPLOYEE:
      return {
        ...state,
        vacationRequests: action.payload,
        loading: false,
      };
    case GET_DATA_PENDING_VACATION_REQUESTS_BY_CODEMPLOYEE:
      return {
        ...state,
        vacationRequestPending: action.payload,
        loading: false,
      };
    case GET_DATA_VACATION_REQUESTS_BY_CODBOSS:
      return {
        ...state,
        vacationRequestBoss: action.payload,
        loading: false,
      };

    case GET_REQUEST_VACATION:
      return {
        ...state,
        message: "",
        vacationRequestsRRHH: action.payload,
        loading: false,
      };

    case ADD_VACATION_REQUEST:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };

    case DELETE_VACATION_DAYS_REQUEST:
    case UPDATE_VACATION_REQUEST_RRHH:
    case UPDATE_VACATION_REQUEST:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };

    case ERROR_GET_DATA:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: null,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
