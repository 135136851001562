import React from "react";
import AdminRRHHRequest from "./adminRRHHRequest";

const FormRequest = () => {
    return (
        <AdminRRHHRequest></AdminRRHHRequest>
    );   
    
}

export default FormRequest;