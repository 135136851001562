import {
  GET_DATA_BALANCE_DAYS,
  ADD_VACATION_REQUEST,
  RESET_MESSAGE,
  ERROR_GET_DATA,
  SET_LOADING,
  GET_TYPES_PERMIT_DAYS_SUCCESS,
  GET_ALL_TYPES_PERMIT_DAYS_SUCCESS,
  GET_DATA_PERMIT_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_PERMIT_REQUESTS_MODIFY_BY_CODEMPLOYEE,
  ADD_PERMIT_DAYS_REQUEST,
  GET_DATA_PERMIT_REQUESTS_BY_CODBOSS,
  GET_DATA_PERMIT_REQUESTS_MODIFY_BY_CODBOSS,
  GET_DATA_PENDING_PERMIT_REQUESTS_BY_CODEMPLOYEE,
  UPD_PERMIT_DAYS_REQUEST,
  GET_PERMIT_DAYS_REQUEST,  
  DELETE_PERMIT_DAYS_REQUEST,
  UPDATE_PERMIT_REQUEST_RRHH,
  GET_PERMIT_DAYS_IMG,  
  GET_DATA_EMPLOYEE_INFO,
} from "../../types";

export default (state, action) => {
  switch (action.type) {

    case GET_TYPES_PERMIT_DAYS_SUCCESS:
      return {
        ...state,
        message: "",
        types: action.payload,
        loading: false,
      };
    case GET_ALL_TYPES_PERMIT_DAYS_SUCCESS:
      return {
        ...state,
        message: "",
        allTypes: action.payload,
        loading: false,
      };
  
    case GET_DATA_BALANCE_DAYS:
      return {
        ...state,
        balanceDays: action.payload[0],
        loading: false,
      };

    case GET_PERMIT_DAYS_IMG:
      return {
        ...state,
        image: action.payload,
        loading: false,
      };

    case GET_DATA_PERMIT_REQUESTS_BY_CODEMPLOYEE:
      return {
        ...state,
        vacationRequests: action.payload,
        loading: false,
      };
    case GET_DATA_PENDING_PERMIT_REQUESTS_BY_CODEMPLOYEE:
      return {
        ...state,
        vacationRequestPending: action.payload,
        loading: false,
      };
    case GET_DATA_PERMIT_REQUESTS_BY_CODBOSS:
      return {
        ...state,
        vacationRequestBoss: action.payload,
        loading: false,
      };

    case GET_PERMIT_DAYS_REQUEST:
      return {
        ...state,
        message: "",
        vacationRequestsRRHH: action.payload,
        loading: false,
      };

    case ADD_PERMIT_DAYS_REQUEST:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };

    case DELETE_PERMIT_DAYS_REQUEST:
    case UPDATE_PERMIT_REQUEST_RRHH:
    case UPD_PERMIT_DAYS_REQUEST:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };

    case ERROR_GET_DATA:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: null,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_DATA_EMPLOYEE_INFO:
      return {
        ...state,
        message: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
