export function validationEmail(email) {
  const emailValid = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const value = email;

  const resultValidation = emailValid.test(value);
  if (resultValidation) {
    return true;
  }
  return false;
}
