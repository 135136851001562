import React, { useEffect, useContext, useState } from "react";
import { Tag, Space, Alert, Table, AutoComplete } from "antd";
import moment from "moment";

import Modal from "../core/Modal";
import FormApproveDay from "../additionaldays/FormApproveDay";
import FormApproveDayModify from "../administrativedays/FormModifyDay";
import AlertContext from "../../context/alert/alertContext";
import AdministrativelDaysContext from "../../context/administrativedays/administrativedaysContext";
import FormDetailsAdministrativeDay from "./FormAdministrativeDays/FormDetailsAdministrativeDays";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSquareCheck, faSquareXmark, faTableList, faMinus} from '@fortawesome/free-solid-svg-icons'
import { CheckCircleOutlined, CloseCircleOutlined, UnorderedListOutlined, DeleteOutlined, CarryOutOutlined  } from '@ant-design/icons';


const RequestAdminFromMyEmployees = () => {
  const administrativelDaysContext = useContext(AdministrativelDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;

  const { getTypes, 
    types,  
    message,
    loading,
    additional,
    adminRequestsBoss,
    getAdminRequestsByCodBoss,
    updateAdminRequest,
    updateAdminRequestModify,
    deleteAdminRequestModify,
    // getAdminRequestsModifyByCodEmployee,
    getAdminRequestsModifyByCodBoss,
    adminRequestsModifyBoss,
  } = administrativelDaysContext;

  useEffect(() => {
    async function fetchGetTypes() {
      getTypes();
    }
    fetchGetTypes();
  }, []);

  // useEffect(() => {
  //   async function fetchDataModify() {
  //     const user = JSON.parse(localStorage.getItem("user"));
  //     getAdminRequestsModifyByCodEmployee(user.username);
  //   }
  //   fetchDataModify();
  // }, []);
  async function fetchData() {
    const user = JSON.parse(localStorage.getItem("user"));
    getAdminRequestsModifyByCodBoss(user.username);
    getAdminRequestsByCodBoss(user.username);
  }

  useEffect(() => {
    async function fetchDataModifyByCodBoss() {
      const user = JSON.parse(localStorage.getItem("user"));
      getAdminRequestsModifyByCodBoss(user.username);
    }
    fetchDataModifyByCodBoss();
  }, []);

  useEffect(() => {
    // async function fetchData() {
    //   const user = JSON.parse(localStorage.getItem("user"));
    //   getAdminRequestsByCodBoss(user.username);
    // }
    fetchData();
  }, [message]);

  useEffect(() => {
    if (message) {
      fetchData();
      showAlert(message.msg, message.category);
    }
  }, [message]);

  //modal variables
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState(null);
  const [width, setWidth] = useState();

  const showModalUpdateRequest = (record, action) => {
    setIsVisibleModal(true);
    //set title modal
    setModalTitle("Actualizar solicitud");
    //set modal content, in this case, form edit
    setModalContent(
      <FormApproveDay
        record={record}
        action={action}
        setIsVisibleModal={setIsVisibleModal}
        updateVacationRequest={updateAdminRequest}
        alert={alert}
        message={message}
        showAlert={showAlert}
      />
    );
  };

  const showModalDetailsRequestAdm = (record, recordDetails) => {
    setIsVisibleModal(true);
    //set title modal
    setModalTitle("Detalle de Solicitud");
    //set modal content, in this case, form edit
    setModalContent(
      <FormDetailsAdministrativeDay
      setIsVisibleModal={setIsVisibleModal}
      record={record}
      recordDetails = {recordDetails}
      ></FormDetailsAdministrativeDay>
    );
    setWidth(600);
  };

  const showModalUpdateRequestModify = (record, action) => {
    setIsVisibleModal(true);
    //set title modal
    setModalTitle("Actualizar solicitud");
    //set modal content, in this case, form edit
    setModalContent(
      <FormApproveDayModify
        record={record}
        action={action}
        setIsVisibleModal={setIsVisibleModal}
        updateAdminRequestModify={updateAdminRequestModify}
        alert={alert}
        message={message}
        showAlert={showAlert}
      />
    );
  };

  const showModalDeleteRequestModify = (record, action) => {
    setIsVisibleModal(true);
    //set title modal
    setModalTitle("Eliminar solicitud");
    //set modal content, in this case, form edit
    setModalContent(
      <FormApproveDayModify
        record={record}
        action={action}
        setIsVisibleModal={setIsVisibleModal}
        updateAdminRequestModify={deleteAdminRequestModify}
        alert={alert}
        message={message}
        showAlert={showAlert}
      />
    );
  };

  const marginP = { margin: 0 };

  const columns = [
    {
      title: "Tipo",
      dataIndex: "Tipo",
      key: "0",
      width: "10%",
      render: (status) => (
       <p style={marginP}> { types.find(type => type.id == status)?.description } </p>
      ), 
    },
    {
      title: "Código colaborador",
      dataIndex: "CodigoEmpleado",
      key: "1",
    },
    {
      title: "Nombre colaborador",
      dataIndex: "Nombre",
      key: "10",
    },
    {
      title: "Fecha solicitud",
      dataIndex: "FechaCreacion",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Estado",
      dataIndex: "Estado",
      key: "2",
      render: (status, record) => (
        <Tag color={adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.Estado === "P" ? "blue" :
        status === "P"  ? "blue" :
        status === "A"  ? "green" : "red"}>
        {adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.Estado === "P" ? 
        (adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.TipoSolicitud === "PM" ? "Pend. Modificacion" :
        adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.TipoSolicitud === "PD" ? "Pend. Eliminacion" : "Pendiente")
        :
        status === "P"
        ? "Pendiente"
        : status === "A"
        ? "Aprobado"
        : "Rechazado"}
        </Tag>
      ),
    },
    {
      title: "Desde",
      dataIndex: "FechaDesde",
      key: "6",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Hasta",
      dataIndex: "FechaHasta",
      key: "7",
      render: (text) => (
        <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "Dias",
      dataIndex: "DiasSolicitados",
      key: "8",
    },
    {
      title: "Comentario colaborador",
      dataIndex: "Comentario",
      key: "3",
      render: (text, record) => (
        <Space size="middle">
          {
            adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.Estado === "P" ?
            (
              <>
                <p>{adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin && data.Estado==="P")?.Comentario}</p>
              </>
            )
            :
            (
              <>
                <p>{text}</p>
              </>
            )
          }
        </Space>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {
          adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.Estado === "P" ?
          (adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.TipoSolicitud === "PM") ?
          (
            <>
              <a>
                <div className="app" style={{fontSize:"1.5em", color:"green"}}>
                  <CheckCircleOutlined title="Aprobar" 
                    onClick={() => showModalUpdateRequestModify(record, "approve")}>
                  </CheckCircleOutlined>
                </div>
              </a>
              <a>  
                <div className="app" style={{fontSize:"1.5em", color:"red"}}>
                  <CloseCircleOutlined title="Rechazar"
                    onClick={() => showModalUpdateRequestModify(record, "refuse")}>
                  </CloseCircleOutlined>
                </div>
              </a>
            </>
          ) :
          (
            <>
              <a>
                <div className="app" style={{fontSize:"1.5em", color:"green"}}>
                <CheckCircleOutlined title="Aprobar" 
                    onClick={() => showModalDeleteRequestModify(record, "approve")}/>
                </div>
              </a>
              <a>  
                <div className="app" style={{fontSize:"1.5em", color:"red"}}>
                  <CloseCircleOutlined title="Rechazar"
                    onClick={() => showModalDeleteRequestModify(record, "refuse")}>
                  </CloseCircleOutlined>
                </div>
              </a>
            </>
          )
          :
          record.Estado === "P" ? (
            <>
              <a>
                <div className="app" style={{fontSize:"1.5em", color:"green"}}>
                  <CheckCircleOutlined title="Aprobar" 
                    onClick={() => showModalUpdateRequest(record, "approve")}>
                  </CheckCircleOutlined>
                </div>
              </a>
              <a>  
                <div className="app" style={{fontSize:"1.5em", color:"red"}}>
                  <CloseCircleOutlined title="Rechazar"
                    onClick={() => showModalUpdateRequest(record, "refuse")}>
                  </CloseCircleOutlined>
                </div>
              </a>
            </>
          ) : (
            <>
              <div className="app" style={{fontSize:"2em", color:"black"}}>
                <CarryOutOutlined title="Finalizada">
                </CarryOutOutlined>
              </div>
            </>
          )}
        </Space>
      ),
    },
    {
      title: "Detalle",
      key: "detalle",
      render: (text, record) => (
        
        <Space size="middle" align="center">
          {
            
          adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.Estado === "P" ?
              (adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin)?.TipoSolicitud === "PM") ?
              (
                <>
                  <a>
                    <div className="app" style={{fontSize:"2em", color:"black"}}>
                      <UnorderedListOutlined title="Detalle" 
                        onClick={() => showModalDetailsRequestAdm(record,adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin && data.Estado==="P"))}>
                      </UnorderedListOutlined>
                    </div>
                  </a>
                </>
              ):
              (
                <>
                  <div className="app" style={{fontSize:"2em", color:"black"}}>
                    <DeleteOutlined title="Pendiente eliminación">
                    </DeleteOutlined>
                  </div>
                </>
              )
            :
            (
              <>
                <a>
                  <div className="app" style={{fontSize:"2em", color:"black"}}>
                    <UnorderedListOutlined title="Aprobar" 
                      onClick={() => showModalDetailsRequestAdm(record,adminRequestsModifyBoss.find(data => record.Id === data.IdSolicitudAdmin && data.Estado==="P"))}>
                    </UnorderedListOutlined>
                  </div>
                </a>
              </>
            )
          }
        </Space>
      ),
    },
  ];

  if (!adminRequestsBoss) {
    return "loading...";
  }

  return (
    <>
      {alert && (
        <Alert
          style={{ marginBottom: "10px" }}
          message={alert.msg}
          type={alert.category}
        />
      )}
      <Table dataSource={adminRequestsBoss} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={width}
      >
        {modalContent}
      </Modal>
    </>
  );
};

export default RequestAdminFromMyEmployees;
