import React, { useContext } from "react";
import { Modal } from "antd";

import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import FilesContext from "../../../context/files/filesContext";

const { Dragger } = Upload;

const ModalUploadFile = ({ visible, handleCloseModal }) => {
  
  const filesContext = useContext(FilesContext);
  const { getFiles } = filesContext;

  const user = JSON.parse(localStorage.getItem("user"));
  console.log("user", user);

  const props = {
    name: "file",
    multiple: false,
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    action: `${process.env.REACT_APP_BACKEND_URL}/api/file/upload`,
    data: {
      userName : `${user.name} ${user.lastName}`,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        getFiles();
      }
      if (status === "done") {
        message.success(`${info.file.name} Cargado correctamente.`);
      } else if (status === "error") {
        message.error(`${info.file.name} Error.`);
      }
    },
  };

  const OkGetFiles = () => {
    getFiles();
    handleCloseModal();
  }

  return (
    <Modal
      key="4"
      visible={visible}
      title="Cargar archivo"
      onCancel={handleCloseModal}
      onOk={OkGetFiles}
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Haga clic o arrastre el archivo a esta área para cargar
        </p>
        <p className="ant-upload-hint">
          Soporte para una carga única de archivos con extensión xlsx
        </p>
      </Dragger>
      ,
    </Modal>
  );
};

export default ModalUploadFile;
