import React, { useContext, useEffect } from "react";
import { Tag, Table, Space } from "antd";
import moment from "moment";
import FilesContext from "../../../context/files/filesContext";
import Text from "antd/lib/typography/Text";

const TableManualUploadFiles = () => {
  const STATUS_FILE = {
    PENDIENTE: "Pendiente",
    EJECUTANDO: "Ejecutando",
    FINALIZADO: "Finalizado",
    ERROR: "Error",
  };

  const filesContext = useContext(FilesContext);

  const { loading, files, getFiles } = filesContext;

  useEffect(() => {
    console.log("useEffect getFiles", files);
    getFiles();
  }, []);

  const marginP = { margin: 0 };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "0",
      width: "10%",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "1",
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "2",
      render: (text) => (
        <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "usuario",
      dataIndex: "codigoEmpleado",
      key: "3",
      render: (text) => (
        <Text style={{textTransform: 'capitalize'}}>{text}</Text>
      ),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "4",
      render: (status) => (
        <Tag
          color={
            status.trim() === "P"
              ? "blue"
              : status.trim() === "E"
              ? "gold"
              : status.trim() === "F"
              ? "green"
              : "red"
          }
        >
          {status.trim() === "P"
            ? STATUS_FILE.PENDIENTE
            : status.trim() === "E"
            ? STATUS_FILE.EJECUTANDO
            : status.trim() === "F"
            ? STATUS_FILE.FINALIZADO
            : "Error"}
        </Tag>
      ),
    },
    {
      title: "Acciones",
      key: "action",
      render: (text, data) => (
        <>
          <Space size="large">
            <>
              <a style={marginP} href={"archivos-detalle/" + data.id}>
                Ver
              </a>
              <a
                href={`${process.env.REACT_APP_BACKEND_URL}/api/file/${data.id}/download`}
              >
                Descargar
              </a>
            </>
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        key="3"
        dataSource={files}
        columns={columns}
        loading={loading}
        scroll={{ x: "auto" }}
      />
    </>
  );
};

export default TableManualUploadFiles;
