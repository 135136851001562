import React, { useReducer } from "react";
import attendancedaysReducer from "./attendancedaysReducer";
import attendancedaysContext from "./attendancedaysContext";
import {
  GET_DATA_BALANCE_DAYS,
  ADD_ATTENDANCE_REQUEST,
  RESET_MESSAGE,
  GET_DATA_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_PENDING_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE,
  GET_DATA_ATTENDANCE_REQUESTS_BY_CODBOSS,
  ERROR_GET_DATA,
  SET_LOADING,
  GET_REQUEST_ATTENDANCE,
  DELETE_ATTENDANCE_DAYS_REQUEST,
  // UPDATE_VACATION_REQUEST_RRHH,
  UPDATE_ATTENDANCE_REQUEST,
  GET_DATA_EMPLOYEE_INFO,
  GET_DATA_ATTENDANCE_REQUESTS_BY_RRHH,
  UPDATE_ATTENDANCE_REQUEST_RRHH,
} from "../../types";
import axiosClient from "../../config/axios";
import moment from "moment";

const AttendanceDaysDataState = (props) => {
  const initialState = {
    balanceDays: [],
    loading: true,
    message: null,
    vacationRequests: [],
    vacationRequestBoss: [],
    vacationRequestPending: [],
    attendanceRequestPending: [],
    attendanceRequestsRRHH: [],
    infoEmployee: null,
  };

  const [state, dispatch] = useReducer(attendancedaysReducer, initialState);

  const user = JSON.parse(localStorage.getItem("user"));

  const getBalanceDays = async (rut) => {
    try {
      const response = await axiosClient.get(
        `/api/employee/${rut}/balancedays`
      );

      dispatch({
        type: GET_DATA_BALANCE_DAYS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const getInfoEmployee = async (rut) => {
    try {
      const response = await axiosClient.get(
        `/api/employee/${rut}/info`
      );

      dispatch({
        type: GET_DATA_EMPLOYEE_INFO,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: ERROR_GET_DATA,
        payload: error,
      });
    }
  };

  const addAttendanceRequest = async (attendanceRequest) => {
    dispatch({
      type: SET_LOADING,
    });

    try {
      const names = state.balanceDays.Nombres;

      const firstName = names.split(" ");

      const fullName = `${firstName} ${state.balanceDays.Apaterno}`;

      const data = {
        codigo: state.balanceDays.Codigo,
        rut: user.username,
        nombre: fullName,
        mail: user.email,
        fechaMarcacion: moment(attendanceRequest.dateString, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD"),
        horaMarcacion: moment(attendanceRequest.dateString, "DD-MM-YYYY HH:mm").format("HH:mm"),
        comentario: attendanceRequest.comment,
        codigoJefe: state.balanceDays.codigoJefe,
        nombreJefe: state.balanceDays.NombreJefe,
        mailJefe: state.balanceDays.CorreoJefe,
        tipo: attendanceRequest.tipo,
      };

      const response = await axiosClient.post(`/api/attendance/days`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };

      dispatch({
        type: ADD_ATTENDANCE_REQUEST,
        payload: alert,
      });

      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
        window.location.reload();
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const addAttendanceRequestRRHH = async (attendanceRequest) => {
    dispatch({
      type: SET_LOADING,
    });

    try {
      const {Codigo, Nombres, Apaterno, Mail, Rut, codigoJefe, NombreJefe, CorreoJefe} = state.balanceDays

      const firstName = Nombres.split(" ");

      const fullName = `${firstName} ${Apaterno}`;

      const data = {
        codigo: Codigo,
        rut: Rut,
        nombre: fullName,
        mail: Mail,
        fechaMarcacion: moment(attendanceRequest.dateString, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD"),
        horaMarcacion: moment(attendanceRequest.dateString, "DD-MM-YYYY HH:mm").format("HH:mm"),
        comentario: attendanceRequest.comment,
        codigoJefe: codigoJefe,
        nombreJefe: NombreJefe,
        mailJefe: CorreoJefe,
        tipo: attendanceRequest.tipo,
      };

      const response = await axiosClient.post(`/api/attendance/rrhh/days`, data);

      const alert = {
        msg: response.data.message,
        category: "success",
      };

      dispatch({
        type: ADD_ATTENDANCE_REQUEST,
        payload: alert,
      });

      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
        window.location.reload();
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getAttendanceRRHH = async (query) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.get(`/api/attendance/rrhh/days/${query}`
      );

      dispatch({
        type: GET_DATA_ATTENDANCE_REQUESTS_BY_RRHH,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getAttendanceRequestsByCodEmployee = async (codEmployee) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.get(
        `api/attendance/days/employee/${codEmployee}`
      );

      dispatch({
        type: GET_DATA_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getPendingAttendanceRequestsByCodEmployee = async (codEmployee) => {
    try {
      const response = await axiosClient.get(
        `/api/attendance/days/employee/:codEmployee/pending/${codEmployee}`
      );

      dispatch({
        type: GET_DATA_PENDING_ATTENDANCE_REQUESTS_BY_CODEMPLOYEE,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getAttendanceRequestsByCodBoss = async (codBoss) => {
    dispatch({
      type: SET_LOADING,
    });

    const codBossFormat = codBoss.split("-");

    try {
      const response = await axiosClient.get(
        `/api/attendance/days/boss/${codBossFormat[0]}`
      );

      dispatch({
        type: GET_DATA_ATTENDANCE_REQUESTS_BY_CODBOSS,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateAttendanceRequest = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.put(`api/attendance/days/${id}`, data);
      console.log(response.data.message)
      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPDATE_ATTENDANCE_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const getAttendanceRequestsByRRHH = async (query = '') => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const url = query.length === 0 ? '/api/attendance/rrhh/days' : `/api/attendance/rrhh/days?query=${query}`
      const response = await axiosClient.get(url);
      dispatch({
        type: GET_REQUEST_ATTENDANCE,
        payload: response.data.data,
      });
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const deleteAttendanceRequest = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const response = await axiosClient.delete(
        `/api/attendance/rrhh/days/${id}`
      );

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: DELETE_ATTENDANCE_DAYS_REQUEST,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  const updateAttendanceRequestRRHH = async (id, data) => {
    dispatch({
      type: SET_LOADING,
    });
    try {
      const params = {
        fechaMarcacion: moment(data.dateString, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD"),
        horaMarcacion: moment(data.dateString, "DD-MM-YYYY HH:mm").format("HH:mm"),
        comentario: data.comment,
        tipo: data.tipo,
      };
      const response = await axiosClient.put(
        `api/attendance/rrhh/days/${id}`,
        params
      );

      const alert = {
        msg: response.data.message,
        category: "success",
      };
      dispatch({
        type: UPDATE_ATTENDANCE_REQUEST_RRHH,
        payload: alert,
      });
      setTimeout(() => {
        dispatch({
          type: RESET_MESSAGE,
        });
      }, 5000);
    } catch (error) {
      const alert = {
        msg: error.response.data.message,
        category: "error",
      };
      dispatch({
        type: ERROR_GET_DATA,
        payload: alert,
      });
    }
  };

  return (
    <attendancedaysContext.Provider
      value={{
        balanceDays: state.balanceDays,
        attendanceRequests: state.attendanceRequests,
        loading: state.loading,
        message: state.message,
        vacationRequests: state.attendanceRequestsRRHH,
        vacationRequestBoss: state.vacationRequestBoss,
        vacationRequestPending: state.vacationRequestPending,
        attendanceRequestsRRHH: state.attendanceRequestsRRHH,
        attendanceRequestPending: state.vacationRequestPending,
        infoEmployee: state.infoEmployee,
        getBalanceDays,
        addAttendanceRequest,
        addAttendanceRequestRRHH,
        getAttendanceRRHH,
        getAttendanceRequestsByCodEmployee,
        getPendingAttendanceRequestsByCodEmployee,
        getAttendanceRequestsByCodBoss,
        getAttendanceRequestsByRRHH,
        updateAttendanceRequest,
        deleteAttendanceRequest,
        updateAttendanceRequestRRHH,
        getInfoEmployee,
      }}
    >
      {props.children}
    </attendancedaysContext.Provider>
  );
};

export default AttendanceDaysDataState;
