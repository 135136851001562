import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
} from "antd";

import LegalVacationDaysContext from "../../../context/legalvacationdays/legalvacationdaysContext";
import AlertContext from "../../../context/alert/alertContext";
import esES from "antd/lib/locale-provider/es_ES";
import { countDaysNotWeekend } from "../../../utils";

const FormEditLegalVacationDay = (props) => {
  const { record, setIsVisibleModal } = props;
  const legalvacationdaysContext = useContext(LegalVacationDaysContext);
  const alertContext = useContext(AlertContext);
  const { alert, showAlert } = alertContext;
  const {
    balanceDays,
    loading,
    getBalanceDays,
    updateLegalVacationRequestRRHH
  } = legalvacationdaysContext;
  
  const [inputs, setInputs] = useState({
    date: "",
    dateString: "",
    days: 1,
    comment: "",
  });

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const initialValues = {
    commentLabel: record.Comentario,
    days: record.DiasSolicitados
  };

  useEffect(() => {
    form.resetFields();
    getBalanceDays(record.CodigoEmpleado);

    setInputs({
        NombreJefe: record.NombreJefe,
        days: record.DiasSolicitados,
        date: moment(record.FechaDesde, "YYYY-MM-DD"),
        dateString:
          +record.DiasSolicitados > 1
            ? [
                moment(record.FechaDesde, "YYYY-MM-DD").format("DD-MM-YYYY"),
                moment(record.FechaHasta, "YYYY-MM-DD").format("DD-MM-YYYY"),
              ]
            : moment(record.FechaDesde, "YYYY-MM-DD").format("DD-MM-YYYY"),
        comment: record.Comentario,
      });


  }, [record]);

  const { Saldo = 0, NombreJefe = '',  DiasOtorgados = '' } = balanceDays ?? {};

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  const onChangeCountDays = (value) => {
    setInputs({ ...inputs, days: value });
  };

  const renderOptionsBalance = (balance) => {
    let array = [];
    for (var i = 1; i <= balance; i++) {
      array.push(i);
    }

    return array.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => (current && current > moment().subtract("1", "d").endOf("day")) || moment(current).day() === 0 || moment(current).day() === 6;

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  const onFinish = async () => {
    const { dateString, days } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays = countDaysNotWeekend(startDay,endDay);

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    if (diffDays < days && days !== 1) {
      showAlert("El rango indicado es menor a lo solicitado.", "warning");
      return;
    }

    if (diffDays > days && days !== 1) {
      showAlert("El rango indicado es mayor a lo solicitado.", "warning");
      return;
    }

    await updateLegalVacationRequestRRHH(record.Id, inputs)
    form.resetFields();
    setIsVisibleModal(false);
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
      >

        <Form.Item label="Rut" name="rut_colaborador">
          <p style={{ margin: "auto" }}>
            {record.CodigoEmpleado ? record.CodigoEmpleado : ""}
          </p>
        </Form.Item>

        <Form.Item label="Nombre" name="nombre_colaborador">
          <p style={{ margin: "auto" }}>{record.Nombre ? record.Nombre : ""}</p>
        </Form.Item>

        {/* <Form.Item label="Dias otorgados" name="dias_otorgados">
          <p style={{ margin: "auto" }}>
            {DiasOtorgados ? DiasOtorgados : "0"}
          </p>
        </Form.Item>

        <Form.Item label="Saldo de dias" name="saldo_dias">
          <p style={{ margin: "auto" }}>{Saldo ? Saldo : 0}</p>
        </Form.Item> */}

        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{record.NombreJefe ? record.NombreJefe : ""}</p>
        </Form.Item>

        <Form.Item label="Dias a solicitar" name="days">
          <Select
            defaultValue={1}
            name="days"
            onChange={onChangeCountDays}
          >
            {renderOptionsBalance(Saldo + +record.DiasSolicitados)}
          </Select>
        </Form.Item>

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
            {inputs.days === 1 ? (
              <DatePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                onChange={onChangeDate}
                locale={esES}
                defaultValue={moment(record.FechaDesde, "YYYY-MM-DD")}
              />
            ) : (
              <RangePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                disabledTime={disabledDateTime}
                onChange={onChangeDate}
                locale={esES}
                defaultValue={[
                    moment(record.FechaDesde, "YYYY-MM-DD"),
                    moment(record.FechaHasta, "YYYY-MM-DD"),
                ]}
              />
            )}
          </Space>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" >
            Modificar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormEditLegalVacationDay;
