import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
} from "antd";

import AttendancedaysContext from "../../../context/attendancedays/attendancedaysContext";
import AlertContext from "../../../context/alert/alertContext";
import esES from "antd/lib/locale-provider/es_ES";
import { countDaysNotWeekend } from "../../../utils";

const FormEditAttendanceDay = (props) => {
  const { record, setIsVisibleModal } = props;
  const attendancedaysContext = useContext(AttendancedaysContext);
  const alertContext = useContext(AlertContext);
  let disabledForm = false;
  const { alert, showAlert } = alertContext;
  const tipoOptions = ['Entrada', 'Salida'];
  const {
    balanceDays,
    loading,
    getBalanceDays,
    updateAttendanceRequestRRHH,
    attendanceRequests
  } = attendancedaysContext;
  
  const [inputs, setInputs] = useState({
    date: "",
    dateString: "",
    days: 1,
    comment: "",
  });

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };

  const initialValues = {
    commentLabel: record.Comentario,
  };

  useEffect(() => {
    form.resetFields();
    getBalanceDays(record.CodigoEmpleado);
    setInputs({
        NombreJefe: record.NombreJefe,
        date: moment(record.FechaMarcacion, "YYYY-MM-DD HH:mm"),
        dateString:moment(record.FechaMarcacion, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY HH:mm"),
        comment: record.Comentario,
      });


  }, [record]);

  const { NombreJefe = '' } = balanceDays ?? {};


  const renderOptionsType = () => {
    return tipoOptions.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };

  const onChangeType = (value) => {
    setInputs({ ...inputs, tipo: value });
    // if (attendanceRequests.length > 0 && attendanceRequests[0].Tipo == value) {
    //   showAlert("Revisa tipo de marcacion", "warning");
    // }
    // if (attendanceRequests.length == 0 && value == 'Salida') {
    //   showAlert("si no tienes marcaciones, debes ingresar una entrada", "warning");
    // }
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  // const onChangeTypeDays = (value) => {
  //   setInputs({
  //     ...inputs,
  //     days: types.find((day) => day.id === value).days,
  //     type: value,
  //   });

  //   if (value === DIA_ADMINISTRATIVO || value === MEDIO_DIA_ADMINISTRATIVO) {
  //     getAdditionalAdminDaysByCodEmployee(record.CodigoEmpleado);
  //     getPendingAdminRequestsByCodEmployee(record.CodigoEmpleado);
  //   } else {
  //     setDisabledForm(false);
  //   }
  // };

  const onChangeCountDays = (value) => {
    setInputs({ ...inputs, days: value });
  };

  const renderOptionsBalance = (balance) => {
    let array = [];
    for (var i = 1; i <= balance; i++) {
      array.push(i);
    }

    return array.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) =>  moment(current).day() === 0 || moment(current).day() === 6;

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  const onFinish = async () => {
    const { dateString } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays = countDaysNotWeekend(startDay,endDay);

    if (!dateString) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    if (attendanceRequests) {
      if (
        attendanceRequests.length > 0 &&
        attendanceRequests[0].Tipo == inputs.tipo
      ) {
        showAlert("Revisa tipo de marcacion", "warning");
        return;
      } else if (
        attendanceRequests.length > 0 &&
        attendanceRequests[0].Tipo != inputs.tipo
      ) {
        let date0 = moment(dateString, "DD-MM-YYYY HH:mm");
        let dateNew = moment(
          attendanceRequests[0].FechaMarcacion +
            " " +
            attendanceRequests[0].HoraMarcacion,
          "YYYY-MM-DD HH:mm"
        );
        let isBefore = dateNew.isBefore(date0);
        if (!isBefore) {
          showAlert(
            "Revisa fecha y hora de marcacion, segun ultimo registro",
            "warning"
          );
          return;
        }
      } else if (attendanceRequests.length == 0 && inputs.tipo == "Salida") {
        showAlert(
          "si no tienes marcaciones, debes ingresar una entrada",
          "warning"
        );
        return;
      }
    }

    await updateAttendanceRequestRRHH(record.Id, inputs)
    form.resetFields();
    setIsVisibleModal(false);
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>

      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        {disabledForm && (
              <Alert
                message="Actualmente no dispones de esta funcionalidad"
                type="info"
                showIcon
                style={{ marginLeft: "10%", minWidth: "40%", maxWidth:"45%", marginBottom: "20px" }}
              />
        )}

        <Form.Item label="Rut" name="rut_colaborador">
          <p style={{ margin: "auto" }}>
            {record.CodigoEmpleado ? record.CodigoEmpleado : ""}
          </p>
        </Form.Item>

        <Form.Item label="Nombre" name="nombre_colaborador">
          <p style={{ margin: "auto" }}>{record.Nombre ? record.Nombre : ""}</p>
        </Form.Item>

        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{NombreJefe ? NombreJefe : ""}</p>
        </Form.Item>

        <Form.Item label="Marcacion" name="types">
          <Select
            value={{value: tipoOptions[0], label: tipoOptions[0]}}
            defaultValue={record.Tipo}
            name="tipo"
            onChange={onChangeType}
            disabled={disabledForm}
          >
            {renderOptionsType()}
          </Select>
        </Form.Item>

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
              <DatePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY HH:mm"
                showTime={true}
                disabledDate={disabledDate}
                disabledTime={false}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
                defaultValue={moment(record.FechaMarcacion + record.HoraMarcacion, "YYYY-MM-DD HH:mm")}
              />
          </Space>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
            disabled={disabledForm}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            Solicitar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormEditAttendanceDay;
