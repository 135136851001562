import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Space,
  Spin,
  Alert,
} from "antd";

import esES from "antd/lib/locale-provider/es_ES";
import "moment/locale/es";
import { countDaysNotWeekend } from "../../../utils";

const FormAttendanceDays = (props) => {

  let disabledForm = false;

  const {
    balanceDays,
    attendanceRequests,
    loading,
    message,
    alert,
    showAlert,
    addAttendanceRequest,
    infoEmployee,
  } = props;
  
  const tipoOptions = ['Entrada', 'Salida'];

  useEffect(() => {
    if (message) {
      showAlert(message.msg, message.category);
    }
  }, [message]);

  useEffect(() => {
    if(!loading){
      setInfoBalance();
    }
  }, [balanceDays, loading]);

  useEffect(() => {
    if(!loading){
      setInfoEmployee();
    }
  }, [infoEmployee, loading]);

  useEffect(() => {
    if (attendanceRequests) {
      if (attendanceRequests.length > 0 && attendanceRequests[0].Tipo == 'Entrada') {
        showAlert("Ultimo Registro es Entrada. Debe marcar Salida", "warning");
      }
      disabledForm = false;
    }
  }, [attendanceRequests]);

  const [inputs, setInputs] = useState({
    date: "",
    dateString: "",
    days: 1,
    comment: "",
    tipo: tipoOptions[0],
  });

  const [form] = Form.useForm();

  const { TextArea } = Input;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 6 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 6 },
  };

  const onChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const setInfoBalance = () =>{
    const { NombreJefe } = balanceDays;
    setInputs({
      ...inputs,
      NombreJefe
    })
  }

  const setInfoEmployee = () =>{
    const { Cod_Tipo_Documento } = infoEmployee ?? '1';
    setInputs({
      ...inputs,
      Cod_Tipo_Documento
    })
  };

  const renderOptionsType = () => {
    return tipoOptions.map((value) => (
      <Option value={value} key={value}>
        {value}
      </Option>
    ));
  };
  
  const onChangeType = (value) => {
    setInputs({ ...inputs, tipo: value });
    if (attendanceRequests.length > 0 && attendanceRequests[0].Tipo == value) {
      showAlert("Revisa tipo de marcacion", "warning");
    }
    if (attendanceRequests.length == 0 && value == 'Salida') {
      showAlert("si no tienes marcaciones, debes ingresar una entrada", "warning");
    }
  };

  const onChangeDate = (date, dateString) => {
    setInputs({ ...inputs, dateString: dateString, date: date });
  };

  const onChangeCountDays = (value) => {
    setInputs({ ...inputs, days: value });
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current) => {
    return (
      (current > moment().endOf("day")) || moment(current).day() === 0 || moment(current).day() === 6
    );
  };

  const disabledDateTime = () => {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  };

  const onFinish = async () => {
    const { dateString, days } = inputs;
    const startDay = moment(dateString[0], "DD-MM-YYYY");
    const endDay = moment(dateString[1], "DD-MM-YYYY");

    let diffDays = countDaysNotWeekend(startDay,endDay);

    if (!dateString || !days) {
      showAlert("La fecha es requerida", "warning");
      return;
    }

    if (attendanceRequests) {
      if (attendanceRequests.length > 0 && attendanceRequests[0].Tipo == inputs.tipo) {
        showAlert("Revisa tipo de marcacion", "warning");
        return;
      }
      else if (attendanceRequests.length > 0 && attendanceRequests[0].Tipo != inputs.tipo) {
        let date0 = moment(dateString, "DD-MM-YYYY HH:mm");
        let dateNew = moment(attendanceRequests[0].FechaMarcacion+' '+attendanceRequests[0].HoraMarcacion, "YYYY-MM-DD HH:mm");
        let isBefore = dateNew.isBefore(date0);
        if (!isBefore) {
          showAlert("Revisa fecha y hora de marcacion, segun ultimo registro", "warning");
          return;
        }
      }
      else if (attendanceRequests.length == 0 && inputs.tipo == 'Salida') {
        showAlert("si no tienes marcaciones, debes ingresar una entrada", "warning");
        return;
      }
    }

    await addAttendanceRequest(inputs);

    form.resetFields();
  };

  const { Saldo = 0, NombreJefe = '',  DiasOtorgados = '' } = balanceDays ?? {};

  const { Cod_Tipo_Documento } = infoEmployee ?? {};
  
  if (Cod_Tipo_Documento == '1') {
    disabledForm = true;
  }

  return (
    <Spin tip="Cargando..." spinning={loading}>

      <Form
        {...layout}
        form={form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item label="Jefe a cargo" name="jefe_cargo">
          <p style={{ margin: "auto" }}>{NombreJefe ? NombreJefe : ""}</p>
        </Form.Item>

        <Form.Item label="Marcacion" name="types">
          <Select
            value={{value: tipoOptions[0], label: tipoOptions[0]}}
            defaultValue={tipoOptions[0]}
            name="tipo"
            onChange={onChangeType}
            disabled={disabledForm}
          >
            {renderOptionsType()}
          </Select>
        </Form.Item>

        <Form.Item label="Seleccionar dia" name="date">
          <Space direction="vertical">
              <DatePicker
                name="date"
                style={{ width: "263px" }}
                format="DD-MM-YYYY HH:mm"
                showTime={true}
                disabledDate={disabledDate}
                disabledTime={false}
                onChange={onChangeDate}
                disabled={disabledForm}
                locale={esES}
              />
          </Space>
        </Form.Item>

        <Form.Item label="Comentario" name="commentLabel">
          <TextArea
            name="comment"
            rows={4}
            onChange={onChange}
            disabled={disabledForm}
          />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" disabled={disabledForm}>
            Solicitar
          </Button>
        </Form.Item>
        <Form.Item {...tailLayout}>
          {alert && <Alert message={alert.msg} type={alert.category} />}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default FormAttendanceDays;
