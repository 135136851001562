import React, { useContext, useEffect, useState } from "react";
import { Tag, Space, Table, Modal as ModalAntd } from "antd";
import Modal from "../core/Modal";
import moment from "moment";

import { ExclamationCircleOutlined } from '@ant-design/icons';
import AdditionaldaysContext from "../../context/additionaldays/additionaldaysContext";
import FormEditAdditionalDay from "../additionaldays/FormAditionalDay/FormEditAdditionalDay";
import LegalVacationDaysContext from "../../context/legalvacationdays/legalvacationdaysContext";
import Search from "antd/lib/input/Search";
import FormEditLegalVacationDay from "../legalvacationdays/FormLegalVacationDays/FormEditLegalVacationDays";
import FormApproveDayRevision from "../legalvacationdays/FormApproveDay/FormApproveDayRevision";
import AlertContext from "../../context/alert/alertContext";

const LegalVacationsGeneralRequest = () => {

    const additionaldaysContext = useContext(AdditionaldaysContext);
    const legalvacationdaysContext = useContext(LegalVacationDaysContext);
    const alertContext = useContext(AlertContext);

    const { alert, showAlert } = alertContext;
    
    const {
      loading,
      message,
      legalvacationRequestsRRHH,
      legalVacationRequestAprovedCount,
      getLegalVacationRRHH,
      deleteLegalVacationRequest,
      getLegalVacationRequestAprovedCount,
      updateLegalVacationRequestRevision
    } = legalvacationdaysContext;

    useEffect(() => {
        getLegalVacationRequestAprovedCount();
        getLegalVacationRRHH();
    }, [message]);

    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalContent, setModalContent] = useState(null);
    const [width, setWidth] = useState();
  
    const showModalUpdateRequest = (record, action) => {
      
      
      setIsVisibleModal(true);
      //set title modal
      setModalTitle("Actualizar solicitud");
      //set modal content, in this case, form edit
      setModalContent(
        <FormApproveDayRevision
          record={record}
          action={action}
          setIsVisibleModal={setIsVisibleModal}
          updateLegalVacationRequestRevision={updateLegalVacationRequestRevision}
          alert={alert}
          message={message}
          showAlert={showAlert}
        />
      );
  
    };

    const marginP = { margin: 0 };

    const columns = [     
      {
        title: "Código colaborador",
        dataIndex: "CodigoEmpleado",
        key: "rrhhCodigoEmpleado",
        filters: [
          ...Array.from(new Set(legalvacationRequestsRRHH.map(x => x.CodigoEmpleado))).map(x => { return { 'text': x, 'value': x }})         
        ],
        onFilter: (value, record) => record.CodigoEmpleado.indexOf(value) === 0,
      },
      {
        title: "Nombre colaborador",
        dataIndex: "Nombre",
        key: "2",
        filters: [
          ...Array.from(new Set(legalvacationRequestsRRHH.map(x => x.Nombre))).map(x => { return { 'text': x, 'value': x }})         
        ],
        onFilter: (value, record) => record.Nombre.indexOf(value) === 0,
      },
      {
        title: "Origen",
        dataIndex: "Origen",
        key: "Origen",
        render: (status) => (
          <Tag color={status && status === "WEB" ? "blue" : "green"}>
            {status ? status : 'PAYROLL'}
          </Tag>
        ),
      },
      // {
      //   title: "Fecha solicitud",
      //   dataIndex: "FechaCreacion",
      //   key: "3",
      //   render: (text) => (
      //     <p style={marginP}>{moment(text).format("DD-MM-YYYY")}</p>
      //   ),
      // },
      {
        title: "Estado Jefatura",
        dataIndex: "Estado",
        key: "4",
        render: (status) => (
          <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
            {status === "P"
              ? "Pendiente"
              : status === "A"
              ? "Aprobado"
              : "Rechazado"}
          </Tag>
        ),
      },
      {
        title: "Estado de Revision",
        dataIndex: "EstadoRevision",
        key: "4",
        render: (status) => (
          <Tag color={status === "P" ? "blue" : status === "A" ? "green" : "red"}>
            {status === "P"
              ? "Pendiente"
              : status === "A"
              ? "Aprobado"
              : "Rechazado"}
          </Tag>
        ),
      },
      {
        title: "Desde",
        dataIndex: "FechaDesde",
        key: "5",
        render: (text) => (
          <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
        ),
      },
      {
        title: "Hasta",
        dataIndex: "FechaHasta",
        key: "6",
        render: (text) => (
          <p style={marginP}>{moment.utc(text).format("DD-MM-YYYY")}</p>
        ),
      },
      {
        title: "Dias Solicitados",
        dataIndex: "DiasSolicitados",
        key: "7",
      },
      {
        title: "Dias Originales",
        dataIndex: "DiasOriginales",
        key: "7",
      },
      // {
      //   title: "Comentario colaborador",
      //   dataIndex: "Comentario",
      //   key: "8",
      // },
      {
        title: "Nombre jefe",
        dataIndex: "NombreJefe",
        key: "9",
      },
      {
        title: "Comentario jefe",
        dataIndex: "ComentarioJefe",
        key: "10",
      },
      {
        title: "Acciones",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            {record.EstadoRevision === "P" ? (
              <>
                <a onClick={() => showModalUpdateRequest(record, "approve")}>
                  Aprobar
                </a>
                <a onClick={() => showModalUpdateRequest(record, "refuse")}>
                  Rechazar
                </a>
              </>
            ) : (
              <Tag color="green">Finalizada</Tag>
            )}
          </Space>
        ),
      },
    ];
  
    // console.log("vacationRequestsRRHH", legalvacationRequestsRRHH);
    if (!legalvacationRequestsRRHH) {
      return "loading...";
    }

    const onSearch = (value) =>{
      console.log("onSearch", value);
      getLegalVacationRRHH(value.trim());
    }
  
    return (
      <>
        {legalVacationRequestAprovedCount != null && 
        <h3>Dias otorgados: {legalVacationRequestAprovedCount.dias}</h3>}
        <Search placeholder="Busqueda por rut o nombre" allowClear={true} enterButton="Buscar" size="middle" onSearch={onSearch}/>
        <br></br>
        <br></br>
        <Table dataSource={legalvacationRequestsRRHH} columns={columns} loading={loading} scroll={{ x: 'auto' }} />
        <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={width}
      >
        {modalContent}
      </Modal>
      </>
    );
}

export default LegalVacationsGeneralRequest;